import React from "react";
import { Box } from "@mui/material";
import { ConfirmationContainerProps } from "./confirmation.types";
import useStyles from "./confirmation.styles";

const ConfirmationContainer = ({ children }: ConfirmationContainerProps) => {
  const styles = useStyles();

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Box className="modal-body">
      <Box sx={styles.mainContainer}>{children}</Box>
    </Box>
  );
};

export default ConfirmationContainer;
