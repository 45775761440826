import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataApi, postDataApi } from "../../utils/common/constants";

/**
 * Adds an adoption code to a location and associates it with a business.
 *
 * @param {object} param0 - An object containing the business name, location name, and adoption code to be added.
 * @param {string} param0.business_name - The name of the business associated with the location.
 * @param {string} param0.location_name - The name of the location to be adopted.
 * @param {string} param0.adoption_code - The adoption code to be associated with the location.
 * @returns {Promise<object>} - An object containing the adoption code and location information if the code is successfully added, or an error response if the code fails to be added.
 */
export const addAdoptionCode = createAsyncThunk(
  "/adopt-location",
  async (
    { business_name, location_name, adoption_code }: any,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const res = await postDataApi({
        path: "alfred/adopt-location",
        data: {
          business_name: business_name,
          location_name: location_name,
          adoption_code: adoption_code,
        },
      });

      /* Keep this response for location sample response, remove this comment later.*/

      // const res = {
      //   adoption_code: "3Mx9Z",
      //   location: {
      //     id: "afc0ef89-119a-409e-9c02-a7a048786062",
      //     business_id: null,
      //     name: "A1 Car Wash - DO NOT EDIT",
      //     address: "201 Rep. John Lewis Way South",
      //     city: "Nashville",
      //     region: "TN",
      //     postal_code: "37203",
      //     phone: null,
      //     description: null,
      //     coordinates: { x: -86.77762, y: 36.1569 },
      //     code_used_on_date: null,
      //     code_valid_thru: null,
      //   },
      // };

      return fulfillWithValue(res);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);

/**
 * Checks the validity of an adoption code and retrieves the associated location and business information.
 *
 * @param {object} param0 - An object containing the adoption code to be checked.
 * @param {string} param0.adoptionCode - The adoption code to be checked.
 * @returns {Promise<object>} - An object containing the adoption code, location, and business information if the code is valid, or an error response if the code is invalid.
 */
export const adoptionCodeCheck = createAsyncThunk(
  "/adoption-code-check",
  async ({ adoptionCode }: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await getDataApi({
        path: "alfred/adoption-code-check",
        data: {
          adoption_code: adoptionCode,
        },
      });

      /* This is sample response for adoption code ,remove this comment later.*/

      // const res = {
      //   adoption_code: "3Mx9Z",
      //   location: {
      //     id: "afc0ef89-119a-409e-9c02-a7a048786062",
      //     business_id: null,
      //     name: "A1 Car Wash - DO NOT EDIT",
      //     address: "201 Rep. John Lewis Way South",
      //     city: "Nashville",
      //     region: "TN",
      //     postal_code: "37203",
      //     description: null,
      //     coordinates: {
      //       x: -86.77762,
      //       y: 36.1569,
      //     },
      //     code_used_on_date: null,
      //     code_valid_thru: null,
      //   },
      //   business: {
      //     id: "fe9d1ef6-3aaa-4eb0-961c-100dca7eb5cb",
      //     name: "",
      //   },
      // };

      return fulfillWithValue(res);
    } catch (error: any) {
      return rejectWithValue(error?.response);
    }
  }
);
