import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { GlobalState } from "../types/global.type";

const initialState: GlobalState = {
  loading: false,
  isCookieSet: false,
  isPanelAreaGraphLoaded: false,
  isPanelAreaBarLoaded: false,
  isLocationCompLoaded: false,
  isWaterGraphLoaded: false,
  isWaterCostGraphLoaded: false,
  isLocationDetailUpdated: false
};

const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    setLoading: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsCookie: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isCookieSet = action.payload;
    },
    setPanelAreaGraphLoaded: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isPanelAreaGraphLoaded = action.payload;
    },
    setPanelBarGraphLoaded: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isPanelAreaBarLoaded = action.payload;
    },
    setLocationCompLoaded: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isLocationCompLoaded = action.payload;
    },
    setWaterGraphLoaded: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isWaterGraphLoaded = action.payload;
    },
    setWaterCostGraphLoaded: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isWaterCostGraphLoaded = action.payload;
    },
    setIsLocationDetailUpdated: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isLocationDetailUpdated = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setIsCookie, setPanelAreaGraphLoaded, setPanelBarGraphLoaded, setLocationCompLoaded, setWaterGraphLoaded, setWaterCostGraphLoaded, setIsLocationDetailUpdated } = globalSlice.actions;

export default globalSlice.reducer;
