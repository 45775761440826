import { toast, Id } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorAlert, TickAlert } from "./common/constants";

const commonErr = "OOPS! something went wrong!";

const toastList = new Set();

const MAXIMUM_TOAST = 5;

/**
 * Defines the props for the `ToastComponent` component.
 *
 * @param message - The main message to display in the toast.
 * @param subMessage - The optional sub-message to display in the toast.
 */
interface ToastComponentProps {
  message: string;
  subMessage?: string;
}

/**
 * Renders a toast component with a message and an optional sub-message.
 *
 * @param message - The main message to display in the toast.
 * @param subMessage - The optional sub-message to display in the toast.
 * @returns A React component that renders the toast.
 */
const ToastComponent: React.FC<ToastComponentProps> = ({
  message,
  subMessage,
}) => (
  <div className="alert-container">
    <p className="alert-message">{message}</p>
    <p className="alert-sub-message">{subMessage}</p>
  </div>
);

/**
 * Displays a toast notification with a message and an optional sub-message.
 *
 * @param type - The type of toast to display (1 for success, 2 for error, 3 for info).
 * @param message - The main message to display in the toast. If not provided, a default error message will be used.
 * @param subMessage - The optional sub-message to display in the toast.
 */
export const showAlert = (
  type: number,
  message: string = commonErr,
  subMessage: string = ""
) => {
  if (toast.error === undefined) {
    toast(message, {
      position: "top-left",
      autoClose: 3000,
      draggable: false,
      closeOnClick: true,
    });
  }

  switch (type) {
    case 1: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id: Id = toast.success(
          <ToastComponent message={message} subMessage={subMessage} />,
          {
            icon: <img src={TickAlert} alt="alert" />,
            onClose: () => toastList.delete(id),
          }
        );
        toastList.add(id);
      }
      break;
    }
    case 2: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id: Id = toast.error(
          <ToastComponent message={message} subMessage={subMessage} />,
          {
            icon: <img src={ErrorAlert} alt="alert" />,
            onClose: () => toastList.delete(id),
          }
        );
        toastList.add(id);
      }
      break;
    }
    case 3: {
      if (toastList.size < MAXIMUM_TOAST) {
        const id: Id = toast.info(
          <ToastComponent message={message} subMessage={subMessage} />,
          {
            onClose: () => toastList.delete(id),
          }
        );
        toastList.add(id);
      }
      break;
    }
    default:
  }
};
