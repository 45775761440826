import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataApi } from "src/utils/common/constants";
import { setLoading , setPanelAreaGraphLoaded, setPanelBarGraphLoaded} from "../features/global.slice";
import { PumpsAndPanelsPayload } from "../types/pumpsAndPanels.type";

/**
 * Fetches pump summary data from the backend API.
 *
 * @param payload - An object containing the parameters for the pump summary request.
 * @returns A promise that resolves to the pump summary data, or an error message if the request fails.
 */
export const pumpSummary = createAsyncThunk(
  "/pump-summary",
  async (payload: PumpsAndPanelsPayload, { dispatch }) => {

    dispatch(setLoading(true));
    try {
      const res = await getDataApi({
        path: `alfred/pump-summary`,
        data: {
          ...payload,
          count: 600000,
        },
      });
      dispatch(setLoading(false));
      return res;
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      dispatch(setLoading(false));
      return message;
    }
  }
);

/**
 * Fetches panel summary data from the backend API.
 *
 * @param payload - An object containing the parameters for the panel summary request.
 * @returns A promise that resolves to the panel summary data, or an error message if the request fails.
 */
export const panelSummary = createAsyncThunk(
  "/panel-summary",
  async (payload: PumpsAndPanelsPayload, { dispatch }) => {
    try {
      dispatch(setPanelAreaGraphLoaded(false))
      const res = await getDataApi({
        path: `alfred/panel-summary`,
        data: { ...payload },
      });
      dispatch(setPanelAreaGraphLoaded(true))
      return res;
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      dispatch(setPanelAreaGraphLoaded(true))
      return message;
    }
  }
);

/**
 * Fetches the status of a pump from the backend API.
 *
 * @param device_id - The ID of the device to fetch the pump status for.
 * @returns A promise that resolves to the pump status data, or an error message if the request fails.
 */
export const pumpStatus = createAsyncThunk(
  "/pump-status",
  async (device_id: string, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await getDataApi({
        path: `alfred/pump-status`,
        data: { deviceId: device_id, since: 1709529896, count: 30000 },
      });
      dispatch(setLoading(false));
      return res;
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      dispatch(setLoading(false));
      return message;
    }
  }
);

/**
 * Fetches the total valve cycles for a given device from the backend API.
 *
 * @param device_id - The ID of the device to fetch the total valve cycles for.
 * @returns A promise that resolves to the total valve cycles data, or an error message if the request fails.
 */
export const totalValveCycles = createAsyncThunk(
  "/total-valve-cycles?",
  async (device_id: string, { dispatch }) => {
    try {
      dispatch(setPanelBarGraphLoaded(false))
      const res = await getDataApi({
        path: `alfred/total-valve-cycles`,
        data: { deviceId: device_id },
      });
      dispatch(setPanelBarGraphLoaded(true))
      return res;
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      dispatch(setPanelBarGraphLoaded(true))
      return message;
    }
  }
);
