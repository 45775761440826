import React from "react";
import { NoNetworkImage } from "src/utils/common/constants";
import './no-network.scss';

const NoNetwork = () => {
  return (
    <div className="not_network_container">
      <div className="not_found_wrap ">
        <img src={NoNetworkImage} alt="No Network" />
        <h3> No Internet Found </h3>
        <p>
          It seems that you have lost your internet network. Please refresh to
          continue
        </p>
      </div>
    </div>
  );
};

export default NoNetwork;
