import moment from "moment";
import { HoursType } from "src/redux/types/userDetail";
import { gallonsInMl } from "./constants";

/**
 * A debounce function that delays the execution of a given function until a specified delay has elapsed since the last time it was called.
 *
 * @param func - The function to be debounced.
 * @param delay - The delay in milliseconds to wait before executing the function.
 * @returns A new function that, when called, will delay the execution of the original function by the specified delay.
 */
export const debounceFn = (
  func: (searchKey: string) => void,
  delay: number
) => {
  let timer: NodeJS.Timeout;
  return function innerFn(this: any, ...args: any) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

/**
 * Generates a CSS font style string based on the provided font properties.
 *
 * @param fontWeight - The font weight, e.g. 400, 700.
 * @param fontSize - The font size, e.g. 16, "1.2rem".
 * @param lineHeight - The line height, e.g. 1.5, "2rem".
 * @param fontStyle - The font style, e.g. "normal", "italic".
 * @param fontFamily - The font family, e.g. "Montserrat".
 * @returns A CSS font style string.
 */
export const FontFamily = (
  fontWeight: number,
  fontSize: number | string,
  lineHeight: number | string,
  fontStyle = "normal",
  fontFamily = "Montserrat"
) => {
  let fontsize = "";
  let lineheight = "";

  if (typeof fontSize === "string") fontsize = fontSize;
  else fontsize = `${fontSize}px`;

  if (typeof lineHeight === "string") lineheight = lineHeight;
  else lineheight = `${lineHeight}px`;

  return `${fontStyle} ${fontWeight} ${fontsize}/${lineheight} ${fontFamily}`;
};

export const criteriaListTexts = [
  {
    id: 1,
    text: "At least 8 characters",
  },
  {
    id: 2,
    text: "At least 3 of the following",
  },
  {
    id: 3,
    text: "Upper case letters (A-Z)",
  },
  {
    id: 4,
    text: "Lower case letters (a-z) ",
  },
  {
    id: 5,
    text: "Numbers (0-9)",
  },
  {
    id: 6,
    text: "Special characters (e.g.!@#$%^&*)",
  },
];

export const updateCriteriaText = (value: any) => {
  const criteriaList = [];

  if (value.length >= 8) {
    criteriaList.push(1);
  }

  if (/[A-Z]/.test(value)) {
    criteriaList.push(3);
  }

  if (/[a-z]/.test(value)) {
    criteriaList.push(4);
  }

  if (/\d/.test(value)) {
    criteriaList.push(5);
  }

  if (/[\W_]/.test(value)) {
    criteriaList.push(6);
  }

  return criteriaList;
};


/**
 * Checks the validity of a schedule object, which represents the business hours for a week.
 *
 * The function performs the following checks:
 * - Ensures there are exactly 7 days in the schedule (one for each day of the week)
 * - Ensures each day's `day` property is a valid day of the week (1-7)
 * - Ensures all days have the same opening and closing times
 * - Ensures all days are marked as not closed
 *
 * If all checks pass, the function returns `true`, indicating the schedule is valid. Otherwise, it returns `false`.
 *
 * @param schedule - An array of `HoursType` objects, representing the business hours for each day of the week.
 * @returns `true` if the schedule is valid, `false` otherwise.
 */
export const checkScheduleValidity = (schedule: HoursType[]) => {
  // Check if there are exactly 7 days in the schedule
  if (schedule.length !== 7) {
    return false;
  }

  // Check if each day falls within the range of 1 to 7
  for (let i = 0; i < schedule.length; i++) {
    if (schedule[i].day !== i + 1) {
      return false;
    }
  }

  // Check if opening time and closing time are the same for all days
  const firstDay = schedule[0];
  for (let i = 1; i < schedule.length; i++) {
    if (schedule[i].open !== firstDay.open || schedule[i].close !== firstDay.close) {
      return false;
    }
  }

  // Check if all days are marked as not closed
  for (let i = 0; i < schedule.length; i++) {
    if (schedule[i].closed !== false) {
      return false;
    }
  }

  // If all checks pass, return true
  return true;
}
/**
 * Converts the first character of the input string to uppercase and the rest to lowercase.
 *
 * @param value - The input string to be capitalized.
 * @returns The input string with the first character capitalized and the rest in lowercase.
 */
export const firstLetterToUpperCase = (value: string) => {

  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

}


/**
 * Formats the given hours and minutes into a string in the format "HH:MM".
 *
 * @param hours - The number of hours to format. Defaults to 0 if not provided.
 * @param minutes - The number of minutes to format. Defaults to 0 if not provided.
 * @returns The formatted time string in the format "HH:MM".
 */
export const handleFormatMinutes = (hours = 0, minutes = 0) => {

  const formattedHours = hours < 10 ? '0' + hours : hours;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  let formattedTime: string = '';
  // Concatenate hours and minutes with a colon in between
  return (formattedTime = `${formattedHours}:${formattedMinutes}`);
};

/**
 * Retrieves the start and end dates based on the provided day filter.
 *
 * @param dayFilter - The filter to determine the start and end dates. Supported values are "TODAY", "YESTERDAY", "MONTH", "LAST7DAYS", "LAST90DAYS", and "ALLTIME".
 * @returns An object containing the start and end dates in ISO string format.
 */
export const getStartEndDate = (dayFilter: string) => {
  let startDate = ""
  let endDate = moment().toISOString()
  if (dayFilter === "TODAY") {
    startDate = moment().startOf("day").toISOString()
  } else if (dayFilter === "YESTERDAY") {
    endDate = moment().subtract(1, 'days').endOf("day").toISOString()
    startDate = moment().subtract(1, 'days').startOf("day").toISOString()
  }
  else if (dayFilter === "MONTH") {
    startDate = moment().startOf('month').startOf("day").toISOString()
  }
  else if (dayFilter === "LAST7DAYS") {
    startDate = moment().subtract(7, 'days').startOf("day").toISOString()
    endDate = moment().subtract(1, 'days').endOf("day").toISOString()
  }
  else if (dayFilter === "LAST90DAYS") {
    startDate = moment().subtract(90, 'days').startOf("day").toISOString()
    endDate = moment().subtract(1, 'days').endOf("day").toISOString()
  }
  else if (dayFilter === "ALLTIME") {
    startDate = moment().startOf("year").toISOString()
  }
  return { startDate, endDate };
};

/**
 * Checks if the given date string represents today's date.
 *
 * @param o - The date string to check.
 * @returns `true` if the date represents today, `false` otherwise.
 */
const isToday = (o: string) => {
  const date = new Date(o);
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

/**
 * Calculates the number of minutes in a bucket based on the given start and end dates.
 *
 * @param startDate - The start date in ISO string format.
 * @param endDate - The end date in ISO string format.
 * @returns An object containing the calculated bucket minutes and the day-hour difference.
 */
export const getBucketMinutes = (startDate: string, endDate: string) => {
  let bucketMinutes = 0;
  let dayHourDiff = 0
  let startOfMonth = moment().startOf("month").toISOString()
  let startOfYear = moment().startOf("year").toISOString()
  let daysDifference = moment(endDate).diff(
    moment(startDate),
    "days"
  );
  let hourDifference = moment(endDate).diff(
    moment(startDate),
    "hours"
  );

  if (daysDifference === 0 && hourDifference <= 24) {
    dayHourDiff = daysDifference + 1
  } else if ((daysDifference >= 1) && (startDate === startOfMonth || startDate === startOfYear)) {
    dayHourDiff = daysDifference
  } else if (isToday(endDate)) {
    dayHourDiff = daysDifference
  } else {
    dayHourDiff = daysDifference + 1
  }

  if (hourDifference <= 4) {
    bucketMinutes = 15;
  } else {
    if (dayHourDiff <= 1 && hourDifference > 4) {
      bucketMinutes = 60;

    } else if (dayHourDiff > 1 && dayHourDiff <= 4) {
      bucketMinutes = 240;
    }
    else if (dayHourDiff > 4 && dayHourDiff <= 7) {
      bucketMinutes = 420;

    } else if (dayHourDiff > 7 && dayHourDiff <= 31) {
      bucketMinutes = 1440;

    } else if (dayHourDiff > 31 && dayHourDiff <= 92) {

      bucketMinutes = 5040;
    }
    else if (dayHourDiff > 92 && dayHourDiff <= 731) {

      bucketMinutes = 10080;
    }
    else if (dayHourDiff > 731) {
      bucketMinutes = 44640;
    }
  }
  return { bucketMinutes, dayHourDiff }
};


// export const formatNumberWithCommas = (number: string) => {
//   const numberValue = Number(number);
//   if (Number.isInteger(numberValue)) {
//     // Number does not have a decimal part
//     return numberValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   } else {
//     // Number has a decimal part
//     const parts = numberValue && numberValue.toFixed(2).split(".");
//     if (parts && parts.length > 0) {
//       parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//       return parts.join(".");
//     }
//     return "";
//   }
// };


/**
 * Formats a number string with commas as thousands separators.
 *
 * @param number - The number to be formatted as a string.
 * @returns The formatted number string with commas.
 */
export const formatNumberWithCommas = (number: string) => {
  const numberValue = Number(number || 0);
  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(numberValue);
};

/**
 * Calculates the cost per milliliter (ML) for a given container size and container cost.
 *
 * @param containerSize - The size of the container in milliliters (ML).
 * @param containerCost - The cost of the container.
 * @returns The cost per milliliter (ML) of the container.
 */
export const getCostForML = (containerSize: number, containerCost: number) => {
  let cost = containerCost / (containerSize * gallonsInMl);
  return Number(cost)

}

/**
 * Removes the specified keys from an object and returns a new object with the keys removed.
 *
 * @param prevDataCopy - The object to remove keys from.
 * @param matchingKeys - The keys to remove from the object.
 * @returns A new object with the specified keys removed.
 */
export const removeKeys = (prevDataCopy: any, matchingKeys: any) => {
  const newObj = { ...prevDataCopy };
  matchingKeys.forEach((key: any) => {
    delete newObj[key];
  });
  return newObj;
};

