import { createSlice } from "@reduxjs/toolkit";
import { LocationState } from "../types/location.type";

export const locationInitialState: LocationState = {
  locations: [],
  initialLocationState: {},
  pending: false
};

export const LocationDetailsModelSlice = createSlice({
  name: "location-details",
  initialState: locationInitialState,
  reducers: {
  },
});

export default LocationDetailsModelSlice.reducer;