import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDataApi,
  postDataApi,
  putDataApi,
} from "../../utils/common/constants";
import { setLoading } from "../features/global.slice";

/**
 * Fetches ChemSense data from the API based on the provided gateway ID, start date, and end date.
 *
 * @param {object} params - An object containing the required parameters.
 * @param {string} params.gatewayId - The ID of the gateway to fetch data for.
 * @param {string} params.start - The start date for the data range.
 * @param {string} params.end - The end date for the data range.
 * @returns {Promise<any>} - A promise that resolves to the fetched ChemSense data.
 */
export const getChemSense = createAsyncThunk(
  "chem-sense/chem-sense",
  async (
    { end, gatewayId, start }: any,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(setLoading(true));
      const res: any = await getDataApi({
        path: `alfred/chem-sense?gatewayId=${gatewayId}&end=${end}&start=${start}`,
        data: {},
      });
      dispatch(setLoading(false));
      if (res && res.containers) {
        return fulfillWithValue(res.containers);
      } else {
        return fulfillWithValue([]);
      }
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      dispatch(setLoading(false));
      return rejectWithValue(message);
    }
  }
);

/**
 * Fetches the "ala carte" ChemSense dashboard data from the API based on the provided gateway ID.
 *
 * @param {string} gatewayId - The ID of the gateway to fetch data for.
 * @returns {Promise<any>} - A promise that resolves to the fetched "ala carte" ChemSense dashboard data.
 */
export const getAlaCarte = createAsyncThunk(
  "chem-sense/get-ala-carte",
  async (
    gatewayId: string,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    dispatch(setLoading(true));
    try {
      const res = await getDataApi({
        path: `alfred/wash-package`,
        data: { gatewayId },
      });
      dispatch(setLoading(false));
      return fulfillWithValue(res);
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      dispatch(setLoading(false));
      return rejectWithValue(message);
    }
  }
);

/**
 * Fetches the "ala carte" ChemSense dashboard data from the API based on the provided gateway ID.
 *
 * @param {string} gatewayId - The ID of the gateway to fetch data for.
 * @returns {Promise<any>} - A promise that resolves to the fetched "ala carte" ChemSense dashboard data.
 */
export const getAlaCarteChemSenseDashboard = createAsyncThunk(
  "chem-sense/get-ala-carte",
  async (gatewayId: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await getDataApi({
        path: `alfred/wash-package`,
        data: { gatewayId },
      });
      return fulfillWithValue(res);
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      return rejectWithValue(message);
    }
  }
);

/**
 * Fetches the "ala carte" ChemSense dashboard data from the API based on the provided gateway ID.
 *
 * @param {string} gatewayId - The ID of the gateway to fetch data for.
 * @returns {Promise<any>} - A promise that resolves to the fetched "ala carte" ChemSense dashboard data.
 */
export const getAlaCarteDashboard = createAsyncThunk(
  "chem-sense/get-ala-carte",
  async (
    gatewayId: string,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await getDataApi({
        path: `alfred/wash-package`,
        data: { gatewayId },
      });
      return fulfillWithValue(res);
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;

      return rejectWithValue(message);
    }
  }
);

/**
 * Fetches all chemical containers data from the API based on the provided gateway ID.
 *
 * @param {string} gatewayId - The ID of the gateway to fetch data for.
 * @returns {Promise<any>} - A promise that resolves to the fetched chemical containers data.
 */
export const getAllChemicals = createAsyncThunk(
  "chem-sense/get-all-chemicals",
  async (
    gatewayId: string,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    dispatch(setLoading(true));
    try {
      const res = await getDataApi({
        path: `alfred/chemical-containers`,
        data: { gatewayId },
      });
      dispatch(setLoading(false));
      return fulfillWithValue(res);
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;

      dispatch(setLoading(false));
      return rejectWithValue(message);
    }
  }
);
/**
 * Fetches all chemical containers data from the API based on the provided gateway ID.
 *
 * @param {string} gatewayId - The ID of the gateway to fetch data for.
 * @returns {Promise<any>} - A promise that resolves to the fetched chemical containers data.
 */
export const getAllChemicalsDashboard = createAsyncThunk(
  "chem-sense/get-all-chemicals",
  async (
    gatewayId: string,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await getDataApi({
        path: `alfred/chemical-containers`,
        data: { gatewayId },
      });
      return fulfillWithValue(res);
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      return rejectWithValue(message);
    }
  }
);

/**
 * Updates the data for a chemical wash package.
 *
 * @param {object} data - The data to update the wash package with.
 * @param {function} successCallback - A callback function to be called on successful update.
 * @param {function} errorCallback - A callback function to be called on update failure.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
export const editPackages = createAsyncThunk(
  "chem-sense/edit-packages",
  async ({ data, successCallback, errorCallback }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await putDataApi({
        path: `alfred/wash-package`,
        data,
      });
      dispatch(setLoading(false));
      if (successCallback) successCallback("Updated");
    } catch (error: any) {
      if (errorCallback) errorCallback();
      dispatch(setLoading(false));
    }
  }
);
/**
 * Adds a new chemical wash package.
 *
 * @param {object} data - The data for the new wash package.
 * @param {function} successCallback - A callback function to be called on successful addition.
 * @param {function} errorCallback - A callback function to be called on addition failure.
 * @returns {Promise<void>} - A promise that resolves when the addition is complete.
 */
export const addPackages = createAsyncThunk(
  "chem-sense/edit-packages",
  async ({ data, successCallback, errorCallback }: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await postDataApi({
        path: `alfred/wash-package`,
        data,
      });
      dispatch(setLoading(false));
      if (successCallback) successCallback("Added");
    } catch (error: any) {
      if (errorCallback) errorCallback();
      dispatch(setLoading(false));
    }
  }
);

export const getAllBackOfficeList = createAsyncThunk(
  "back-office-wash-package-list",
  async (locationId: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await getDataApi({
        path: `alfred/back-office-wash-package-list`,
        data: locationId,
      });
      return fulfillWithValue(res);
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      return rejectWithValue(message);
    }
  }
);
