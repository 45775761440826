import { useEffect, useState } from "react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { panelAndPumpDetail } from "src/redux/actions/aqua.lab.action";
import { setSelectedPumpPanel } from "src/redux/features/aquaLab.slice";
import { PumpsAndPanel } from "src/redux/types/aquaLab.type";

const usePanelSelectionHelper = () => {
  const dispatch = useAppDispatch();
  const [pumpAndPanels, setPumpAndPanels] = useState([]);
  const [panelCount, setPanelCount] = useState<String | Number>();

  const locationId = useAppSelector(
    (state) => state.userInfo.selectedLocation.id
  );

  const selectedPumpPanel = useAppSelector(
    (state) => state.aquaLab.selectedPumpPanel
  );

  /**
   * Fetches the pump and panel details for the selected location and updates the state with the retrieved components.
   * If the fetch is successful, it also sets the first component as the selected pump and panel.
   */
  const getPumpAndPanel = () => {
    dispatch(panelAndPumpDetail(locationId))
      .then((res: any) => {
        if (res.payload) {
          setPumpAndPanels(
            res.payload.components.filter((item: any) => item.id)
          );
          setPanelCount(pumpAndPanels.length ? pumpAndPanels.length : "No");
          dispatch(setSelectedPumpPanel(res.payload.components[0]));
        }
      })
      .catch(() => {
        return;
      });
  };

  /**
   * Dispatches an action to update the selected pump and panel in the application state.
   * @param selectedEquipment - The selected pump and panel equipment.
   */
  const handleSelection = (selectedEquipment: PumpsAndPanel) => {
    dispatch(setSelectedPumpPanel(selectedEquipment));
  };

  useEffect(() => {
    getPumpAndPanel();
    // eslint-disable-next-line
  }, [locationId]);

  return { pumpAndPanels, handleSelection, selectedPumpPanel, panelCount };
};

export default usePanelSelectionHelper;
