import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getAllBackOfficeList } from "../actions/get.chem-sense";
import { BackOfficeState } from "../types/chemSense.type";

const initialState: BackOfficeState = {
  error: "",
  backOfficeListing: [],
};

const backOfficeSlice = createSlice({
  name: "backOffice",
  initialState,
  reducers: {
    getBackOfficeListing: (
      state: BackOfficeState,
      action: PayloadAction<Array<any>>
    ) => {
      state.backOfficeListing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllBackOfficeList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.backOfficeListing = action.payload;
          return state;
        }
      )
      .addCase(
        getAllBackOfficeList.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
        }
      );
  },
});

export const { getBackOfficeListing } = backOfficeSlice.actions;

export default backOfficeSlice.reducer;
