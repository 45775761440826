import { LocationData } from "./location.validator";
import { setKey, setLanguage, fromLatLng } from "react-geocode";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { addAddress } from "../../redux/actions/locationDetails.action";
import { addAdoptionCode } from "../../redux/actions/adoptionCode.action";
import { updateSelectedLocation } from "src/redux/features/userDetail.slice";
import { useNavigate } from "react-router-dom";
import {
  setLoading,
  setIsLocationDetailUpdated,
} from "src/redux/features/global.slice";

/**
 * This function provides helper methods for adding addresses from payload, getting
 * address from latitude and longitude, getting address from adoption code, and fetching latitude and
 * longitude from an address.
 * @returns The `useLocationHelper` function returns an object with four functions:
 * `addAddressFromPayload`, `getAddressFromLatLng`, `getAddressFromAdoption`, and
 * `getLatLngFromAddress`.
 */
const useLocationHelper = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /**
   * This function dispatches actions to add an address from a payload, updates
   * the selected location, navigates back, and sets a flag for location detail update.
   * @param {LocationData} payload - The `payload` parameter in the `addAddressFromPayload` function is
   * of type `LocationData`. It is used to pass location data to the function for processing and adding
   * the address.
   */
  const addAddressFromPayload = async (payload: LocationData) => {
    dispatch(setLoading(true));
    try {
      const response: any = await dispatch(addAddress({ payload }));
      dispatch(updateSelectedLocation(response.payload.id));
      navigate(-1);
      dispatch(setIsLocationDetailUpdated(true));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
    }
  };

 /**
  * This function asynchronously dispatches an action to add an adoption code
  * with the provided code.
  * @param {string} code - The `code` parameter in the `getAddressFromAdoption` function is a string
  * that represents the adoption code used to retrieve an address.
  */
  const getAddressFromAdoption = async (code: string) => {
    await dispatch(addAdoptionCode({ code }));
  };

  
 /**
   * This function takes an address as a string and returns the latitude and longitude coordinates of that address using the Google Maps Geocoding API.
   * @param {string} address - The address to be converted to latitude and longitude coordinates.
   * @returns {Promise<{ lat: number, lng: number }>} - A Promise that resolves to an object containing the latitude and longitude coordinates of the provided address.
   * @throws {Error} - If the address is not found in the Google Maps Geocoding API response.
   */
  const getLatLngFromAddress = async (address: string) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${apiKey}`
    );

    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const location = data.results[0].geometry.location;
      return {
        lat: location.lat,
        lng: location.lng,
      };
    } else {
      throw new Error("Address not found");
    }
  };

 /**
  * This function takes a marker's position and returns the formatted address
  * corresponding to that location.
  * @param {any} markerPosition - The `markerPosition` parameter is an object that contains the
  * latitude and longitude coordinates of a marker on a map. It is used in the `getAddressFromLatLng`
  * function to retrieve the address corresponding to those coordinates.
  * @returns The function `getAddressFromLatLng` is returning the address corresponding to the latitude
  * and longitude provided in the `markerPosition` object. If successful, it returns the formatted
  * address from the response. If there is an error or an exception is caught, it returns `undefined`.
  */
  const getAddressFromLatLng = async (markerPosition: any) => {
    setLanguage("en");
    setKey("AIzaSyAoaoO4nwnojd1w7T6L3NZb07y2GojllNM");
    try {
      const response = await fromLatLng(markerPosition.lat, markerPosition.lng);
      const address = await response.results[0].formatted_address;
      return address;
    } catch {
      return;
    }
  };

  return {
    addAddressFromPayload,
    getAddressFromLatLng,
    getAddressFromAdoption,
    getLatLngFromAddress,
  };
};

export default useLocationHelper;
