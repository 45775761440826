import { combineReducers } from "@reduxjs/toolkit";
import UserDetailModelSlice from "./features/userDetail.slice";
import GlobalModelSlice from "./features/global.slice";
import ChemSenseModelSlice from "./features/chemSense.slice";
import chemSenseOverview from "./features/chemSenseOverview.slice";
import LocationDetailsModelSlice from "./features/locationDetails.slice";
import AquaLabModelSlice from "./features/aquaLab.slice";
import PumpsAndPanelsSlice from "./features/pumpsAndPanels.slice";
import DashboardSlice from "./features/dashboard.slice";
import backOfficeSlice from "./features/backOffice.slice";

const rootReducer = combineReducers({
  location: LocationDetailsModelSlice,
  userInfo: UserDetailModelSlice,
  globalcontent: GlobalModelSlice,
  chemSense: ChemSenseModelSlice,
  chemSenseOverview: chemSenseOverview,
  aquaLab: AquaLabModelSlice,
  pumpsAndPanels: PumpsAndPanelsSlice,
  dashboard: DashboardSlice,
  backOffice: backOfficeSlice,
});

export default rootReducer;
