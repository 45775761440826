import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import {
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./root.reducer";

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["auth"],
};

/**
 * Persists the application's state using the configured persistence settings.
 *
 * This function wraps the application's reducers with the `persistReducer` function from the `redux-persist` library.
 * The persisted state is stored in the session storage using the configuration defined in the `persistConfig` object.
 *
 * @param state - The current state of the Redux store.
 * @param action - The action object that triggered this reducer.
 * @returns The new state of the Redux store after applying the persisted state.
 */
const persistedReducer = persistReducer(persistConfig, reducers);

/**
 * Handles the logout action by removing the persisted state from the session storage and resetting the state to an empty object.
 *
 * @param state - The current state of the Redux store.
 * @param action - The action object that triggered this reducer.
 * @returns The new state of the Redux store after handling the logout action.
 */
const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") {
    // this applies to all keys defined in persistConfig(s)
    sessionStorage.removeItem("persist:root");
    state = {};
    // successAlert("User logout successfully.");
  }
  return persistedReducer(state, action);
};

/**
 * Configures and returns the Redux store for the application.
 * 
 * The store is configured with the following options:
 * - `reducer`: The root reducer function that combines all the reducers in the application.
 * - `devTools`: Enables the Redux DevTools extension in non-production environments.
 * - `middleware`: Configures the Redux middleware, including ignoring certain actions for the Redux Persist library.
 *
 * The configured store is exported as `store` and can be used throughout the application.
 */
export var store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;