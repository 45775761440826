import { Box, Button } from "@mui/material";
import PanelSvg from "src/components/svg.icon/panel.svg";
import PumpSvg from "src/components/svg.icon/pump.svg";
import { PumpsAndPanel } from "src/redux/types/aquaLab.type";
import usePanelSelectionHelper from "./helper";
import "./panel-selection.scss";

export default function PanelSelection() {
  const { pumpAndPanels, handleSelection, selectedPumpPanel, panelCount } =
    usePanelSelectionHelper();

  return (
    <Box className="panel_selection_container">
      {pumpAndPanels && (
        <>
          <div className="panel_selection_heading">
            {`${panelCount} Aqua-Lab Panels & Pumps  Found`}
          </div>
          <div className="panel_overwrapper">
            <Box className="panel_pump_container">
              {pumpAndPanels.map((item: PumpsAndPanel, index: any) => {
                return (
                  <Button
                    onClick={() => handleSelection(item)}
                    className={`panel_selection_button ${
                      selectedPumpPanel.modbus_id === item.modbus_id
                        ? `active-${selectedPumpPanel.type}`
                        : ""
                    }`}
                    key={index}
                  >
                    {item.type === "panel" ? <PanelSvg /> : <PumpSvg />}
                    <p className="hd"> {item.name}</p>
                  </Button>
                );
              })}
            </Box>
          </div>
        </>
      )}
    </Box>
  );
}
