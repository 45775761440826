const ROUTES = {
  BASE_ROUTE: "/",

  AUTH_ROUTES: {
    LOGIN: "/",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    SIGN_UP: "/sign-up",
    TERMS: "/terms",
  },
  FEATURE_ROUTES: {
    DASHBOARD: "/dashboard",
    DASHBOARD_EDIT: "/dashboard-edit",
    ADDWIDGET: "/dashboard/add-widget",
    ADOPTION: "/adoption",
    LOCATION: "/location",
    WATER_SENSE: "/water-sense",
    CHEM_SENSE: "/chem-sense",
    CHEM_SENSE_GRAPH: "/chem-sense-graph",
    TOTAL_CYCLES_PER_VALVE_GRAPH_WITH_FILTER: "/total-cycles-graph-filter",
    TOTAL_CYCLES_PER_VALVE_GRAPH: "/total-cycles-graph",
    TOTAL_CYCLES_PER_VALVE: "/total-cycles",
    TOTAL_CYCLES_PER_VALVE_PANEL: "/total-cycles-panel",
    AQUA_LAB: "/aqua-lab",
    CHEM_SENSE_DETAIL: "/chem-sense/detail",
    CHEM_SENSE_SETTINGS: "/chem-sense/settings",
    USER_SETTINGS: "/user/settings",
    GATEWAY_STATS: "/gateway-stats",
    WATER_COST: "/water-cost",
    BUSINESS_HOURS: "/set-business-hours",
    VIEW_BUSINESS_HOURS: "/view-business-hours",
    USER_MANGEMENT: "/user-management",
    ADD_USER: "/user-management/add-user",
    EDIT_USER: "/user-management/edit-user",
    USER_DETAILS: "/user-management/user-details",
    NOTIFICATION: "/notification",
    PUMP_WEB_VIEW: "/pump-web-view",
    PUMP_POWER_WEB_VIEW: "/pump-web-view-power",
  },
};

export default ROUTES;
