import { FC } from "react";

const AquaLabSvg: FC<{}> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#00ACE5" />
      <g clipPath="url(#clip0_2564_9306)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0945 15.5039C17.4275 14.21 18.6021 13.2539 20 13.2539C21.3978 13.2539 22.5724 14.21 22.9055 15.5039H17.0945ZM15.5622 15.5039C15.9193 13.3756 17.7702 11.7539 20 11.7539C22.2297 11.7539 24.0807 13.3756 24.4377 15.5039H28.25V19.2539H11.75V15.5039H15.5622ZM20.75 20.7539H28.25V28.2539H11.75V20.7539H19.25V22.2539H20.75V20.7539Z"
          fill="url(#paint0_linear_2564_9306)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2564_9306"
          x1="20"
          y1="11.7539"
          x2="20"
          y2="28.2539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
        <clipPath id="clip0_2564_9306">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(11 11.0039)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AquaLabSvg;
