import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Typography,
} from "@mui/material";
import "./pump-detail.scss";
import {
  BlueBackgoundDrop,
  CloseImg,
  PurpleDrop,
} from "src/utils/common/constants";
import usePumpDetailHelper from "./helper";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import ContainedButton from "src/components/contained-button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { formatNumberWithCommas } from "src/utils";

export default function PumpDetail() {
  const {
    selectedPumpPanel,
    getPumpSummary,
    initialDateValues,
    params,
    selectionRange,
    handleRageFilter,
    activeButton,
    showPicker,
    dateSelected,
    handleDates,
    handleEventDate,
    handleTimeChange,
    isDateSelected,
    handleReset,
    setShowPicker,
  } = usePumpDetailHelper();

  const [isError, setError] = useState(false);

  /**
   * Fetches the pump summary data based on the selected date range.
   *
   * @param {string} startDate - The start date of the selected date range in ISO format.
   * @param {string} endDate - The end date of the selected date range in ISO format.
   */
  const updateSummaryData = () => {
    getPumpSummary(
      selectionRange.startDate.toISOString(),
      selectionRange.endDate.toISOString()
    );
  };

  /**
   * Handles errors that occur when setting the date range.
   *
   * @param {any} err - The error that occurred.
   * @returns {void}
   */
  const handleError = (err: any) => {
    setError(err === "minTime" || err === "invalidDate" ? true : false);
  };

  useEffect(() => {
    updateSummaryData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="pump_detail_container">
      <Typography className="pump_detail_heading">
        {selectedPumpPanel.name}
      </Typography>
      <div className="pump_detail_subheading">
        Select the pump/valve from left panel to view details
      </div>
      <Box>
        <Box className="inlineFilter_wrap">
          <Box className="filterDt_wp">
            <Box
              className={
                showPicker || dateSelected !== 0 ? `active cal` : `cal`
              }
            >
              <i onClick={handleReset}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1.5 3H16.5V16.5H1.5V3Z" strokeWidth="1.6" />
                  <path d="M1.5 6H16.5" strokeWidth="1.6" />
                  <path d="M4.5 0.75V3" strokeWidth="1.6" />
                  <path d="M13.5 0.75V3" strokeWidth="1.6" />
                  <path d="M3.75 9.75H6" strokeWidth="1.6" />
                  <path d="M3.75 12.75H6" strokeWidth="1.6" />
                  <path d="M7.875 9.75H10.125" strokeWidth="1.6" />
                  <path d="M12 9.75H14.25" strokeWidth="1.6" />
                  <path d="M7.875 12.75H10.125" strokeWidth="1.6" />
                </svg>
              </i>
            </Box>
            {isDateSelected && (
              <Box className="filtInp">
                <input
                  type="text"
                  value={`${moment(selectionRange.startDate).format(
                    "D MMM, YYYY hh:mm A "
                  )} - ${moment(selectionRange.endDate).format(
                    "D MMM, YYYY hh:mm A"
                  )}`}
                  onClick={handleEventDate}
                  id="date-range-picker"
                  placeholder="Select Date Range"
                  onChange={() => {}}
                />
                <i className="cls" onClick={handleReset}>
                  <img src={CloseImg} alt="close" />
                </i>
              </Box>
            )}
            {showPicker && (
              <ClickAwayListener onClickAway={() => setShowPicker(false)}>
                <Box className="date-range-container">
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleDates}
                    maxDate={initialDateValues?.endDate}
                    months={1}
                    className={"date-picker"}
                  />
                  <Box sx={{ display: "flex" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          label="Start Time"
                          value={dayjs(selectionRange.startDate)}
                          onChange={(value) =>
                            handleTimeChange(value, "startDate")
                          }
                          timeSteps={{ minutes: 1 }}
                          onError={handleError}
                          inputRef={(inputRef) => {
                            if (inputRef) {
                              inputRef.addEventListener("keydown", (event) => {
                                if (event.keyCode === 8) {
                                  event.preventDefault();
                                }
                              });
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          label="End Time"
                          value={dayjs(selectionRange.endDate)}
                          onChange={(value) =>
                            handleTimeChange(value, "endDate")
                          }
                          timeSteps={{ minutes: 1 }}
                          minTime={dayjs(selectionRange.startDate)}
                          onError={handleError}
                          inputRef={(inputRef) => {
                            if (inputRef) {
                              inputRef.addEventListener("keydown", (event) => {
                                if (event.keyCode === 8) {
                                  event.preventDefault();
                                }
                              });
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                  <Box className="dataPik_bottom">
                    <ContainedButton
                      title="Set Dates"
                      className="set_rst"
                      onClick={updateSummaryData}
                      disabled={isError}
                    />
                  </Box>
                </Box>
              </ClickAwayListener>
            )}
          </Box>
          <Button
            className={`InfillBtn ${
              activeButton === "today" ? "activeBtn" : ""
            }`}
            onClick={() => {
              handleRageFilter("today");
            }}
          >
            Today
          </Button>
          <Button
            className={`InfillBtn ${
              activeButton === "yesterday" ? "activeBtn" : ""
            }`}
            onClick={() => {
              handleRageFilter("yesterday");
            }}
          >
            Yesterday
          </Button>
          <Button
            className={`InfillBtn ${
              activeButton === "7days" ? "activeBtn" : ""
            }`}
            onClick={() => {
              handleRageFilter("7days");
            }}
          >
            Last 7 days
          </Button>
          <Button
            className={`InfillBtn ${
              activeButton === "30days" ? "activeBtn" : ""
            }`}
            onClick={() => {
              handleRageFilter("30days");
            }}
          >
            Last 30 days
          </Button>
          <Button
            className={`InfillBtn ${
              activeButton === "allTime" ? "activeBtn" : ""
            }`}
            onClick={() => {
              handleRageFilter("allTime");
            }}
          >
            All time
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box className="delBxc">
              <div className="tp">
                <img src={BlueBackgoundDrop} alt="backDrop" />
                <p>Total Run Time</p>
              </div>
              <p
                className="dl"
                dangerouslySetInnerHTML={{ __html: params.totalRunTime }}
              ></p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="delBxc">
              <div className="tp">
                <img src={PurpleDrop} alt="backDrop" />
                <p>Average Run Time per day</p>
              </div>
              <p
                className="dl"
                dangerouslySetInnerHTML={{ __html: params.avgRunTime }}
              ></p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="delBxc">
              <div className="tp">
                <img src={BlueBackgoundDrop} alt="backDrop" />
                <p>Total Power Usage</p>
              </div>
              <p className="dl">
                <span>{params.totalPowerTime}</span> kwh
              </p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="delBxc">
              <div className="tp">
                <img src={PurpleDrop} alt="backDrop" />
                <p>Average Power Usage per day</p>
              </div>
              <p className="dl">
                <span>{params.avgPowerTime}</span> kwh
              </p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="delBxc">
              <div className="tp">
                <img src={BlueBackgoundDrop} alt="backDrop" />
                <p>Total Starts</p>
              </div>
              <p className="dl">
                <span>{formatNumberWithCommas(String(params?.totalStarts || ""))}</span>
              </p>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="delBxc">
              <div className="tp">
                <img src={PurpleDrop} alt="backDrop" />
                <p>Average Starts per day</p>
              </div>
              <p className="dl">
                <span>{formatNumberWithCommas(String(params?.avgStarts || ""))}</span>
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
