import React, { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";

import { MapsIco, MapsPin } from "../../utils/common/constants/images";


/**
 * Renders a Google Map component with a marker at the specified location.
 *
 * @param {object} props - The component props.
 * @param {object} props.mapLocationRef - A ref to the current map location.
 * @param {object} props.locationAddress - An object containing the address details.
 * @param {string} props.adoption_code - An optional adoption code to display.
 * @returns {JSX.Element} - The rendered Google Map component.
 */
/**
 * Renders a Google Map component with a marker at the specified location.
 *
 * @param {object} props - The component props.
 * @param {object} props.mapLocationRef - A ref to the current map location.
 * @param {object} props.locationAddress - An object containing the address details.
 * @param {string} props.adoption_code - An optional adoption code to display.
 * @returns {JSX.Element} - The rendered Google Map component.
 */
/**
 * Renders a Google Map component with a marker at the specified location.
 *
 * @param {object} props - The component props.
 * @param {object} props.mapLocationRef - A ref to the current map location.
 * @param {object} props.locationAddress - An object containing the address details.
 * @param {string} props.adoption_code - An optional adoption code to display.
 * @returns {JSX.Element} - The rendered Google Map component.
 */
/**
 * Renders a Google Map component with a marker at the specified location.
 *
 * @param {object} props - The component props.
 * @param {object} props.mapLocationRef - A ref to the current map location.
 * @param {object} props.locationAddress - An object containing the address details.
 * @param {string} props.adoption_code - An optional adoption code to display.
 * @returns {JSX.Element} - The rendered Google Map component.
 */
/**
 * Renders a Google Map component with a marker at the specified location.
 *
 * @param {object} props - The component props.
 * @param {object} props.mapLocationRef - A ref to the current map location.
 * @param {object} props.locationAddress - An object containing the address details.
 * @param {string} props.adoption_code - An optional adoption code to display.
 * @returns {JSX.Element} - The rendered Google Map component.
 */
const Map = ({ mapLocationRef, locationAddress, adoption_code }: any) => {
  const [markerPosition, setMarkerPosition] = useState(mapLocationRef.current);
  const [mapCenter] = useState(mapLocationRef.current);

  /**
   * Handles the click event on the Google Map component, updating the marker position to the clicked location.
   *
   * @param {any} event - The click event object from the Google Maps API.
   * @returns {void}
   */
  const handleMapClick = useCallback((event: any) => {
    const newMarkerPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    mapLocationRef.current = newMarkerPosition;
    setMarkerPosition(newMarkerPosition);
    // eslint-disable-next-line
  }, []);

  /**
   * Generates a formatted address string from the provided location address details.
   *
   * @param {object} locationAddress - An object containing the address details.
   * @param {string} locationAddress.address - The street address.
   * @param {string} locationAddress.city - The city.
   * @param {string} locationAddress.region - The region or state.
   * @returns {string} - The formatted address string.
   */
  const addressString = useMemo(() => {
    const { address, city, region } = locationAddress;
    const addressPoints = [address, city, region].filter((item) => item !== "");
    return addressPoints.join(", ");
  }, [locationAddress]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env?.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ["places"],
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps...</div>;
  }
  return (
    <>
      <Box className="mpBx">
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          zoom={15}
          center={mapCenter}
          onClick={handleMapClick}
        >
          <MarkerF position={markerPosition} icon={MapsPin}></MarkerF>
        </GoogleMap>
      </Box>
      <Box className="mapDetails">
        <div className="lt">
          <Box component="img" src={MapsIco}></Box>
          <Box>{addressString}</Box>
        </div>
        {adoption_code ? <div className="rt">{adoption_code}</div> : <></>}
      </Box>
    </>
  );
};

export default Map;
