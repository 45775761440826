import React from "react"; // add this
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

import "./index.scss";
import App from "./App";
type ProvidersProps = {
  children: React.ReactNode;
};

const Providers = ({ children }: ProvidersProps) => {
  return <Provider store={store}>{children}</Provider>;
};

const container = document.getElementById("root") as HTMLDivElement;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <Providers>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Providers>
  // </React.StrictMode>
);

reportWebVitals();
