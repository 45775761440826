import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { FormControl } from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Logo, NotificationIco } from "../../utils/common/constants";
import UserSvg from "../svg.icon/user.svg";
import DashboardSvg from "../svg.icon/dashboard.svg";
import MenuSvg from "../svg.icon/menu.svg";
import WaterSenseSvg from "../svg.icon/water.sense.svg";
import ChemSenseSvg from "../svg.icon/chem.sense.svg";
import AquaLabSvg from "../svg.icon/aqua.lab.svg";
import ROUTES from "../../routes/routes";
import { User, UserDetail } from "src/redux/types/userDetail";
import {
  Caret,
  userSetting,
  activenoti,
} from "src/utils/common/constants/images";
import "./header.scss";

function Header() {
  const location = useLocation();

  const userInfo: UserDetail = useSelector((state: any) => state.userInfo);
  const userDetails: User = userInfo.userDetail;
  const selectedLocation = userInfo.selectedLocation;
  const [profilePicture, setProfilePicture] = React.useState<string>(
    userDetails?.user_metadata?.profile_picture
  );

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    setProfilePicture(userDetails?.user_metadata?.profile_picture);
  }, [userDetails?.user_metadata?.profile_picture]);

  const handleOpenLocationMenu = () => {
    localStorage.setItem("isDropdown", "true");
    localStorage.setItem("routes", location.pathname);

    navigate(ROUTES.FEATURE_ROUTES.ADOPTION, {
      state: { path: location.pathname },
      replace: true,
    });
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    navigate(ROUTES.FEATURE_ROUTES.USER_SETTINGS);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (path: string) => {
    setAnchorElNav(null);
    navigate(path);
  };
  const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  return (
    <AppBar position="static" className="header">
      <Container maxWidth="xl" className="header_container">
        <Box className="header_left_container">
          <Link to="/dashboard" className="brandLogo">
            <img src={Logo} alt="logo" />
          </Link>
          <Box className="headerMenu">
            {userDetails?.role_type === "admin" ? (
              <Button
                className={`${location.pathname.includes(
                  ROUTES.FEATURE_ROUTES.USER_MANGEMENT
                )
                  ? "header_button active"
                  : "header_button"
                  }`}
                onClick={() => {
                  handleNavigate(ROUTES.FEATURE_ROUTES.USER_MANGEMENT);
                }}
              >
                <i>
                  <UserSvg />
                </i>
                <span className="txt">User</span>
              </Button>
            ) : (
              <></>
            )}
            <Button
              onClick={() => {
                handleNavigate(ROUTES.FEATURE_ROUTES.DASHBOARD);
              }}
              className={`${location.pathname.includes(ROUTES.FEATURE_ROUTES.DASHBOARD)
                ? "header_button ds active"
                : "header_button ds"
                }`}
            >
              <i>
                <DashboardSvg />
              </i>
              <span className="txt">Dashboard</span>
            </Button>
            {location.pathname.includes(ROUTES.FEATURE_ROUTES.WATER_SENSE) && (
              <Button className="header_button active">
                <i>
                  <svg
                    width="24"
                    height="28"
                    viewBox="0 0 24 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.0004 0.683594L19.8252 8.50843C21.3728 10.056 22.4267 12.0278 22.8536 14.1743C23.2806 16.3209 23.0614 18.5458 22.2239 20.5678C21.3863 22.5898 19.968 24.3181 18.1482 25.534C16.3285 26.7499 14.189 27.3989 12.0004 27.3989C9.81181 27.3989 7.67235 26.7499 5.85259 25.534C4.03283 24.3181 2.6145 22.5898 1.77694 20.5678C0.939385 18.5458 0.720227 16.3209 1.14718 14.1743C1.57413 12.0278 2.62802 10.056 4.17558 8.50843L12.0004 0.683594ZM12.0004 20.9999C10.7627 20.9999 9.57575 20.5083 8.70058 19.6331C7.82541 18.7579 7.33374 17.5709 7.33374 16.3333H5.00041C5.00041 18.1898 5.73791 19.9703 7.05066 21.283C8.36342 22.5958 10.1439 23.3333 12.0004 23.3333V20.9999Z"
                      fill="#004E9A"
                    />
                  </svg>
                </i>
                <span className="txt">Water</span>
              </Button>
            )}
            {location.pathname.includes(ROUTES.FEATURE_ROUTES.CHEM_SENSE) && (
              <Button className="header_button active">
                <i>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.8333 1.66667H15.5V0H0.5V1.66667H2.16667V14.1667C2.16667 17.3883 4.77834 20 8 20C11.2217 20 13.8333 17.3883 13.8333 14.1667V1.66667ZM12.1667 1.66667H3.83333V5H12.1667V1.66667Z"
                      fill="#014E99"
                    />
                  </svg>
                </i>
                <span className="txt">Chem</span>
              </Button>
            )}
            {location.pathname.includes(ROUTES.FEATURE_ROUTES.AQUA_LAB) && (
              <Button className="header_button active">
                <i>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.77136 4.9987C7.14139 3.56101 8.44651 2.4987 9.99967 2.4987C11.5528 2.4987 12.8579 3.56101 13.228 4.9987H6.77136ZM5.06881 4.9987C5.46554 2.63394 7.52217 0.832031 9.99967 0.832031C12.4772 0.832031 14.5338 2.63394 14.9305 4.9987H19.1663V9.16537H0.833008V4.9987H5.06881ZM10.833 10.832H19.1663V19.1654H0.833008V10.832H9.16634V12.4987H10.833V10.832Z"
                      fill="#014E99"
                    />
                  </svg>
                </i>
                <span className="txt">Aqualab</span>
              </Button>
            )}
            <Box
              className={anchorElNav ? `DrpMn active` : `DrpMn`}
              onClick={handleOpenNavMenu}
            >
              <MenuSvg />
            </Box>
          </Box>
        </Box>

        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className="icon_navigation"
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          <Button
            onClick={() => {
              handleNavigate(ROUTES.FEATURE_ROUTES.WATER_SENSE);
            }}
            className="header_button"
          >
            <i>
              <WaterSenseSvg />
            </i>
            <span className="txt">Water</span>
          </Button>
          <Button
            onClick={() => {
              handleNavigate(ROUTES.FEATURE_ROUTES.CHEM_SENSE);
            }}
            className="header_button"
          >
            <i>
              <ChemSenseSvg />
            </i>
            <span className="txt">Chem</span>
          </Button>
          <Button
            onClick={() => {
              handleNavigate(ROUTES.FEATURE_ROUTES.AQUA_LAB);
            }}
            className="header_button"
          >
            <i>
              <AquaLabSvg />
            </i>
            <span className="txt">Aqualab</span>
          </Button>
        </Menu>

        <Box className="Head_rt" sx={{ flexGrow: 0 }}>
          <FormControl className="Lc_dropdownWrp">
            <Button onClick={handleOpenLocationMenu}>
              <i>
                <FmdGoodIcon />
              </i>
              {selectedLocation.name}
              <img src={Caret} style={{ paddingLeft: 10 }} alt="caret" />
            </Button>
          </FormControl>
          <Button
            className={
              location.pathname.includes(ROUTES.FEATURE_ROUTES.NOTIFICATION)
                ? "active notiNv "
                : "notiNv"
            }
            onClick={() => {
              handleNavigate(ROUTES.FEATURE_ROUTES.NOTIFICATION);
            }}
          >
            <img
              src={NotificationIco}
              alt="notification-icon"
              className="inactiveImg"
            />
            <img
              src={activenoti}
              alt="notification-icon"
              className="activeImg"
            />
          </Button>
          <Box>
            <div
              className={
                location.pathname.includes(ROUTES.FEATURE_ROUTES.USER_SETTINGS)
                  ? "active pp_menu user-menu-wrapper "
                  : "pp_menu user-menu-wrapper"
              }
              onClick={handleOpenUserMenu}
            >
              <figure className="img">
                {profilePicture ? (
                  <img src={profilePicture} alt="picture" />
                ) : (
                  <img src={userDetails?.picture} alt="picture" />
                )}
              </figure>
              <div className="rt">
                <div className="hd">{userDetails?.name}</div>
                <div className="ds">
                  {capitalizeFirstLetter(userDetails?.role_type)}
                </div>
              </div>
            </div>

            <Button
              className="notiNv mobileUserSetting"
              onClick={() => {
                handleNavigate(ROUTES.FEATURE_ROUTES.USER_SETTINGS);
              }}
            >
              <img src={userSetting} alt="user" />
            </Button>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
}
export default Header;
