import React,{ useId } from "react";
import { Box } from "@mui/material";
import Slider from "react-slick";
import CarouselCard from "./carousel-card";
import "./authCarousel.scss";
import { LogoIcon } from "../../utils/common/constants";
import { v4 as uuidv4 } from "uuid";
import {chartLine , clock, flask, stats} from "../../utils/common/constants";

const LogoWithCarousel = () => {
  const carouselCards = [
    {
      title: "Aesthetic Graphics",
      subtitle: "A Symphony of Sight: Elevating Design to Artistic Heights",
      icon: chartLine,
    },
    {
      title: "Real-Time Statistics",
      subtitle: "Harnessing the Power of Now: Insights Unfolding in Real Time",
      icon: clock,
    },
    {
      title: "Track Equipment",
      subtitle: "Precision Instruments for the Modern Trailblazer: Navigate with Confidence",
      icon: flask,
    },
    {
      title: "Predict upcoming trends",
      subtitle: "Deciphering Tomorrow: Insights to Anticipate the Future",
      icon: stats,
    }
  ];
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    draggable: false,
    arrows: false,
    className: "center",
    centerMode: true,
  };
  return (
    <>
      <Box className="logoContainer">
        <img src={LogoIcon} alt="logo-icon"/>
        <h1>ALFRED</h1>
        <h2>Your Personal Carwash Assistant</h2>
      </Box>
      <Box className="carouselContainer">
        <Slider {...settings}>{carouselCards.map((card: any) => {
          return <CarouselCard key={uuidv4()} title={card.title} subtitle={card.subtitle} icon={card.icon} />
        })}</Slider>
      </Box>
    </>
  );
};

export default LogoWithCarousel;
