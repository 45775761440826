import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postDataApi,
} from "../../utils/common/constants";
import { setLoading } from "../features/global.slice";

/**
 * Fetches all widgets for the dashboard and saves them to the Redux store.
 *
 * @param allWidgets - An object containing the widget data to be saved.
 * @returns The fetched widget data, or an empty array if the fetch fails.
 */
export const dashboardAllWidgets = createAsyncThunk(
    "dashboard/widget",
    async (
        allWidgets: any,
        { fulfillWithValue, rejectWithValue, dispatch }
    ) => {
        try {
            dispatch(setLoading(true));
            const res: any = await postDataApi({
                path: "alfred/dashboard-prefs",
                data: { desktop_json: allWidgets },
            });
            dispatch(setLoading(false));
            if (res && res.desktop_json) {

                return fulfillWithValue(res.desktop_json);
            } else {
                return fulfillWithValue([]);
            }
        } catch (error: any) {
            const message =
                error.response && error.response.data && error.response.data.message;
            dispatch(setLoading(false));
            return rejectWithValue(message);
        }
    }
);

/**
 * Fetches all calculated widgets for the dashboard and saves them to the Redux store.
 *
 * @param allCalculatedWidgetWidgets - An object containing the calculated widget data to be saved.
 * @returns The fetched calculated widget data, or an empty array if the fetch fails.
 */
export const dashboardCalculatedWidget = createAsyncThunk(
    "dashboard/CalculatedWidget",
    async (
        allCalculatedWidgetWidgets: any,
        { fulfillWithValue, rejectWithValue }
    ) => {
        try {
            return fulfillWithValue(allCalculatedWidgetWidgets);

        } catch (error: any) {
            return rejectWithValue("");
        }
    }
);