import React, { useEffect, useState } from "react";
import { Box, Switch } from "@mui/material";
import "./notification.scss";
import useSettingHelper from "../helper";
import { useSelector } from "react-redux";
import { UserDetail, User } from "src/redux/types/userDetail";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Notification = () => {
  const [emailAlert, setEmailAlert] = useState<boolean>(true);
  const [loactionAlert, setLocationAlert] = useState<boolean>(true);
  const [valveAlert, setValveAlert] = useState<boolean>(true);
  const { emailAlertChange, locationAlertChange, valveAlertChange } =
    useSettingHelper();
  const userInfo: UserDetail = useSelector((state: any) => state.userInfo);
  const userDetails: User = userInfo.userDetail;

  /* The `useEffect` hook in the provided code snippet is responsible for setting the initial state of
  the email, location, and valve alerts based on the `userDetails.system_alerts_block_list` array.
  Here's a breakdown of what the `useEffect` is doing: */
  useEffect(() => {
    if (
      userDetails.system_alerts_block_list &&
      userDetails.system_alerts_block_list.length === 0
    ) {
      setEmailAlert(true);
      setLocationAlert(true);
      setValveAlert(true);
    }
    if (
      userDetails.system_alerts_block_list &&
      userDetails.system_alerts_block_list[0] === "ALL"
    ) {
      setEmailAlert(false);
      setLocationAlert(true);
      setValveAlert(true);
    }
    if (
      userDetails?.system_alerts_block_list &&
      userDetails.system_alerts_block_list.length > 0
    ) {
      const alertList = userDetails.system_alerts_block_list;
      if (alertList) {
        if (alertList.includes("GATEWAY_DISCONNECTED_FRESH")) {
          setLocationAlert(false);
        }
        if (alertList.includes("VALVE_OVERVOLT")) {
          setValveAlert(false);
        }
        if (alertList.includes("ALL")) {
          setEmailAlert(false);
        }
      }
    }
  }, [userDetails?.system_alerts_block_list]);

 /**
  * The below functions handle changes in email, location, and valve alerts by toggling their
  * respective states and triggering corresponding alert change functions.
  */
  const handleEmailAlertChange = () => {
    setEmailAlert((prevEmailAlert) => {
      const newEmailAlert = !prevEmailAlert;
      emailAlertChange(newEmailAlert, loactionAlert, valveAlert);
      return newEmailAlert;
    });
  };
  const handdleLocationAlertChange = () => {
    setLocationAlert((prevLocationAlert) => {
      const newLocationAlert = !prevLocationAlert;
      locationAlertChange(emailAlert, newLocationAlert, valveAlert);
      return newLocationAlert;
    });
  };
  const handdleValveAlertChange = () => {
    setValveAlert((prevValveAlert) => {
      const newValveAlert = !prevValveAlert;
      valveAlertChange(emailAlert, loactionAlert, newValveAlert);
      return newValveAlert;
    });
  };

  return (
    <Box>
      <div className="topHeadingWrapper">
        <h1 className="title">Notification Settings</h1>
        <h2 className="subTitle">
          Choose whether to be notified by email when an alert occurs
        </h2>
      </div>

      <p className="typeTitle">NOTIFICATION TYPE</p>

      <div className="alertWrapper">
        <div className="leftCol">
          <h2 className="typeHeading">Email Alert</h2>
          <p className="typeSubTitle">Get emails on every triggered alerts</p>
        </div>
        <div className="checkAlertWrapper">
          <Switch
            {...label}
            checked={emailAlert}
            onChange={handleEmailAlertChange}
          />
        </div>
      </div>

      {emailAlert && (
        <>
          <div className="alertWrapper borderBottom">
            <div className="leftCol">
              <p className="typeTitle">Category</p>
              <h2 className="typeHeading">Location Monitoring Disrupted</h2>
              <p className="typeSubTitle">
                Alfred will email an alert when connection to the Alfred Gateway
                is lost for over 15 minutes.
              </p>
            </div>
            <div className="checkAlertWrapper">
              <Switch
                {...label}
                checked={loactionAlert}
                onChange={handdleLocationAlertChange}
              />
            </div>
          </div>

          <div className="alertWrapper">
            <div className="leftCol">
              <h2 className="typeHeading">Valve Overvoltage</h2>
              <p className="typeSubTitle">
                Alfred will email an alert when it detects an overvoltage on any
                valve of an Aqua-Lab™ panel.
              </p>
            </div>
            <div className="checkAlertWrapper">
              <Switch
                {...label}
                checked={valveAlert}
                onChange={handdleValveAlertChange}
              />
            </div>
          </div>
        </>
      )}
    </Box>
  );
};

export default Notification;
