import { FC } from "react";

const ChemSenseSvg: FC<{}> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#00ACE5" />
      <g clipPath="url(#clip0_2564_9320)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.25 12.5039H26.75V11.0039H13.25V12.5039H14.75V23.7539C14.75 26.6534 17.1005 29.0039 20 29.0039C22.8995 29.0039 25.25 26.6534 25.25 23.7539V12.5039ZM23.75 12.5039H16.25V15.5039H23.75V12.5039Z"
          fill="url(#paint0_linear_2564_9320)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2564_9320"
          x1="20"
          y1="11.0039"
          x2="20"
          y2="29.0039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
        <clipPath id="clip0_2564_9320">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(11 11.0039)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChemSenseSvg;
