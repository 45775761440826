import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./user-settings.scss";
import UserAccount from "./userAccount";
import Notification from "./notification";
import PrivacyPolicy from "./privacy-policy";
import AboutUs from "./about-us";
import TermConditions from "./term-condition";
import ConfirmationContainer from "src/components/pop-ups/confimation";
import SimpleModal from "src/components/modal/modal";
import Logout from "src/components/pop-ups/logout";
import useSettingHelper from "./helper";
import { getUserId } from "src/utils";
import { userDetail } from "src/redux/actions/userDetail.action";
import { useAppDispatch } from "src/hooks/useAppDispatch";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

/* The below code is a TypeScript React component called `UserSettings`. It is rendering a user
settings page with tabs for different sections such as Account Information, Notification Settings,
About Us, Privacy Policies, and Terms & Conditions. Each tab has an icon and corresponding content
displayed below the tabs. */
const UserSettings = () => {
  const { isLogout, handleLogoutPopUpClose, handleLogout } = useSettingHelper();
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleUserLogout = (flag: boolean) => {
    handleLogoutPopUpClose(flag);
  };

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    const user_id = getUserId();
    if (user_id) {
      dispatch(userDetail(user_id));
    }
  },[])
  
  return (
    <Box className="aqua_lab_container">
      <Box className="aqua_lab_inner_container">
        <Box className="panel_selection_container">
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            centered={false}
            visibleScrollbar={false}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              className="customVerticalTabs"
              label="Account Information"
              {...a11yProps(0)}
              icon={
                value === 0 ? (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2914_5126)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.9997 0.457031C7.96211 0.457031 5.49967 2.91946 5.49967 5.95703C5.49967 8.9946 7.96211 11.457 10.9997 11.457C14.0372 11.457 16.4997 8.9946 16.4997 5.95703C16.4997 2.91946 14.0372 0.457031 10.9997 0.457031ZM10.9997 12.832C7.65664 12.832 5.12797 13.549 3.41983 14.2812C2.5669 14.6467 1.92049 15.0151 1.48054 15.298C1.26055 15.4394 1.09207 15.5595 0.975063 15.6472C0.916552 15.6911 0.870896 15.7269 0.838081 15.7533C0.821671 15.7665 0.808471 15.7773 0.798478 15.7855L0.785893 15.7961L0.781403 15.7999L0.779609 15.8014L0.778825 15.8021C0.778464 15.8024 0.778116 15.8027 1.37467 16.4987L0.778825 15.8021L0.458008 16.0771V21.5404H21.5413V16.0771L21.2212 15.8027L20.6247 16.4987C21.2212 15.8027 21.2209 15.8024 21.2205 15.8021L21.2198 15.8014L21.2179 15.7999L21.2134 15.7961L21.2009 15.7855C21.1909 15.7773 21.1777 15.7665 21.1613 15.7533C21.1285 15.7269 21.0828 15.6911 21.0242 15.6472C20.9073 15.5595 20.7388 15.4393 20.5188 15.298C20.0789 15.0151 19.4325 14.6467 18.5795 14.2812C16.8714 13.549 14.3428 12.832 10.9997 12.832Z"
                        fill="#004E9A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2914_5126">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2914_5126)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.9997 0.457031C7.96211 0.457031 5.49967 2.91946 5.49967 5.95703C5.49967 8.9946 7.96211 11.457 10.9997 11.457C14.0372 11.457 16.4997 8.9946 16.4997 5.95703C16.4997 2.91946 14.0372 0.457031 10.9997 0.457031ZM10.9997 12.832C7.65664 12.832 5.12797 13.549 3.41983 14.2812C2.5669 14.6467 1.92049 15.0151 1.48054 15.298C1.26055 15.4394 1.09207 15.5595 0.975063 15.6472C0.916552 15.6911 0.870896 15.7269 0.838081 15.7533C0.821671 15.7665 0.808471 15.7773 0.798478 15.7855L0.785893 15.7961L0.781403 15.7999L0.779609 15.8014L0.778825 15.8021C0.778464 15.8024 0.778116 15.8027 1.37467 16.4987L0.778825 15.8021L0.458008 16.0771V21.5404H21.5413V16.0771L21.2212 15.8027L20.6247 16.4987C21.2212 15.8027 21.2209 15.8024 21.2205 15.8021L21.2198 15.8014L21.2179 15.7999L21.2134 15.7961L21.2009 15.7855C21.1909 15.7773 21.1777 15.7665 21.1613 15.7533C21.1285 15.7269 21.0828 15.6911 21.0242 15.6472C20.9073 15.5595 20.7388 15.4393 20.5188 15.298C20.0789 15.0151 19.4325 14.6467 18.5795 14.2812C16.8714 13.549 14.3428 12.832 10.9997 12.832Z"
                        fill="#717C99"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2914_5126">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )
              }
            />
            <Tab
              className="customVerticalTabs"
              label="Notification Settings"
              {...a11yProps(1)}
              icon={
                value === 1 ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.3329 7.4987C3.3329 3.8168 6.31767 0.832031 9.99959 0.832031C13.6814 0.832031 16.6663 3.8168 16.6663 7.4987V11.4445L19.1674 15.832H0.832031L3.3329 11.4445V7.4987ZM8.33284 19.1654H11.6662V17.4987H8.33284V19.1654Z"
                      fill="#004E9A"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.3329 7.4987C3.3329 3.8168 6.31767 0.832031 9.99959 0.832031C13.6814 0.832031 16.6663 3.8168 16.6663 7.4987V11.4445L19.1674 15.832H0.832031L3.3329 11.4445V7.4987ZM8.33284 19.1654H11.6662V17.4987H8.33284V19.1654Z"
                      fill="#717C99"
                    />
                  </svg>
                )
              }
            />

            <Tab
              className="customVerticalTabs"
              label="About Us"
              {...a11yProps(2)}
              icon={
                value === 2 ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2914_5132)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.50002 0C5.19922 0 3.33404 1.86517 3.33404 4.16597C3.33404 6.46677 5.19922 8.33194 7.50002 8.33194C9.80083 8.33194 11.666 6.46677 11.666 4.16597C11.666 1.86517 9.80083 0 7.50002 0ZM8.27215 16.459L8.39125 16.6653H0V11.9382L0.291008 11.6888L0.833333 12.3214C0.291008 11.6888 0.291322 11.6884 0.291644 11.6882L0.292333 11.6876L0.293868 11.6863L0.297568 11.6832L0.307483 11.6748C0.315212 11.6684 0.325178 11.6603 0.337383 11.6504C0.36179 11.6308 0.395161 11.6047 0.4375 11.5729C0.522167 11.5094 0.64278 11.4235 0.799369 11.3228C1.11252 11.1215 1.56996 10.861 2.17173 10.6031C3.37738 10.0864 5.15533 9.58333 7.5 9.58333C8.59258 9.58333 9.56208 9.69258 10.408 9.8615L8.27148 13.5621L9.28367 15.0105L8.27215 16.459ZM16.8276 11.2904L16.2329 10.0195H14.0121L13.4176 11.2916L12.7551 11.6741L11.3562 11.5536L10.2458 13.4769L11.0505 14.6284V15.3922L10.2463 16.5439L11.3567 18.4673L12.7558 18.3461L13.4178 18.7283L14.0121 20.0002H16.2329L16.8278 18.7291L17.4915 18.3459L18.8893 18.4673L19.9997 16.5439L19.1968 15.3938L19.1967 14.6263L20.0002 13.4769L18.8898 11.5536L17.4918 11.6739L16.8276 11.2904ZM14.2903 15.7832V14.1166H15.957V15.7832H14.2903Z"
                        fill="#004E9A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2914_5132">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2914_5132)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.50002 0C5.19922 0 3.33404 1.86517 3.33404 4.16597C3.33404 6.46677 5.19922 8.33194 7.50002 8.33194C9.80083 8.33194 11.666 6.46677 11.666 4.16597C11.666 1.86517 9.80083 0 7.50002 0ZM8.27215 16.459L8.39125 16.6653H0V11.9382L0.291008 11.6888L0.833333 12.3214C0.291008 11.6888 0.291322 11.6884 0.291644 11.6882L0.292333 11.6876L0.293868 11.6863L0.297568 11.6832L0.307483 11.6748C0.315212 11.6684 0.325178 11.6603 0.337383 11.6504C0.36179 11.6308 0.395161 11.6047 0.4375 11.5729C0.522167 11.5094 0.64278 11.4235 0.799369 11.3228C1.11252 11.1215 1.56996 10.861 2.17173 10.6031C3.37738 10.0864 5.15533 9.58333 7.5 9.58333C8.59258 9.58333 9.56208 9.69258 10.408 9.8615L8.27148 13.5621L9.28367 15.0105L8.27215 16.459ZM16.8276 11.2904L16.2329 10.0195H14.0121L13.4176 11.2916L12.7551 11.6741L11.3562 11.5536L10.2458 13.4769L11.0505 14.6284V15.3922L10.2463 16.5439L11.3567 18.4673L12.7558 18.3461L13.4178 18.7283L14.0121 20.0002H16.2329L16.8278 18.7291L17.4915 18.3459L18.8893 18.4673L19.9997 16.5439L19.1968 15.3938L19.1967 14.6263L20.0002 13.4769L18.8898 11.5536L17.4918 11.6739L16.8276 11.2904ZM14.2903 15.7832V14.1166H15.957V15.7832H14.2903Z"
                        fill="#717C99"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2914_5132">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )
              }
            />

            <Tab
              className="customVerticalTabs"
              label="Privacy Policies"
              {...a11yProps(3)}
              icon={
                value === 3 ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2914_5135)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.50002 0C5.19922 0 3.33404 1.86517 3.33404 4.16597C3.33404 6.46677 5.19922 8.33194 7.50002 8.33194C9.80083 8.33194 11.666 6.46677 11.666 4.16597C11.666 1.86517 9.80083 0 7.50002 0ZM10 12.5V16.6653H0V11.9382L0.291007 11.6887L0.833333 12.3214C0.291007 11.6887 0.291644 11.6882 0.291644 11.6882L0.292333 11.6876L0.293868 11.6862L0.297568 11.6832L0.307483 11.6748C0.315212 11.6684 0.325177 11.6602 0.337382 11.6504C0.36179 11.6308 0.395161 11.6047 0.4375 11.5729C0.522167 11.5094 0.64278 11.4235 0.799369 11.3228C1.11252 11.1215 1.56996 10.861 2.17173 10.6031C3.37737 10.0864 5.15532 9.58333 7.5 9.58333C9.27458 9.58333 10.7246 9.8715 11.8475 10.2357C11.4672 10.9036 11.25 11.6764 11.25 12.5H10ZM12.9167 12.5C12.9167 10.8892 14.2225 9.58333 15.8333 9.58333C17.4442 9.58333 18.75 10.8892 18.75 12.5V14.1667H20V20H11.6667V14.1667H12.9167V12.5ZM14.5833 14.1667V12.5C14.5833 11.8097 15.143 11.25 15.8333 11.25C16.5237 11.25 17.0833 11.8097 17.0833 12.5V14.1667H14.5833Z"
                        fill="#004E9A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2914_5135">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2914_5135)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.50002 0C5.19922 0 3.33404 1.86517 3.33404 4.16597C3.33404 6.46677 5.19922 8.33194 7.50002 8.33194C9.80083 8.33194 11.666 6.46677 11.666 4.16597C11.666 1.86517 9.80083 0 7.50002 0ZM10 12.5V16.6653H0V11.9382L0.291007 11.6887L0.833333 12.3214C0.291007 11.6887 0.291644 11.6882 0.291644 11.6882L0.292333 11.6876L0.293868 11.6862L0.297568 11.6832L0.307483 11.6748C0.315212 11.6684 0.325177 11.6602 0.337382 11.6504C0.36179 11.6308 0.395161 11.6047 0.4375 11.5729C0.522167 11.5094 0.64278 11.4235 0.799369 11.3228C1.11252 11.1215 1.56996 10.861 2.17173 10.6031C3.37737 10.0864 5.15532 9.58333 7.5 9.58333C9.27458 9.58333 10.7246 9.8715 11.8475 10.2357C11.4672 10.9036 11.25 11.6764 11.25 12.5H10ZM12.9167 12.5C12.9167 10.8892 14.2225 9.58333 15.8333 9.58333C17.4442 9.58333 18.75 10.8892 18.75 12.5V14.1667H20V20H11.6667V14.1667H12.9167V12.5ZM14.5833 14.1667V12.5C14.5833 11.8097 15.143 11.25 15.8333 11.25C16.5237 11.25 17.0833 11.8097 17.0833 12.5V14.1667H14.5833Z"
                        fill="#717C99"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2914_5135">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )
              }
            />

            <Tab
              className="customVerticalTabs"
              label="Terms & Conditions"
              {...a11yProps(4)}
              icon={
                value === 4 ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.66699 0H13.6788L18.3337 4.65483V20H1.66699V0ZM5.83366 6.25H10.0003V4.58333H5.83366V6.25ZM5.83366 10.8333V9.16667H14.167V10.8333H5.83366ZM5.83366 13.75V15.4167H14.167V13.75H5.83366Z"
                      fill="#004E9A"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.66699 0H13.6788L18.3337 4.65483V20H1.66699V0ZM5.83366 6.25H10.0003V4.58333H5.83366V6.25ZM5.83366 10.8333V9.16667H14.167V10.8333H5.83366ZM5.83366 13.75V15.4167H14.167V13.75H5.83366Z"
                      fill="#717C99"
                    />
                  </svg>
                )
              }
            />
          </Tabs>
          <Tab
            label="Logout"
            className="customVerticalTabs"
            onClick={() => {
              handleLogout();
            }}
            icon={
              value === 4 ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7082 6C16.4931 4.64142 14.894 3.68382 13.1227 3.25392C11.3514 2.82401 9.49134 2.94206 7.7886 3.59245C6.08586 4.24284 4.62073 5.39491 3.58709 6.89621C2.55344 8.39751 2 10.1773 2 12C2 13.8227 2.55344 15.6025 3.58709 17.1038C4.62073 18.6051 6.08586 19.7572 7.7886 20.4076C9.49134 21.0579 11.3514 21.176 13.1227 20.7461C14.894 20.3162 16.4931 19.3586 17.7082 18"
                    stroke="#EA2027"
                    strokeWidth="2"
                  />
                  <path d="M22 12H8" stroke="#EA2027" strokeWidth="2" />
                  <path
                    d="M18 8L22 12L18 16"
                    stroke="#EA2027"
                    strokeWidth="1.5"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7082 6C16.4931 4.64142 14.894 3.68382 13.1227 3.25392C11.3514 2.82401 9.49134 2.94206 7.7886 3.59245C6.08586 4.24284 4.62073 5.39491 3.58709 6.89621C2.55344 8.39751 2 10.1773 2 12C2 13.8227 2.55344 15.6025 3.58709 17.1038C4.62073 18.6051 6.08586 19.7572 7.7886 20.4076C9.49134 21.0579 11.3514 21.176 13.1227 20.7461C14.894 20.3162 16.4931 19.3586 17.7082 18"
                    stroke="#EA2027"
                    strokeWidth="2"
                  />
                  <path d="M22 12H8" stroke="#EA2027" strokeWidth="2" />
                  <path
                    d="M18 8L22 12L18 16"
                    stroke="#EA2027"
                    strokeWidth="1.5"
                  />
                </svg>
              )
            }
          />
        </Box>

        <Box className="pump_detail_container">
          <TabPanel value={value} index={0}>
            <UserAccount />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Notification />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AboutUs />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PrivacyPolicy />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <TermConditions />
          </TabPanel>
        </Box>
      </Box>
      <SimpleModal isOpen={isLogout} handleClose={handleLogoutPopUpClose}>
        <ConfirmationContainer>
          <Logout handleUserLogout={handleUserLogout} />
        </ConfirmationContainer>
      </SimpleModal>
    </Box>
  );
};

export default UserSettings;
