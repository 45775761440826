import React from "react";
import { chartLine } from "../../utils/common/constants";

function CarouselCard({ title, subtitle, icon }: any) {
  return (
    <div className="caroulsel_item">
      <div className="hd">
        {title}<img src={icon} alt="" />
      </div>
      <div className="shd">
        {subtitle}
      </div>
    </div>
  );
}

export default CarouselCard;
