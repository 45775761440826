import { CSSProperties } from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { COLORS } from "../../../utils/common/constants";

type StyleKeys = "mainContainer" | "paper";

const useStyles = (): {
  [key in StyleKeys]?: CSSProperties & SxProps<Theme>;
} => {
  return {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    paper: {
      position: "absolute",
      backgroundColor: COLORS.white,
      padding: "6% 3% 6%",
      minWidth: "25%",
      maxWidth: "35%",
    },
  };
};

export default useStyles;
