import * as Plot from "@observablehq/plot";
import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import "./index.scss";
import { formatNumberWithCommas } from "src/utils";

const getYFormat = (value) => {
  if (value === 0) {
    return "0";
  }
  if (value > 999) {
    return `${value / 1000} K`;
  } else {
    return value;
  }
};

const options = [
  { value: "v1cycles", label: "Valve 1", color: "#b278a2" },
  { value: "v2cycles", label: "Valve 2", color: "#4c78a8" },
  { value: "v3cycles", label: "Valve 3", color: "#55a24a" },
  { value: "v4cycles", label: "Valve 4", color: "#eeca3b" },
  { value: "v5cycles", label: "Valve 5", color: "#f58518" },
  { value: "v6cycles", label: "Valve 6", color: "#e55756" },
  { value: "v7cycles", label: "Valve 7", color: "#9d755e" },
  { value: "All", label: "All", color: "" },
];

const hover = (tip, pos, text) => {
  const side_padding = 10;
  const vertical_padding = 15;
  const vertical_offset = 15;

  // Empty it out
  tip.selectAll("*").remove();

  // Append the text
  tip
    .style("text-anchor", "middle")
    .style("pointer-events", "none")
    .attr("transform", `translate(${pos[0]}, ${pos[1] + 7})`)
    .selectAll("text")
    .data(text)
    .join("text")
    .style("dominant-baseline", "ideographic")
    .text((d) => d)
    .attr("y", (d, i) => (i - (text.length - 1)) * 15 - vertical_offset)
    .style("font-weight", (d, i) => (i === 0 ? "bold" : "normal"));

  const bbox = tip.node().getBBox();

  // Add a rectangle (as background)
  tip
    .append("rect")
    .attr("y", bbox.y - vertical_padding)
    .attr("x", bbox.x - side_padding)
    .attr("width", bbox.width + side_padding * 2)
    .attr("height", bbox.height + vertical_padding * 2)
    .attr("rx", 7) // Rounded corner x-radius
    .attr("ry", 7) // Rounded corner y-radius
    .style("fill", "white")
    .style("stroke", "#d3d3d3")
    .lower();
};

const addTooltips = (chart, styles) => {
  const stroke_styles = {};
  const fill_styles = { fill: "blue", opacity: 0.5 };

  // Workaround if it's in a figure
  const type = d3.select(chart).node().tagName;
  let wrapper =
    type === "FIGURE" ? d3.select(chart).select("svg") : d3.select(chart);

  // Workaround if there's a legend....
  const svgs = d3.select(chart).selectAll("svg");
  if (svgs.size() > 1) wrapper = d3.select([...svgs].pop());
  wrapper.style("overflow", "visible"); // to avoid clipping at the edges

  // Set pointer events to visibleStroke if the fill is none (e.g., if its a line)
  wrapper.selectAll("path").each(function (data, index, nodes) {
    // For line charts, set the pointer events to be visible stroke
    if (
      d3.select(this).attr("fill") === null ||
      d3.select(this).attr("fill") === "none"
    ) {
      d3.select(this).style("pointer-events", "visibleStroke");
      if (styles === undefined) styles = stroke_styles;
    }
  });

  if (styles === undefined) styles = fill_styles;

  const tip = wrapper
    .selectAll(".hover")
    .data([1])
    .join("g")
    .attr("class", "hover")
    .style("pointer-events", "none")
    .style("text-anchor", "middle");

  const id_generator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return "a" + S4() + S4();
  };
  // Add a unique id to the chart for styling
  const id = id_generator();

  // Add the event listeners
  d3.select(chart).classed(id, true); // using a class selector so that it doesn't overwrite the ID
  wrapper.selectAll("title").each(function () {
    // Get the text out of the title, set it as an attribute on the parent, and remove it
    const title = d3.select(this); // title element that we want to remove
    const parent = d3.select(this.parentNode); // visual mark on the screen
    const t = title.text();
    if (t) {
      parent.attr("__title", t).classed("has-title", true);
      title.remove();
    }
    // Mouse events
    parent
      .on("pointerenter pointermove", function (event) {
        const text = d3.select(this).attr("__title");
        const pointer = d3.pointer(event, wrapper.node());
        if (text) tip.call(hover, pointer, text.split("\n"));
        else tip.selectAll("*").remove();

        // Raise it
        d3.select(this).raise();
        // Keep within the parent horizontally
        const tipSize = tip.node().getBBox();
        if (pointer[0] + tipSize.x < 0)
          tip.attr(
            "transform",
            `translate(${tipSize.width / 2}, ${pointer[1] + 7})`
          );
        else if (pointer[0] + tipSize.width / 2 > wrapper.attr("width"))
          tip.attr(
            "transform",
            `translate(${wrapper.attr("width") - tipSize.width / 2}, ${
              pointer[1] + 7
            })`
          );
      })
      .on("pointerout", function (event) {
        tip.selectAll("*").remove();
        // Lower it!
        d3.select(this).lower();
      });
  });

  // Remove the tip if you tap on the wrapper (for mobile)
  wrapper.on("touchstart", () => tip.selectAll("*").remove());

  return chart;
};

export default function MyPlot({ data, style = {}, startDate, endDate }) {
  const allCycles = [
    {
      value: "v1cycles",
      color: "#b278a2",
    },
    {
      value: "v2cycles",
      color: "#4c78a8",
    },
    {
      value: "v3cycles",
      color: "#55a24a",
    },
    {
      value: "v4cycles",
      color: "#eeca3b",
    },
    {
      value: "v5cycles",
      color: "#f58518",
    },
    {
      value: "v6cycles",
      color: "#e55756",
    },
    {
      value: "v7cycles",
      color: "#9d755e",
    },
  ];

  const [Cycles, setCycles] = useState(allCycles);
  const [selectedOption, setSelectedOption] = useState("All");

  const ref = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setSelectedOption("All");
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCycles(
      allCycles.filter((item) => {
        if (selectedOption === "All") {
          return true;
        } else {
          return item.value === selectedOption;
        }
      })
    );
    // eslint-disable-next-line
  }, [selectedOption]);

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (data) {
      const filteredData = data.filter((item) => {
        if (startDate && endDate) {
          const startTime = new Date(startDate).getTime() / 1000;
          let endTime = new Date(endDate).getTime() / 1000;
          return startTime <= item.unixtime && item.unixtime <= endTime;
        } else {
          return true;
        }
      });

      const uData = [];
      filteredData.forEach((item) => {
        uData.push({ time: item.time, value: item.v1cycles, type: "Valve 1" });
        uData.push({ time: item.time, value: item.v2cycles, type: "Valve 2" });
        uData.push({ time: item.time, value: item.v3cycles, type: "Valve 3" });
        uData.push({ time: item.time, value: item.v4cycles, type: "Valve 4" });
        uData.push({ time: item.time, value: item.v5cycles, type: "Valve 5" });
        uData.push({ time: item.time, value: item.v6cycles, type: "Valve 6" });
        uData.push({ time: item.time, value: item.v7cycles, type: "Valve 7" });
      });

      const uf = uData.filter((item) => {
        if (selectedOption === "All") {
          return true;
        } else {
          return item.type === selectedOption;
        }
      });

      const yMax = Math.max(...uf.map((item) => item.value));

      const accumulativeY = options.reduce((accumulator, currentValue) => {
        const typeData = uf.filter((item) => item.type === currentValue.label);
        const typeDataValues = typeData.map((item) => item.value);
        return (
          accumulator +
          (typeDataValues.length ? Math.max(...typeDataValues) : 0)
        );
      }, 0);

      const barChart = Plot.plot({
        marks: [
          Plot.areaY(
            uf,
            Plot.stackY(
              { reverse: true },
              {
                x: "time",
                y: "value",
                fill: "type",
                curve: "catmull-rom",
                z: "type",
                title: (d) => {
                  const value = formatNumberWithCommas(String(d?.value))
                  const time = new Date(d.time);
                  return `${value} \n ${
                    monthNames[time.getMonth()]
                  } ${time.getDate()} ${time.getFullYear()}`;
                },
              }
            )
          ),
        ],
        color: {
          domain: [
            "Valve 1",
            "Valve 2",
            "Valve 3",
            "Valve 4",
            "Valve 5",
            "Valve 6",
            "Valve 7",
          ],
          range: [
            "#b278a2",
            "#4c78a8",
            "#55a24a",
            "#eeca3b",
            "#f58518",
            "#e55756",
            "#9d755e",
          ],
        },
        x: {
          domain: [new Date(startDate), new Date(endDate)],
        },
        y: {
          ticks: 6,
          domain: [0, yMax > 0 ? accumulativeY * 1.3 : 1000],
          grid: true,
          tickFormat: getYFormat,
        },
        marginBottom: 50,
        marginLeft: 50,
        labelArrow: "none",
        label: "",
      });

      ref.current.append(addTooltips(barChart));
      return () => barChart.remove();
    }
    // eslint-disable-next-line
  }, [data, Cycles, endDate, startDate]);

  return (
    <>
      <div className="graph-container" id="graph" style={style} ref={ref}></div>
      <div className="label_container" ref={containerRef}>
        <h3>Select Valve</h3>
        <div className="label-area">
          {options
            .filter((item) => item.value !== "All")
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={`label_name ${
                    selectedOption === "All" || selectedOption === item.label
                      ? ""
                      : "label_opacity"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedOption(item.label)}
                >
                  <span
                    className="label_circle"
                    style={{
                      backgroundColor: item.color,
                    }}
                  ></span>
                  {item.label}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
