import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AquaLabState, PumpsAndPanel } from "../types/aquaLab.type";
import { panelAndPumpDetail } from "../actions/aqua.lab.action";
const initialState: AquaLabState = {
  selectedPumpPanel: {
    id: "",
    modbus_id: 0,
    position: 0,
    type: "",
    name: "",
  },
  iot_hub_device_id: "",
  pending: true
};

const aquaLabSlice = createSlice({
  name: "aquaLab",
  initialState,
  reducers: {
    setSelectedPumpPanel: (
      state: AquaLabState,
      action: PayloadAction<PumpsAndPanel>
    ) => {
      state.selectedPumpPanel = action.payload;
    },
    resetPumpPanel: (state: AquaLabState) => {
      state.selectedPumpPanel = initialState.selectedPumpPanel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(panelAndPumpDetail.pending, (state) => {
        state.pending = true;
      })
      .addCase(panelAndPumpDetail.fulfilled, (state, action: PayloadAction<any>) => {
        state.pending = false;
        state = action.payload;
      })
      .addCase(panelAndPumpDetail.rejected, (state, action: PayloadAction<any>) => {
        state.pending = false;
      });
  },
});

export const { setSelectedPumpPanel, resetPumpPanel } = aquaLabSlice.actions;

export default aquaLabSlice.reducer;
