import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import ROUTES from "./routes";
import LazyLoader from "../utils/common/routesContainer";
import { lazy, useEffect, useState } from "react";
import LoginWrapper from "../layout/loginWrapper";
import PrivateRoute from "./PrivateRoute";
import PageContainer from "../layout/headerWrapper";
import AquaLab from "src/Modules/aqua-lab";
import UserSettings from "src/Modules/user-settings";
import LocationDetails from "src/Modules/location/locationDetails";
import NotFound from "src/components/notFound";
import NoNetwork from "src/components/Network/noNetwork";
import WaterCost from "src/Modules/location/watercost";

const NotificationComp = LazyLoader(
  lazy(() => import("src/Modules/notifications"))
);
const SignUp = LazyLoader(lazy(() => import("../Modules/signup")));
const Login = LazyLoader(lazy(() => import("../Modules/login")));
const Dashboard = LazyLoader(lazy(() => import("../Modules/dashboard")));
const DashboardEdit = LazyLoader(
  lazy(() => import("../Modules/dashboard/edit-widgets"))
);
const ForgotPassword = LazyLoader(lazy(() => import("../Modules/forgot")));
const WaterSense = LazyLoader(lazy(() => import("../Modules/water-sense")));
const ChemSense = LazyLoader(lazy(() => import("../Modules/chem-sense")));
const GatewayStats = LazyLoader(
  lazy(() => import("../Modules/location/gateway-stats"))
);
const BusinessHours = LazyLoader(
  lazy(() => import("../Modules/location/business-hours"))
);
const ViewBusinessHours = LazyLoader(
  lazy(() => import("../Modules/location/view-business-hours"))
);

const ChemSenseChemicalDetail = LazyLoader(
  lazy(
    () => import("src/Modules/chem-sense/chem-sense-dasboard/chem-sense-detail")
  )
);
const ChemSenseChemicalSettings = LazyLoader(
  lazy(
    () =>
      import("src/Modules/chem-sense/chem-sense-dasboard/chem-sense-settings")
  )
);

const LocationWrapper = LazyLoader(
  lazy(() => import("../layout/locationWrapper"))
);
const ChemSenseGraphWebView = LazyLoader(
  lazy(() => import("../components/chem-sense-graph-web-view"))
);

const PumpWebView = LazyLoader(
  lazy(() => import("../components/pump-web-view"))
);
const PumpPowerWebView = LazyLoader(
  lazy(() => import("../components/pump-power-web-view"))
);

const TotalCyclesGraphFilterWebView = LazyLoader(
  lazy(() => import("../components/total-cycles-graph-web-view"))
);

const TotalCyclesGraphWebView = LazyLoader(
  lazy(() => import("../components/total-cycles-graph-web-view/graph"))
);
const TotalCycles = LazyLoader(
  lazy(() => import("../components/total-cycles-graph-web-view/CookieGraph"))
);
const TotalCyclesPanel = LazyLoader(
  lazy(() => import("../components/total-cycles-graph-web-view/graphPanel"))
);

const UserMangement = LazyLoader(
  lazy(() => import("src/Modules/user-management/index"))
);

const AddUserComponent = LazyLoader(
  lazy(() => import("src/Modules/user-management/add-user"))
);

const UserDetailsComponent = LazyLoader(
  lazy(() => import("src/Modules/user-management/user-details"))
);

const EditUserComponent = LazyLoader(
  lazy(() => import("src/Modules/user-management/edit-user"))
);
const AddWidget = LazyLoader(
  lazy(() => import("src/Modules/dashboard/add-widget-settings"))
);

const TermsComponent = LazyLoader(lazy(() => import("src/Modules/terms")));

const RoutesManager = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={ROUTES.AUTH_ROUTES.LOGIN}
          element={
            <LoginWrapper
              childComp={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
          }
        ></Route>
        <Route
          path={ROUTES.AUTH_ROUTES.TERMS}
          element={<TermsComponent />}
        ></Route>
        <Route
          path={ROUTES.AUTH_ROUTES.SIGN_UP}
          element={
            <LoginWrapper
              childComp={
                <PublicRoute>
                  <SignUp />
                </PublicRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.AUTH_ROUTES.FORGOT_PASSWORD}
          element={
            <LoginWrapper
              childComp={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />
          }
        ></Route>
        <Route
          path={ROUTES.FEATURE_ROUTES.DASHBOARD}
          element={
            <PrivateRoute>
              {isOnline ? (
                <Dashboard />
              ) : (
                <PageContainer>
                  <NoNetwork />
                </PageContainer>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.DASHBOARD_EDIT}
          element={
            <PrivateRoute>
              {isOnline ? (
                <DashboardEdit />
              ) : (
                <PageContainer>
                  <NoNetwork />
                </PageContainer>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.ADOPTION}
          element={
            <PrivateRoute>
              {isOnline ? (
                <LocationWrapper />
              ) : (
                <PageContainer>
                  <NoNetwork />
                </PageContainer>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.LOCATION}
          element={
            <PrivateRoute>
              {isOnline ? (
                <LocationDetails />
              ) : (
                <PageContainer>
                  <NoNetwork />
                </PageContainer>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.CHEM_SENSE_GRAPH}
          element={<ChemSenseGraphWebView />}
        ></Route>
        <Route
          path={ROUTES.FEATURE_ROUTES.PUMP_WEB_VIEW}
          element={<PumpWebView />}
        ></Route>
        <Route
          path={ROUTES.FEATURE_ROUTES.PUMP_POWER_WEB_VIEW}
          element={<PumpPowerWebView />}
        ></Route>
        <Route
          path={ROUTES.FEATURE_ROUTES.TOTAL_CYCLES_PER_VALVE_GRAPH_WITH_FILTER}
          element={<TotalCyclesGraphFilterWebView />}
        ></Route>
        <Route
          path={ROUTES.FEATURE_ROUTES.TOTAL_CYCLES_PER_VALVE_GRAPH}
          element={<TotalCyclesGraphWebView />}
        ></Route>
        <Route
          path={ROUTES.FEATURE_ROUTES.TOTAL_CYCLES_PER_VALVE_PANEL}
          element={<TotalCyclesPanel />}
        ></Route>
        <Route
          path={ROUTES.FEATURE_ROUTES.TOTAL_CYCLES_PER_VALVE}
          element={<TotalCycles />}
        ></Route>
        <Route
          path={ROUTES.FEATURE_ROUTES.WATER_SENSE}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <WaterSense /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.CHEM_SENSE}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <ChemSense /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.CHEM_SENSE_DETAIL}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <ChemSenseChemicalDetail /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.CHEM_SENSE_SETTINGS}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <ChemSenseChemicalSettings /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.AQUA_LAB}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <AquaLab /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.GATEWAY_STATS}
          element={
            <LoginWrapper
              childComp={
                <PrivateRoute>
                  {isOnline ? <GatewayStats /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.BUSINESS_HOURS}
          element={
            <LoginWrapper
              childComp={
                <PrivateRoute>
                  {isOnline ? <BusinessHours /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.BUSINESS_HOURS}
          element={
            <LoginWrapper
              childComp={
                <PrivateRoute>
                  {isOnline ? <BusinessHours /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.USER_SETTINGS}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <UserSettings /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.VIEW_BUSINESS_HOURS}
          element={
            <LoginWrapper
              childComp={
                <PrivateRoute>
                  {isOnline ? <ViewBusinessHours /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.USER_MANGEMENT}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <UserMangement /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.WATER_COST}
          element={
            <LoginWrapper
              childComp={
                <PrivateRoute>
                  {isOnline ? <WaterCost /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.ADD_USER}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <AddUserComponent /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.EDIT_USER}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <EditUserComponent /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.USER_DETAILS}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <UserDetailsComponent /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.ADDWIDGET}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <AddWidget /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path={ROUTES.FEATURE_ROUTES.NOTIFICATION}
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <NotificationComp /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
        <Route
          path="/*"
          element={
            <PageContainer
              children={
                <PrivateRoute>
                  {isOnline ? <NotFound /> : <NoNetwork />}
                </PrivateRoute>
              }
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesManager;
