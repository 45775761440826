import React from 'react'
import "./not-found.scss";
import { NotFoundImg } from "src/utils/common/constants";



const NotFound = () => {
  return (
    <div className='not_found_container'>
      <div className='not_found_wrap'>
        <img src={NotFoundImg} alt="No Network" />
        <h3>Page Not Found</h3>
        <p>
          The Page your are looking for doesn’t exist or an other error occured.
        </p>
      </div>
    </div>
  )
}

export default NotFound