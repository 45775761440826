import React from "react";
import { Box, Button, Link } from "@mui/material";
import { DeletePop ,PopupLogout } from "src/utils/common/constants";
import "../popup.scss";
type props = {
  handleUserLogout: (value: boolean) => void
}
const Logout: React.FC<props> = ({ handleUserLogout }) => {
  return (
    <>

    <div className="logoutPopupWrapper">
    <img src={PopupLogout}/>
      <div className="popupDescription">
        <h1 className="title">
        Are you sure?
        </h1>
        <h2 className="desc">
        This will result in logging you out of the application. <span className="hint">
        Would you still like to continue?
        </span>
        </h2>
      </div>
      <div className="btnWrap">
      <Button className="bbtn white" variant="outlined" onClick={() => { handleUserLogout(false) }}>No</Button>
          <Button className="bbtn" variant="contained" onClick={() => { handleUserLogout(true) }}>
            Yes, Logout
          </Button>
      </div>
    </div>
     
    </>
  );
};

export default Logout;
