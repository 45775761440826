import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataApi } from "../../utils/common/constants";

/**
 * Fetches the ChemSense overview data from the backend API.
 *
 * @param {object} params - The parameters for the API request.
 * @param {string} params.gatewayId - The ID of the gateway to fetch data for.
 * @param {string} params.start - The start timestamp for the data range.
 * @param {string} params.end - The end timestamp for the data range.
 * @returns {Promise<object[]>} - The fetched ChemSense overview data.
 */
export const chemSenseOverview = createAsyncThunk(
    "chem-sense/overview",
    async (
        { end, gatewayId, start }: any,
        { fulfillWithValue, rejectWithValue }
    ) => {
        try {
            const res: any = await getDataApi({
                path: `alfred/chem-sense?gatewayId=${gatewayId}&end=${end}&start=${start}`,
                data: {},
            });
            if (res && res.containers) {
                return fulfillWithValue(res.containers)
            } else {
                return fulfillWithValue([]);
            }

        } catch (error: any) {
            const message =
                error.response && error.response.data && error.response.data.message;
            return rejectWithValue(message);
        }
    }
);

