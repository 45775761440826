import { FC } from "react";

const UserSvg: FC<{}> = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5747_12637)">
        <path
          d="M8.34252 16.2213H1.71094V13.9154C3.74269 12.7913 6.0178 12.1717 8.34252 12.1094"
          stroke="#52616C"
          strokeWidth="1.5"
        />
        <path
          d="M10.4141 11.2812V16.6342L14.3516 19.1048L18.2891 16.6342V11.2812H10.4141Z"
          stroke="#00ACE5"
          strokeWidth="1.5"
        />
        <path
          d="M5.44141 5.14453C5.44141 6.07279 5.81258 6.96303 6.47328 7.61941C7.13397 8.27578 8.03007 8.64453 8.96443 8.64453C9.8988 8.64453 10.7949 8.27578 11.4556 7.61941C12.1163 6.96303 12.4875 6.07279 12.4875 5.14453C12.4875 4.21627 12.1163 3.32603 11.4556 2.66966C10.7949 2.01328 9.8988 1.64453 8.96443 1.64453C8.03007 1.64453 7.13397 2.01328 6.47328 2.66966C5.81258 3.32603 5.44141 4.21627 5.44141 5.14453Z"
          stroke="#52616C"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5747_12637">
          <rect
            width="19.8947"
            height="21"
            fill="white"
            transform="translate(0.0527344)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserSvg;
