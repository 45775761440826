import { Backdrop, Box } from "@mui/material";
import { LoaderImg, LoaderLogo } from "../../utils/common/constants/images";
import { useAppSelector } from "../../hooks/useAppSelector";
import "./loader.scss";

const Loader = () => {
  const { loading } = useAppSelector((state) => state.globalcontent);

  return (
    <Backdrop className="loaderContainer" open={loading}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box component="img" className="ldr_lg" src={LoaderLogo} alt="Loader" />
        <Box component="img" className="ldr" src={LoaderImg} alt="Loader" />
      </Box>
    </Backdrop>
  );
};

export default Loader;
