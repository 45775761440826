import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserDetail } from "../types/userDetail";
import { userDetail ,updateUserPhone,updateUserName ,updateSystemAlert, updateProfilePic} from "../actions/userDetail.action";
import { addAddress, getGatewayId } from "../actions/locationDetails.action";
export const userDetailInitialState: UserDetail = {
  userDetail: {
    created_at: "",
    email: "",
    email_verified: false,
    identities: [
      {
        user_id: "",
        provider: "",
        connection: "",
        isSocial: false,
      },
    ],
    name: "",
    nickname: "",
    picture: "",
    updated_at: "",
    user_id: "",
    last_password_reset: "",
    last_ip: "",
    last_login: "",
    logins_count: 0,
    system_alerts_block_list: null,
    business_id: "",
    business_name: "",
    role_type: "",
    locations: [],
    user_metadata: {
      phone_number: "",
    },
  },
  selectedLocation: {
    id: "",
    name: "",
    address: "",
    city: "",
    region: "",
    postal_code: 0,
    phone: 0,
    description: "",
    coordinates: {
      x: 0,
      y: 0,
    },
    timezone: "",
    created_at: "",
    field_trials: [],
    hours: [],

  water_cost_per_gallon:0
  },
  gatewayId: "",
  pending: false
};

export const UserDetailModelSlice = createSlice({
  name: "userInfo",
  initialState: userDetailInitialState,
  reducers: {
    reset() {
      return {
        ...userDetailInitialState,
      };
    },
    /**
     * Updates the `selectedLocation` state with the location object that matches the provided `id`.
     *
     * @param state - The current state of the `userDetail` slice.
     * @param action - An action object that contains the `id` of the location to update.
     */
    updateSelectedLocation(state, action: PayloadAction<string>) {
      const newLocation: any = state.userDetail.locations.find(
        (item: any) => item.id === action.payload
      );
      state.selectedLocation = newLocation?.id
        ? newLocation
        : state.selectedLocation;
    },
    /**
     * Updates the `hours` property of the location object with the matching `id` in the `userDetail.locations` array.
     *
     * @param state - The current state of the `userDetail` slice.
     * @param action - An action object that contains the `id` of the location to update and the `newHours` array.
     * @returns The updated state.
     */
    updateLocationHours(state, action: PayloadAction<any>) {
      // Find the location object with the matching ID
      const locationIndex = state.userDetail.locations.findIndex(
        (item: any) => item.id === action.payload.id
      );

      // If location is found
      if (locationIndex !== -1) {
        const updatedLocation = { ...state.userDetail.locations[locationIndex] };
        updatedLocation.hours = action.payload.newHours;
        state.userDetail.locations[locationIndex] = updatedLocation;
      }

      return state;



    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userDetail.pending, (state) => {
        state.pending = true;
      })
      .addCase(userDetail.fulfilled, (state, action: PayloadAction<any>) => {
        state.pending = false;
        const updatedUserDetail = removeEmptyLocations(action.payload);

        state.userDetail = updatedUserDetail;
      })
      .addCase(userDetail.rejected, (state, action: PayloadAction<any>) => {
        state.pending = false;
      })
      .addCase(getGatewayId.pending, (state) => {
        state.pending = true;
      })
      .addCase(getGatewayId.fulfilled, (state, action: PayloadAction<any>) => {
        state.pending = false;
        state.gatewayId = action.payload.iot_hub_device_id;
      })
      .addCase(getGatewayId.rejected, (state, action: PayloadAction<any>) => {
        state.pending = false;
      });

      builder
      .addCase(updateUserPhone.pending, (state) => {
        state.pending = true;
      })
      .addCase(updateUserPhone.fulfilled, (state, action: PayloadAction<any>) => {
        state.pending = false;
        state.userDetail.user_metadata.phone_number = action.payload.phone;
      })
      .addCase(updateUserPhone.rejected, (state, action: PayloadAction<any>) => {
        state.pending = false;
      });
      builder
      .addCase(updateUserName.pending, (state) => {
        state.pending = true;
      })
      .addCase(updateUserName.fulfilled, (state, action: PayloadAction<any>) => {
        state.pending = false;
        state.userDetail.name = action.payload.name;
      })
      .addCase(updateUserName.rejected, (state, action: PayloadAction<any>) => {
        state.pending = false;
      });
      builder
      .addCase(updateSystemAlert.pending, (state) => {
        state.pending = true;
      })
      .addCase(updateSystemAlert.fulfilled, (state, action: PayloadAction<any>) => {
        state.pending = false;
        state.userDetail.system_alerts_block_list = action.payload.system_alerts_block_list;
      })
      .addCase(updateSystemAlert.rejected, (state, action: PayloadAction<any>) => {
        state.pending = false;
      })
      .addCase(addAddress.pending, (state) => {
        state.pending = true;
      })
      .addCase(addAddress.fulfilled, (state, action: PayloadAction<any>) => {
        state.pending = false;
      })
      .addCase(addAddress.rejected, (state, action: PayloadAction<any>) => {
        state.pending = false;
      })
      builder
      .addCase(updateProfilePic.pending, (state) => {
        state.pending = true;
      })
      .addCase(updateProfilePic.fulfilled, (state, action: PayloadAction<any>) => {
        state.pending = false;
        state.userDetail.user_metadata.profile_picture = action.payload;
      })
      .addCase(updateProfilePic.rejected, (state, action: PayloadAction<any>) => {
        state.pending = false;
      });
  },
});

 const removeEmptyLocations = (payload: any) => {
  if(payload?.locations){
    const updatedLocations = payload?.locations.filter((location: any) => {
      return location.id !== null
    })
    payload.locations = updatedLocations;
  }
  return payload;
  
 }
export const { reset, updateSelectedLocation, updateLocationHours } = UserDetailModelSlice.actions;

export default UserDetailModelSlice.reducer;
