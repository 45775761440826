import React from "react";
import LogoWithCarousel from "../../components/logo-with-carousel";
import "./authContainer.scss";

interface Props {
  childComp: React.ReactNode;

}

const LoginWrapper = ({ childComp }: Props) => {

  return (
    <div className='onboarding_wrapper'>
      <div className="lt_auth_panel">
        <LogoWithCarousel />
      </div>
      <div className="rt_auth_panel">
        {childComp}
      </div>
    </div>

  );
};

export default LoginWrapper;
