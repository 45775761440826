import { useEffect } from "react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { resetPumpPanel } from "src/redux/features/aquaLab.slice";

const useAquaLabHelper = () => {
  const dispatch = useAppDispatch();

  const panelType: any = useAppSelector(
    (state) => state.aquaLab.selectedPumpPanel.type
  );

  useEffect(() => {
    dispatch(resetPumpPanel());
    // eslint-disable-next-line
  }, []);

  return {
    panelType,
  };
};

export default useAquaLabHelper;
