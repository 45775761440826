import React, { useRef, useState } from "react";
import { Box, Button, Link } from "@mui/material";
import { BackArrow, dollarCircle, dollar } from "../../utils/common/constants";
import { Formik } from "formik";

import "./selectLocation.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "src/hooks/useAppSelector";
import useLocationHelper from "./helper";


/**
 * The `WaterCost` component is responsible for rendering a form that allows the user to update the water cost per gallon for a selected location.
 *
 * The component uses the `Formik` library to manage the form state and validation. It retrieves the initial location data from the Redux store and populates the form fields accordingly.
 *
 * The `handleOnSubmit` function is called when the form is submitted. It creates a payload object with the updated water cost per gallon and calls the `addAddressFromPayload` function to update the location data.
 *
 * The component also includes a back arrow button that allows the user to navigate back to the previous page.
 */
const WaterCost = () => {
  const mapLocationRef = useRef({ lat: 0, lng: 0 }); // Initial location (San Francisco)

  const navigate = useNavigate();
  const locationNav = useLocation();
  const { addAddressFromPayload } = useLocationHelper();

  const [isDisabled, setDisabled] = useState(true);

  
  /**
   * Retrieves the initial location data from the Redux store based on the current location navigation state.
   *
   * The function first tries to find the location object in the `state.userInfo.userDetail.locations` array
   * that matches the `id` property of the current location navigation state. If a matching location is found,
   * it is returned as the `initialLocation` value.
   *
   * If an error occurs during the retrieval process, the function will return `undefined`.
   */
  let initialLocation: any = useAppSelector((state) => {
    try {
      return state.userInfo.userDetail.locations.find(
        (loc) => loc.id === locationNav.state.id
      );
    } catch (error: any) {}
  });

  const constValidate = Math.max(
    0,
    Number(initialLocation?.water_cost_per_gallon)
  );

  const initialValues = {
    currency_code: "USD",
    water_cost_per_gallon: constValidate,
  };

  
  /**
   * Prevents the user from entering certain characters (e, E, +, -) when typing in a form field.
   * This is a common technique to prevent users from entering invalid characters for numeric input fields.
   * @param e - The keyboard event object.
   */
  const handleKeyDown = (e: any) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  if (locationNav?.state?.location?.adoption_code) {
    initialLocation = { ...locationNav?.state?.location?.location };
  }
  mapLocationRef.current = {
    lat: initialLocation?.coordinates?.y,
    lng: initialLocation?.coordinates?.x,
  };

  
  /**
   * Handles the form submission for updating the water cost.
   *
   * This function is called when the user submits the water cost form. It first checks if the `water_cost_per_gallon` field has a valid numeric value. If so, it creates a payload object with the form values and the `id` and `display_name` properties from the `initialLocation` object. It then calls the `addAddressFromPayload` function to update the water cost.
   *
   * @param value - The form values submitted by the user.
   */
  const handleOnSubmit = (value: any) => {
    if (Number(value.water_cost_per_gallon)) {
      const payload = {
        id: initialLocation?.id,
        display_name: initialLocation?.name,
        ...value,
      };

      addAddressFromPayload(payload);
    }
  };

  return (
    <>
      <Box className="auth_internal_container waterCostContainer">
        <Link>
          <img
            style={{ cursor: "pointer" }}
            src={BackArrow}
            alt="gate-way-stats"
            onClick={() => navigate(-1)}
          />
        </Link>

        <>
          <Box className="auth_heading">
            <div className="hd">Water Cost</div>
            <div className="shd">Update water cost for selected site</div>
          </Box>
          <>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleOnSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                isSubmitting,
                dirty,
                isValid,
                setFieldValue,
              }) => {
                const handlePositiveChange = (e: any) => {
                  const { name, value } = e.target;
                  const regex = /^\d*(\.\d{0,8})?$/;
                  if (!regex.test(value)) {
                    setDisabled(false);
                  } else {
                    setDisabled(true);
                  }

                  if (value === "" || (!isNaN(value) && Number(value) >= 0)) {
                    handleChange(e);
                  }
                };

                return (
                  <form onSubmit={handleSubmit}>
                    <Box className="selectLocation_wrap">
                      <Box component="img" src={dollarCircle}></Box>
                      <input
                        value={values?.water_cost_per_gallon}
                        placeholder="Water Cost"
                        name="water_cost_per_gallon"
                        onChange={handlePositiveChange}
                        type="number"
                        id="outlined-adornment-phone"
                        onKeyDown={handleKeyDown}
                      />
                      <Box component="img" src={dollar}></Box>
                    </Box>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      className="bbtn"
                      disabled={!isDisabled}
                    >
                      {constValidate > 0 ? "Update" : "Save"}
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </>
        </>
      </Box>
    </>
  );
};

export default WaterCost;
