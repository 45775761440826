import moment from "moment";
import { mockData } from "../Modules/dashboard/widget-promises/mockWashData";
import { getDataApi } from "./common/constants";
import { getStartEndDate } from "./helper";
export const errorStatus = {
  inactive: "InactiveAccount",
  deleted: "Deleted",
  accountLocked: "AccountLocked",
  verificationFailed: "VERIFICATION_FAILED",
  error_not_verified: "EMAIL_NOT_VERIFIED",
  account_Locked: "ACCOUNT_LOCKED",
};

export const messages = {
  sinUpVerified: "Signup completed. Kindly login",
};
export const level = {
  low: 10,
  medium: 20,
  high: 100,
};

export const gallonsInMl = 3785.411;
export const selectedWashPackageCount = 2;

type MockDataKey = keyof typeof mockData;

/**
 * Calculates the total wash count for a given gateway ID and wash package IDs.
 *
 * @param gatewayId - The ID of the gateway to get the wash data for.
 * @param washPackageIds - An array of wash package IDs, or the string "all" to get the total for all wash packages.
 * @returns The total wash count for the specified gateway and wash packages.
 */
export const getTotalWashCount = (
  gatewayId: MockDataKey,
  washPackageIds: Array<string> | string
) => {
  const id: MockDataKey = gatewayId;
  if (washPackageIds === "all") {
    const selectedArray: any = mockData[id] || [];
    const count = selectedArray?.reduce(
      (acc: any, item: any) => acc + item.washCount,
      0
    );
    return count;
  }
  const array = mockData[id]?.filter((item: any) =>
    washPackageIds?.includes(item.id)
  );
  const count = array?.reduce((acc: any, item: any) => acc + item.washCount, 0);
  if (count) {
    return count;
  } else {
    return 0;
  }
};

/**
 * Determines the appropriate date format based on the difference between the start and end dates.
 *
 * @param start - The start date.
 * @param end - The end date.
 * @returns The appropriate date format string ('hour', 'day', or 'month') based on the difference between the start and end dates.
 */
const handleFormat = (start: any, end: any) => {
  if (differenceInDates(start, end) <= 1) return "hour";
  if (differenceInDates(start, end) > 1 && differenceInDates(start, end) < 30)
    return "day";
  return "month";
};

/**
 * Calculates the difference in days between two dates.
 *
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns The difference in days between the start and end dates. If the end date is today, the difference is returned without adding 1. Otherwise, the difference is incremented by 1.
 */
const differenceInDates = (startDate: any, endDate: any) => {
  const today = moment(); // Get current date
  let diff = moment(endDate).diff(startDate, "days");

  // Check if end date is today's date
  const isToday = moment(endDate).isSame(today, "day");

  // If end date is today's date, return difference without adding 1
  if (isToday) {
    return diff;
  } else {
    // Otherwise, add 1 to the difference
    return diff > 0 ? diff + 1 : 1;
  }
};

/**
 * Retrieves the summary of wash package sales for a given location and date range.
 *
 * @param startDate - The start date for the date range.
 * @param endDate - The end date for the date range.
 * @param selectedLocationId - The ID of the selected location.
 * @returns A promise that resolves to the wash package sales data for the given location and date range.
 */
export const getWashSummary = (
  startDate: any,
  endDate: any,
  selectedLocationId: any
) => {
  const dateFormat = handleFormat(startDate, endDate);
  return getDataApi({
    path: `alfred/back-office-wash-package-sales`,
    data: {
      start: startDate,
      end: endDate,
      lid: selectedLocationId,
      dateFormat: dateFormat,
    },
  });
};

/**
 * Retrieves the summary of wash package sales for a given location and date range.
 *
 * @param startDate - The start date for the date range.
 * @param endDate - The end date for the date range.
 * @param selectedLocationId - The ID of the selected location.
 * @param filter - The filter to apply to the data.
 * @param prevData - The previous data to use for the request.
 * @param updateWidgetApiData - A function to update the widget API data.
 * @param type - The type of data to retrieve.
 * @param gatewayId - The ID of the gateway.
 * @returns A promise that resolves to the wash package sales data for the given location and date range.
 */
export const getWidgetWashSummary = ({
  startDate,
  endDate,
  selectedLocationId,
  filter,
  prevData,
  updateWidgetApiData,
  type,
  gatewayId = "",
}: any) => {
  const dateFormat = handleFormat(startDate, endDate);
  return getWidgetData({
    startDate,
    endDate,
    filter,
    type,
    prevData,
    updateWidgetApiData,
    gatewayId,
    promiseConfig: {
      path: `alfred/back-office-wash-package-sales`,
      data: {
        start: startDate,
        end: endDate,
        lid: selectedLocationId,
        dateFormat: dateFormat,
      },
    },
  });
};

/**
 * Retrieves data for a widget using a promise-based API.
 *
 * @param startDate - The start date for the data range.
 * @param endDate - The end date for the data range.
 * @param filter - The filter to apply to the data.
 * @param type - The type of data to retrieve.
 * @param prevData - The previous data to use for the request.
 * @param promiseConfig - The configuration for the promise-based API.
 * @param updateWidgetApiData - A function to update the widget API data.
 * @param gatewayId - The ID of the gateway (optional).
 * @returns A promise that resolves to the requested data.
 */
export const getWidgetData = ({
  startDate,
  endDate,
  filter,
  type,
  prevData,
  promiseConfig,
  updateWidgetApiData,
  gatewayId = "",
}: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      let key: any = null;
      if (filter === "CUSTOM") {
        key = `${type}_${startDate}_${endDate}`;
      } else {
        const { startDate, endDate } = getStartEndDate(filter)
        key = `${type}_${filter}_${startDate}_${endDate}`;
      }
      if (
        prevData &&
        prevData[gatewayId] &&
        prevData[gatewayId][key]) {
        setTimeout(() => {
          resolve(prevData[gatewayId][key]);
        }, 400);
      } else {
        const res = await getDataApi(promiseConfig);
        let keyInitial = `${type}_${filter}`

        if (key) {
          updateWidgetApiData({ gatewayId: gatewayId, data: { [key]: res }, keyInitial });
        }
        resolve(res);
      }
    } catch (e) {
      reject();
    }
  });
};

/**
 * Calculates the total wash count for a given set of package IDs.
 *
 * @param totalSaleArray - An array of total sale data.
 * @param backOfficePackageIds - An array of back office package IDs.
 * @param packageIds - An array of package IDs, or the string "all" to include all packages.
 * @returns The total wash count for the specified packages.
 */
export const getTotalWashCount2 = ({
  totalSaleArray = [],
  backOfficePackageIds = [],
  packageIds = [],
}: any) => {
  let totalCount = 0;
  if (packageIds === "all") {
    totalCount = totalSaleArray?.reduce((acc: any, item: any) => {
      return (item?.totalCount || 0) + acc;
    }, 0);
    return totalCount;
  }
  const backOfficeIds = packageIds?.map((id: any) => {
    const selected =
      backOfficePackageIds?.find((item: any) => item.id === id)
        ?.back_office_wash_package_id || "";
    return selected;
  });
  if (totalSaleArray) {
    totalCount = backOfficeIds?.reduce((acc: any, id: any) => {
      const selected = totalSaleArray?.find((o: any) => o.id === id);
      if (selected) {
        return (selected?.totalCount || 0) + acc;
      } else {
        return acc;
      }
    }, 0);
  }
  return totalCount;
};

/**
 * Calculates the total wash count for a given set of package IDs.
 *
 * @param totalSaleArray - An array of total sale data.
 * @param backOfficePackageIds - An array of back office package IDs.
 * @param packageIds - An array of package IDs, or the string "all" to include all packages.
 * @returns The total wash count for the specified packages.
 */
export const getTotalWashCountWaterGraph = ({
  totalSaleArray = [],
  backOfficePackageIds = [],
  packageIds = [],
}: any) => {
  if (packageIds === "all") {
    const result = totalSaleArray.reduce((acc: any, { sales }: any) => {
      sales.forEach(({ time, count }: any) => {
        const existingObj = acc?.find((obj: any) => obj.time === time);
        if (existingObj) {
          existingObj.count += count;
        } else {
          acc.push({ time, count });
        }
      });
      return acc;
    }, []);
    return result;
  } else {
    const selectedId =
      backOfficePackageIds?.find((item: any) => item.id === packageIds)
        ?.back_office_wash_package_id || "";

    if (totalSaleArray) {
      const selectedItem = totalSaleArray?.find(
        (o: any) => o.id === selectedId
      );
      return selectedItem?.sales || [];
    }
    return [];
  }
};

/**
 * Calculates the total wash count for a given set of package IDs.
 *
 * @param totalSaleArray - An array of total sale data.
 * @param backOfficePackageIds - An array of back office package IDs.
 * @param packageIds - A string representing the package IDs, or the string "all" to include all packages.
 * @returns The total wash count for the specified packages.
 */
export const getTotalWashCountWaterKpi = ({
  totalSaleArray = [],
  backOfficePackageIds = [],
  packageIds = "",
}: any) => {
  let totalCount: any = 0;
  if (packageIds === "all") {
    totalCount = totalSaleArray?.reduce((acc: any, item: any) => {
      if (item?.totalCount) {
        return (item?.totalCount || 0) + acc;
      } else {
        return acc;
      }
    }, 0);
    return totalCount;
  } else {
    const selectedId =
      backOfficePackageIds?.find((item: any) => item.id === packageIds)
        ?.back_office_wash_package_id || "";

    if (totalSaleArray) {
      const selectedItem = totalSaleArray?.find(
        (o: any) => o.id === selectedId
      );
      return selectedItem?.totalCount || 0;
    }
  }
};

/**
 * Retrieves the selected wash package count for a given gateway ID and wash package ID.
 *
 * @param gatewayId - The ID of the gateway.
 * @param washPackageId - The ID of the wash package.
 * @returns The wash count for the specified wash package, or 0 if not found.
 */
export const getSelectedWashPackageCount = (
  gatewayId: MockDataKey,
  washPackageId: string
) => {
  const id: MockDataKey = gatewayId;
  const array = mockData[id] || [];
  const item = array?.find((item: any) => item.id === washPackageId);
  return item?.washCount || 0;
};
interface Option {
  label: string;
  value: string | number;
}
export const containerSizeOptions: Option[] = [
  { label: "Bullseye", value: "Bullseye" },
  { label: "15", value: 15 },
  { label: "30", value: 30 },
];

export interface WidgetStatus {
  [key: number]: {
    name: string;
    items: any[];
  };
}

/**
 * An array of assumed widgets to be displayed on the dashboard.
 * Each widget has an id, content type, column, and position.
 */
export const assumedWidgets = [
  { id: "1", content: "kpi", col: 1, position: 1 },
  { id: "2", content: "Graph", col: 2, position: 1 },
  { id: "3", content: "KPI", col: 3, position: 1 },
  { id: "4", content: "List", col: 1, position: 2 },
  { id: "5", content: "KPI", col: 2, position: 2 },
];

/**
 * An object that represents the status of various widgets on the dashboard.
 * Each key in the object represents a column on the dashboard, and the value
 * is an object with a `name` property and an `items` array that holds the
 * widgets in that column.
 */
export const widgetStatus: WidgetStatus = {
  1: {
    name: "column1",
    items: [],
  },
  2: {
    name: "column2",
    items: [],
  },
  3: {
    name: "column3",
    items: [],
  },
};
/**
 * An enumeration of the different time filter options that can be applied to data on the dashboard.
 * Each option represents a different time period that the data can be filtered by.
 */
export type TimeKey =
  | "TODAY"
  | "YESTERDAY"
  | "LAST7DAYS"
  | "LAST90DAYS"
  | "MONTH"
  | "ALLTIME";

/**
 * An enumeration of the different categories of widgets that can be displayed on the dashboard.
 * Each category represents a different type of data or functionality that the widgets can provide.
 */
export enum CategoryType {
  CHEM_SENSE = "CHEMSENSE",
  WATER_SENSE = "WATERSENSE",
  AQUA_LAB = "AQUALAB",
  WASH_DATA = "WASHDATA",
  AQUA_LAB_POWER_GRAPH = "AQUALAB_POWER_GRAPH",
  AQUA_LAB_PRESSURE_GRAPH = "AQUALAB_PRESSURE_GRAPH",
  AQUALAB_KPI = "AQUALAB_KPI"
}

/**
 * An object that defines the different time filter options that can be applied to data on the dashboard.
 * Each key in the object represents a different time period that the data can be filtered by, and the
 * corresponding value is a human-readable label for that time period.
 */
export let timeFilter = {
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  LAST7DAYS: "Last 7 days",
  LAST90DAYS: "Last 90 days",
  MONTH: "This Month",
  ALLTIME: "All Time",
};

/**
 * An enumeration of the different types of widgets that can be displayed on the dashboard.
 * Each type represents a different way of visualizing or presenting data.
 */
export enum TypeEnum {
  KPI = "KPI",
  GRAPH = "GRAPH",
  LIST = "LIST",
  TABLE = "TABLE",
  KPI_AQUA = "KPI_AQUA",
  GRAPH_AQUA = "GRAPH_AQUA",
}

/**
 * Represents a widget that can be displayed on the dashboard.
 * Each widget has an id, a category, a type, a title, and a list of steps.
 */
interface Widget {
  id: number;
  category: CategoryType;
  type: TypeEnum;
  title: string;
  steps: number[];
}

interface WidgetDictionary {
  [key: number]: Widget;
}

/**
 * An object that defines the different widgets that can be displayed on the dashboard.
 * Each widget has an id, a category, a type, a title, and a list of steps.
 */
export const widgetDictionary: WidgetDictionary = {
  1: {
    id: 1,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.KPI,
    title: "Total Water Usage",
    steps: [2],
  },
  2: {
    id: 2,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.KPI,
    title: "Av. Water Usage",
    steps: [2],
  },
  3: {
    id: 3,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.KPI,
    title: "Av. Water used per car",
    steps: [2],
  },
  4: {
    id: 4,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.KPI,
    title: "Av. Water used per wash packages",
    steps: [0, 2],
  },
  5: {
    id: 5,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.KPI,
    title: "Av. Water cost per day",
    steps: [2],
  },
  6: {
    id: 6,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.KPI,
    title: "Av. Water cost per car",
    steps: [2],
  },
  7: {
    id: 7,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.GRAPH,
    title: "Total Water Usage",
    steps: [2],
  },
  8: {
    id: 8,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.GRAPH,
    title: "Av. Water Usage",
    steps: [2],
  },
  9: {
    id: 9,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.GRAPH,
    title: "Av. Water used per car",
    steps: [2],
  },
  10: {
    id: 10,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.GRAPH,
    title: "Av. Water used per wash packages",
    steps: [0, 2],
  },
  11: {
    id: 11,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.GRAPH,
    title: "Av. Water cost per day",
    steps: [2],
  },
  12: {
    id: 12,
    category: CategoryType.WATER_SENSE,
    type: TypeEnum.GRAPH,
    title: "Av. Water cost per car",
    steps: [2],
  },
  13: {
    id: 13,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.KPI,
    steps: [1, 2],
    title: "Av. Individual Chemical amount applied per car",
  },
  14: {
    id: 14,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.KPI,
    steps: [0, 1, 2],
    title: "Av. Individual Chemical amount applied per wash package ",
  },
  15: {
    id: 15,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.KPI,
    steps: [1, 2],
    title: "Av. Individual Chemical cost per car",
  },
  16: {
    id: 16,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.KPI,
    steps: [0, 1, 2],
    title: "Av. Individual Chemical cost per wash package",
  },
  17: {
    id: 17,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.KPI,
    steps: [2],
    title: "Av. Total Chemical cost per car",
  },
  18: {
    id: 18,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.KPI,
    steps: [0, 2],
    title: "Av. Total chemical cost per wash package",
  },
  19: {
    id: 19,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.GRAPH,
    steps: [1, 2],
    title: "Av. Individual chemical usage",
  },
  20: {
    id: 20,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.GRAPH,
    steps: [1, 2],
    title: "Av. Individual Chemical amount applied per car",
  },
  21: {
    id: 21,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.GRAPH,
    steps: [0, 1, 2],
    title: "Av. Individual Chemical amount applied per wash package ",
  },
  22: {
    id: 22,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.GRAPH,
    steps: [1, 2],
    title: "Av. Individual Chemical cost per car",
  },
  23: {
    id: 23,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.GRAPH,
    steps: [0, 1, 2],
    title: "Av. Individual Chemical cost per wash package",
  },
  24: {
    id: 24,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.GRAPH,
    steps: [0, 2],
    title: "Av. Total chemical cost per wash package",
  },
  25: {
    id: 25,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.GRAPH,
    steps: [2],
    title: "Av. Total Chemical cost per car",
  },
  26: {
    id: 26,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.LIST,
    steps: [1], // Need to show All chemcials
    title: "Chemical Listing according to level",
  },
  27: {
    id: 27,
    category: CategoryType.CHEM_SENSE,
    type: TypeEnum.TABLE,
    steps: [1], // Need to show All chemcials
    title: "Chemical Overview",
  },
  28: {
    id: 28,
    category: CategoryType.AQUALAB_KPI,
    type: TypeEnum.KPI_AQUA,
    steps: [2],
    title: "Total run time",
  },
  29: {
    id: 29,
    category: CategoryType.AQUALAB_KPI,
    type: TypeEnum.KPI_AQUA,
    steps: [2],
    title: "Total power usage",
  },
  30: {
    id: 30,
    category: CategoryType.AQUALAB_KPI,
    type: TypeEnum.KPI_AQUA,
    steps: [2],
    title: "Total starts",
  },
  31: {
    id: 31,
    category: CategoryType.AQUA_LAB,
    type: TypeEnum.GRAPH_AQUA,
    title: "Total Cycles per valve",
    steps: [3, 2],
  },
  33: {
    id: 33,
    category: CategoryType.AQUA_LAB_POWER_GRAPH,
    type: TypeEnum.GRAPH_AQUA,
    title: "Av. power usage",
    steps: [2],
  },
  34: {
    id: 34,
    category: CategoryType.AQUA_LAB_PRESSURE_GRAPH,
    type: TypeEnum.GRAPH_AQUA,
    title: "Outlet pressure",
    steps: [2],
  },
  35: {
    id: 35,
    category: CategoryType.WASH_DATA,
    type: TypeEnum.KPI,
    title: "Total wash count",
    steps: [2],
  },
  36: {
    id: 36,
    category: CategoryType.WASH_DATA,
    type: TypeEnum.KPI,
    title: "Total count of each wash package",
    steps: [0, 2],
  },
};
