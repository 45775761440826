import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getAlaCarte,
  getAllChemicals,
  getChemSense,
} from "../actions/get.chem-sense";
import { ChemSenseState } from "../types/chemSense.type";

const initialState: ChemSenseState = {
  chemSense: [],
  allAlacarte: [],
  error: "",
  allChemicalListing: [],
};

const chemSenseSlice = createSlice({
  name: "chemSense",
  initialState,
  reducers: {
    setAllAlaCarte: (
      state: ChemSenseState,
      action: PayloadAction<Array<any>>
    ) => {
      state.allAlacarte = action.payload;
    },
    setAllChemicals: (
      state: ChemSenseState,
      action: PayloadAction<Array<any>>
    ) => {
      state.allChemicalListing = action.payload.filter(
        (i: any) => i.id !== null
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getChemSense.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.chemSense = action.payload;
        return state;
      }
    );
    builder
      .addCase(
        getAllChemicals.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.allChemicalListing = action.payload.filter(
            (i: any) => i.id !== null
          );
        }
      )
      .addCase(
        getAllChemicals.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
        }
      );
    builder
      .addCase(getAlaCarte.fulfilled, (state, action: PayloadAction<any>) => {
        state.allAlacarte = action.payload;
      })
      .addCase(getAlaCarte.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      });
  },
});

export const { setAllAlaCarte, setAllChemicals } = chemSenseSlice.actions;

export default chemSenseSlice.reducer;
