import * as Yup from "yup";
import { mobileRegex, phoneError } from "src/utils/common/constants/validation";

export const AccountSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Name must be at least 2 characters") 
    .max(25, "Name must be at most 25 characters")
    .matches(
      /^(?!.*\.\.)[A-Za-z]+(?:\.?[A-Za-z ]+){0,24}$/,
      "Invalid name format"
    )
    .required("Name is required"),
  phone: Yup.string().required("Please enter a valid phone number").matches(mobileRegex, phoneError),
});

export type AccountData = Yup.InferType<typeof AccountSchema>;
