import { Box } from "@mui/material";
import PanelSelection from "./panel-selection";
import useAquaLabHelper from "./helper";
import PumpDetail from "./pump-detail";
import PanelDetail from "./panel-detail";
import "./aqua-lab.scss";

export default function AquaLab() {
  const {  panelType } = useAquaLabHelper();

  
  return (
    <Box className="aqua_lab_container">
      <Box className="aqua_lab_inner_container">
        <PanelSelection />
        {panelType === "pump" ? (
          <PumpDetail />
        ) : (
          <PanelDetail />
        )}
      </Box>
    </Box>
  );
}
