import { $axios } from "./common/api/axios.instance";
import { jwtDecode } from "jwt-decode";
import authHelper from "./auth";
/**
 * set access token in axios instance header
 */
export const setAuthorizationToken = (token?: string) => {
  if (token) {
    $axios.defaults.headers.common["access-token"] = token;
  } else {
    $axios.defaults.headers.common["access-token"] = "";
    delete $axios.defaults.headers.common["access-token"];
  }
};

/**
 * Removes the user's session by clearing the local storage and logging out the user from the authentication provider.
 * This function should be called when the user wants to log out of the application.
 */
/**
 * Removes the user's session by clearing the local storage and logging out the user from the authentication provider.
 * This function should be called when the user wants to log out of the application.
 */
export const removeSession = () => {
  
  localStorage.clear();

  const params = {
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI,
  }
  authHelper.logout(params)
};

/**
 * get user access token form localStorage
 */

export const getAccessToken = () => {
  let token: any = localStorage.getItem("idToken");
  if (!token) {
    return false
  }
  const decoded: any = jwtDecode(token);
  let currentDate = new Date();
  // check for jwt expiry
  if (decoded?.exp * 1000 < currentDate.getTime()) {
    return false
  } else {
    return true;
  }

};
/**
 * Sets the access token in the Axios instance's headers, enabling authenticated requests.
 * @param accessToken - The access token to be set in the Axios instance headers.
 * @returns The provided access token.
 */
export const setAccessToken = (accessToken: any) => {
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }
  return accessToken;
};

/**
 * get user id form localStorage
 */
export const getUserId = () => {
  let token: any = localStorage.getItem("idToken");

  if (token) {
    const decoded: any = jwtDecode(token);
    let user_id = decoded.sub;
    user_id = user_id.split("|")[1]
    return user_id 
  }
  return ""
  
}

/**
 * get mfaToken form localStorage
 */
export const getMFAtoken = () => {
  return localStorage.getItem("mfaToken");
};
