import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PumpAndPanel, PumpsAndPanelState } from "../types/pumpsAndPanels.type";

const initialState: PumpsAndPanelState = {
  pumpsAndPanels: [],
};

const PumpsAndPanelsSlice = createSlice({
  name: "pumpsAndPanels",
  initialState,
  reducers: {
    /**
     * Sets the pumps and panels in the state.
     *
     * @param state - The current state of the pumps and panels.
     * @param action - The action containing the new array of pumps and panels.
     */
    setPumpsAndPanels: (
      state: PumpsAndPanelState,
      action: PayloadAction<Array<PumpAndPanel>>
    ) => {
      state.pumpsAndPanels = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPumpsAndPanels } = PumpsAndPanelsSlice.actions;

export default PumpsAndPanelsSlice.reducer;
