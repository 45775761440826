import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { pumpSummary } from "src/redux/actions/pumpsAndPanels.action";
import { PumpSummary } from "src/redux/types/aquaLab.type";
import { showAlert } from "src/utils";

const initialDateValues = {
  startDate: moment().subtract(15, "days").startOf("day").toDate(),
  endDate: moment().subtract(1, "days").endOf("day").toDate(),
  key: "selection",
};

const initialParamsValue = {
  totalRunTime: "",
  avgRunTime: "",
  totalPowerTime: 0,
  avgPowerTime: 0,
  totalStarts: 0,
  avgStarts: 0,
};

const getDays = (start: any, end: any) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const startMilliseconds = startDate.getTime();
  const endMilliseconds = endDate.getTime();
  const differenceMilliseconds = endMilliseconds - startMilliseconds;
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const differenceInDays = differenceMilliseconds / millisecondsInADay;
  return differenceInDays;
};

/**
 * The `usePumpDetailHelper` function in TypeScript manages state and logic related to pump summary
 * data, date filtering, and calculations for a pump detail panel.
 * @returns The `usePumpDetailHelper` function returns an object containing various functions and state
 * variables related to managing pump summary data and date filtering.
 */
const usePumpDetailHelper = () => {
  const [pumpSummaryData, setPumpSummaryData] = useState<any>([]);
  const [filteredSummaryData, setFilteredSummaryData] = useState<any>([]);
  const [selectionRange, setSelectionRange] = useState(initialDateValues);
  const [params, setParams] = useState(initialParamsValue);
  const dispatch = useAppDispatch();
  const [activeButton, setActiveButton] = useState<string>("");
  const [showPicker, setShowPicker] = useState(false);
  const [dateSelected, setDatesSelected] = useState(1);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [start_date, setStartdate] = useState() as any;
  const [end_date, setEnd_date] = useState() as any;
  const device_id = useSelector((state: any) => state.userInfo.gatewayId);

  const selectedPumpPanel = useAppSelector(
    (state) => state.aquaLab.selectedPumpPanel
  );
  useEffect(() => {
    setActiveButton("");
    setIsDateSelected(true);
    setShowPicker(false);
    getPumpSummary(
      initialDateValues.startDate.toISOString(),
      initialDateValues.endDate.toISOString()
    );
    setSelectionRange(initialDateValues);
    // eslint-disable-next-line
  }, [selectedPumpPanel.id]);

  const calculateData = () => {
    let totalStarts = 0;
    let start_Date = moment(start_date);
    let end_Date = moment(end_date);
    const startUnixTimeStamp = moment(start_date).startOf("days").unix();
    const startOfyear = moment().startOf("year");
    let totalDays = end_Date.diff(start_Date, "days");
    if (start_Date?.toISOString() !== startOfyear?.toISOString()) {
      totalDays += 1;
    }
    const filteredDataAsPerDate = pumpSummaryData.filter(
      (item: PumpSummary) =>
        item.unixtime >= startUnixTimeStamp &&
        selectedPumpPanel.modbus_id === item.modbusId
    );

    const dataLength = filteredDataAsPerDate.length - 1;

    const filterDataDate = filteredDataAsPerDate.length
      ? filteredDataAsPerDate[0]?.runTime -
        filteredDataAsPerDate[dataLength]?.runTime
      : 0;

    /*Total run time*/
    const days = filterDataDate ? Math.floor(filterDataDate / 24) : 0;
    const remainingHours = filterDataDate ? Math.floor(filterDataDate % 24) : 0;
    const remianingMinutes = filterDataDate
      ? Math.round(remainingHours / 60)
      : 0;

    totalStarts = filteredDataAsPerDate.length
      ? filteredDataAsPerDate[0]?.starts -
        filteredDataAsPerDate[dataLength]?.starts
      : 0;
    const totalPower = filteredDataAsPerDate.length
      ? filteredDataAsPerDate[0]?.totalKWH -
        filteredDataAsPerDate[dataLength]?.totalKWH
      : 0;

    /*Average RunTime */
    const avgDays = Math.floor(days / totalDays) ?? 0;

    const averageValueRunTime = filterDataDate / totalDays ?? 0;

    const avgRemainingHours = Math.floor(averageValueRunTime) ?? 0;

    // Separate the whole number part and the decimal part
    const decimalPart = averageValueRunTime - avgRemainingHours;

    // Convert decimal part into minutes
    const minutes = Math.round(decimalPart * 60);

    const averageMinute = minutes ?? 0;

    const localParams = {
      totalRunTime:
        `<span>${days}</span> d <span>${remainingHours}</span> h <span>${remianingMinutes}</span> m` ??
        "-",
      avgRunTime:
        `<span >${avgDays}</span> d <span>${avgRemainingHours}</span> h <span>${averageMinute}</span> m` ??
        "-",
      totalPowerTime: totalPower ?? 0,
      avgPowerTime: totalPower
        ? Number((totalPower / totalDays).toFixed(2))
        : 0,
      totalStarts: totalStarts ?? 0,
      avgStarts: totalStarts ? Number((totalStarts / totalDays).toFixed(2)) : 0,
    };
    setFilteredSummaryData(filteredDataAsPerDate);
    setParams(localParams);
  };

  const getPumpSummary = (startDate: string, endDate: string) => {
    setShowPicker(false);
    let bucketMinutes = 60;
    const days = getDays(startDate, endDate);

    if (days && days <= 1) {
      bucketMinutes = 60;
    } else if (days && days > 1 && days <= 4) {
      bucketMinutes = 240;
    } else if (days && days > 4 && days <= 7) {
      bucketMinutes = 420;
    } else if (days && days > 3 && days <= 31) {
      bucketMinutes = 1440;
    } else if (days && days > 31 && days <= 92) {
      bucketMinutes = 5040;
    } else if (days && days > 92 && days <= 731) {
      bucketMinutes = 10080;
    }
    setStartdate(moment(startDate));
    setEnd_date(moment(endDate));
    const payload = {
      deviceId: device_id,
      start: startDate,
      end: endDate,
      bucketMinutes: bucketMinutes,
    };
    if (device_id) {
      dispatch(pumpSummary(payload)).then((res: any) => {
        setPumpSummaryData(res.payload);
      });
    }
  };

  const handleTimeChange = (value: any, type: string) => {
    if (value) {
      const updatedRange = { ...selectionRange };
      type === "startDate"
        ? (updatedRange.startDate = moment(value.$d).startOf("day").toDate())
        : (updatedRange.endDate = moment(value.$d).endOf("day").toDate());
      setSelectionRange(updatedRange);
    }
  };

  const handleRageFilter = (value: string) => {
    try {
      dateRangeFilter(value);
      setActiveButton(value);
      setDatesSelected(0);
      setIsDateSelected(false);
      setShowPicker(false);
    } catch (err: any) {
      showAlert(2, err.description);
    }
  };

  const handleEventDate = () => {
    setShowPicker(!showPicker);
  };

  const handleReset = () => {
    setIsDateSelected(true);
    setActiveButton("");
    setShowPicker(true);
  };

  const dateRangeFilter = (value: string) => {
    let startDate: string;
    let endDate: string;

    switch (value) {
      case "today":
        startDate = moment().startOf("day").toISOString();
        endDate = moment().toISOString();

        break;
      case "yesterday":
        startDate = moment().subtract(1, "day").startOf("day").toISOString();
        endDate = moment().subtract(1, "day").endOf("day").toISOString();
        break;
      case "7days":
        startDate = moment().subtract(7, "days").startOf("day").toISOString();
        endDate = moment().subtract(1, "day").endOf("day").toISOString();

        break;
      case "30days":
        endDate = moment().subtract(1, "day").endOf("day").toISOString();
        startDate = moment().subtract(30, "days").startOf("day").toISOString();

        break;
      case "allTime":
        startDate = moment().startOf("year").toISOString();
        endDate = moment().toISOString();
        break;
      default:
        return;
    }

    getPumpSummary(startDate, endDate);
  };

  const handleShowPicker = (startDate: any, endDate: any) => {
    setActiveButton("");
    setShowPicker(!showPicker);
    getPumpSummary(startDate, endDate);
  };

  const handleDates = (ranges: any) => {
    setIsDateSelected(true);
    setSelectionRange({
      ...ranges.selection,
      endDate: moment(ranges.selection.endDate).endOf("day").toDate(),
    });
    setDatesSelected(dateSelected + 1);
  };

  const setValue = () => {};

  useEffect(() => {
    calculateData();
    // eslint-disable-next-line
  }, [selectionRange, pumpSummaryData, selectedPumpPanel]);

  return {
    dispatch,
    initialDateValues,
    selectedPumpPanel,
    getPumpSummary,
    filteredSummaryData,
    params,
    selectionRange,
    setSelectionRange,
    handleRageFilter,
    activeButton,
    showPicker,
    handleShowPicker,
    dateSelected,
    isDateSelected,
    handleDates,
    handleEventDate,
    setValue,
    handleTimeChange,
    handleReset,
    setShowPicker,
  };
};

export default usePumpDetailHelper;
