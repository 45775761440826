import { Box, Container } from "@mui/material";
import Header from "../../components/header";
import "./headerWrapper.scss";

interface Props {
  children: React.ReactNode;
}

export default function PageContainer({ children }: Props) {
  return (
    <Box className="wrapper">
      <Header />
      <Container maxWidth="xl" className="insider_container">
        {children}
      </Container>
    </Box>
  );
}
