import { FC } from "react";

const MenuSvg: FC<{}> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill="#52616C" />
      <circle cx="2" cy="9" r="2" fill="#52616C" />
      <circle cx="2" cy="16" r="2" fill="#52616C" />
      <circle cx="9" cy="2" r="2" fill="#52616C" />
      <circle cx="9" cy="9" r="2" fill="#52616C" />
      <circle cx="9" cy="16" r="2" fill="#52616C" />
      <circle cx="16" cy="2" r="2" fill="#52616C" />
      <circle cx="16" cy="9" r="2" fill="#52616C" />
      <circle cx="16" cy="16" r="2" fill="#52616C" />
    </svg>
  );
};

export default MenuSvg;
