import * as Plot from "@observablehq/plot";
import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { formatNumberWithCommas } from "src/utils";

const getYFormat = (value) => {
if(value === 0 ){
    return  "0"
}
if(value > 10000){
    return `${Math.floor(value / 1000)} K`
}else {
    return value
}
}

const hover = (tip, pos, text) => {
    const side_padding = 10;
    const vertical_padding = 15;
    const vertical_offset = 15;
  
    // Empty it out
    tip.selectAll("*").remove();
  
    // Append the text
    tip
      .style("text-anchor", "middle")
      .style("pointer-events", "none")
      .attr("transform", `translate(${pos[0]}, ${pos[1] + 7})`)
      .selectAll("text")
      .data(text)
      .join("text")
      .style("dominant-baseline", "ideographic")
      .text((d) => d)
      .attr("y", (d, i) => (i - (text.length - 1)) * 15 - vertical_offset)
      .style("font-weight", (d, i) => (i === 0 ? "bold" : "normal"));
  
    const bbox = tip.node().getBBox();
  
    // Add a rectangle (as background)
    tip
      .append("rect")
      .attr("y", bbox.y - vertical_padding)
      .attr("x", bbox.x - side_padding)
      .attr("width", bbox.width + side_padding * 2)
      .attr("height", bbox.height + vertical_padding * 2)
      .attr("rx", 7) // Rounded corner x-radius
      .attr("ry", 7) // Rounded corner y-radius
      .style("fill", "white")
      .style("stroke", "#d3d3d3")
      .lower();
  };

  const addTooltips = (chart, styles) => {
    const stroke_styles = {};
    const fill_styles = { fill: "blue", opacity: 0.5 };
  
    // Workaround if it's in a figure
    const type = d3.select(chart).node().tagName;
    let wrapper =
      type === "FIGURE" ? d3.select(chart).select("svg") : d3.select(chart);
  
    // Workaround if there's a legend....
    const svgs = d3.select(chart).selectAll("svg");
    if (svgs.size() > 1) wrapper = d3.select([...svgs].pop());
    wrapper.style("overflow", "visible"); // to avoid clipping at the edges
  
    // Set pointer events to visibleStroke if the fill is none (e.g., if its a line)
    wrapper.selectAll("path").each(function (data, index, nodes) {
      // For line charts, set the pointer events to be visible stroke
      if (
        d3.select(this).attr("fill") === null ||
        d3.select(this).attr("fill") === "none"
      ) {
        d3.select(this).style("pointer-events", "visibleStroke");
        if (styles === undefined) styles = stroke_styles;
      }
    });
  
    if (styles === undefined) styles = fill_styles;
  
    const tip = wrapper
      .selectAll(".hover")
      .data([1])
      .join("g")
      .attr("class", "hover")
      .style("pointer-events", "none")
      .style("text-anchor", "middle");
  
    const id_generator = () => {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return "a" + S4() + S4();
    };
    // Add a unique id to the chart for styling
    const id = id_generator();
  
    // Add the event listeners
    d3.select(chart).classed(id, true); // using a class selector so that it doesn't overwrite the ID
    wrapper.selectAll("title").each(function () {
      // Get the text out of the title, set it as an attribute on the parent, and remove it
      const title = d3.select(this); // title element that we want to remove
      const parent = d3.select(this.parentNode); // visual mark on the screen
      const t = title.text();
      if (t) {
        parent.attr("__title", t).classed("has-title", true);
        title.remove();
      }
      // Mouse events
      parent
        .on("pointerenter pointermove", function (event) {
          const text = d3.select(this).attr("__title");
          const pointer = d3.pointer(event, wrapper.node());
          if (text) tip.call(hover, pointer, text.split("\n"));
          else tip.selectAll("*").remove();
  
          // Raise it
          d3.select(this).raise();
          // Keep within the parent horizontally
          const tipSize = tip.node().getBBox();
          if (pointer[0] + tipSize.x < 0)
            tip.attr(
              "transform",
              `translate(${tipSize.width / 2}, ${pointer[1] + 7})`
            );
          else if (pointer[0] + tipSize.width / 2 > wrapper.attr("width"))
            tip.attr(
              "transform",
              `translate(${wrapper.attr("width") - tipSize.width / 2}, ${
                pointer[1] + 7
              })`
            );
        })
        .on("pointerout", function (event) {
          tip.selectAll("*").remove();
          // Lower it!
          d3.select(this).lower();
        });
    });
  
    // Remove the tip if you tap on the wrapper (for mobile)
    wrapper.on("touchstart", () => tip.selectAll("*").remove());
  
    return chart;
  };

const valveObject = {
v1: "Val 1",
v2: "Val 2",
v3: "Val 3",
v4: "Val 4",
v5: "Val 5",
v6: "Val 6",
v7: "Val 7",
}

export default function MyPlot({data}) {
    const ref = useRef();
    useEffect(() => {
        const colors = ['#b278a2', '#4c78a8', '#55a24a', '#eeca3b', '#f58518', '#e55756', '#9d755e'];
        if(data){
            const newData = {...data}
            delete newData.modbusId;
            const dataArray = Object.keys(newData).map((key)=> ({ x: valveObject[key], y: newData[key]}))
            const keys = Object.keys(newData).map((item)=> valveObject[item])
            const values = Object.values(newData)
            const constantYValue = 80000
            const minY = Math.min(...values, constantYValue);
            const sum = Object.values(newData).reduce((acc, curr) => acc + curr, 0);
            const average = sum/Object.values(newData).length

    const maxY = Math.max(...values, constantYValue);

            const barChart = Plot.plot({
                marks: [
                    // Plot.barY(values, { x: keys, fill: colors, strokeWidth: 2,rx: 30}),
                    Plot.lineY([constantYValue, constantYValue], {
                        stroke: "red",
                        strokeWidth: 2,
                        strokeDasharray: "8 4" // Increase gap between dashes
                      }),
                      Plot.barY(dataArray, { x: "x", y: "y", fill: colors, strokeWidth: 1,rx: 15, width: 0.2, insetLeft: 50, insetRight: 50, title: (d) => {
                        const value = formatNumberWithCommas(String(d?.y))
                        return `${d.x} \n ${value}`;
                      },}),
                      Plot.lineY([{x: "Val 1", y: 300000}, {x: "Val 7", y: 300000}], {x: "x", y: "y", stroke: "red", strokeWidth: 2, strokeDasharray: "6 10"}),
                ],
                width: 1000,
                height: 500,
                labelArrow: "none",
                label: "",
                bandwidth: 0.5 ,
                x: {
                    bandwidth: 0.5 ,
                    domain: keys,
                     tickFormat: (value) => {
                        if(value === "start") {
                            return ""
                        }else {
                            return value
                        }
                        
                     },
                },
                y: {
                    ticks: 6,
                    domain: [0, Math.max(...values) > 300000 ? Math.max(...values) : 300000],
                    grid: true,
                     tickFormat: getYFormat,
                },
            });
            ref.current.append(addTooltips(barChart));
            return () => barChart.remove();
        }
    }, [data]);

    return (
        <div id="bar-graph"  ref={ref}></div>
    );
}
