import { COLORS } from "../../utils/common/constants";

const styles = {
  btnPrimary: {
    textTransform: "capitalize",
    cursor: "pointer",
    borderRadius: "0",
    background: `linear-gradient(272.22deg, ${COLORS.black} 0%, ${COLORS.button} 100%)`,
    letterSpacing: "0.59px",
    boxShadow: `0px 10px 14px rgba(0, 0, 0, 0.15)`,
    "&.MuiButtonBase-root": {
      color: `${COLORS.white} !important`,
      "&:hover": {
        background: `linear-gradient(272.22deg, ${COLORS.black} 0%, ${COLORS.button} 100%)`,
        color: COLORS.white,
      },
      "&:disabled": {
        background: "#F3F3F3",
        backdropFilter: "blur(20px)",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.15)",
      },
    },
  },
  btnNormal: {
    textTransform: "capitalize",
    cursor: "pointer",
    borderRadius: "0",
  },
};
export default styles;
