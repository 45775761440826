import { Box, ClickAwayListener, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BlueBackgoundDrop,
  CloseImg,
  PurpleDrop,
} from "src/utils/common/constants";
import usePanelDetailHelper from "./helper";
import "./panel-detail.scss";
import CyclesData from "../../../components/graph/totalCyclesGraphs";
import Bar from "../../../components/graph/lifeTimeCyclesGraph";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "react-date-range";
import ContainedButton from "src/components/contained-button";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useAppSelector } from "src/hooks/useAppSelector";
import Loader from "./loader";
import { NoData } from "src/utils/common/constants";

export default function PanelDetail() {
  const {
    selectedPumpPanel,
    latestDate,
    filteredData,
    filteredTotalValueCycles,
    getPanelSummary,
    selectionRange,
    initialDateValues,
    showPicker,
    handleReset,
    handleEventDate,
    handleDates,
    handleTimeChange,
    setShowPicker,
  } = usePanelDetailHelper();

  const valueKeys = [
    "v1cycles",
    "v2cycles",
    "v3cycles",
    "v4cycles",
    "v5cycles",
    "v6cycles",
    "v7cycles",
  ];
  const maxCyclesValue = Math.max(
    ...filteredData?.map((item: any) =>
      Math.max(...valueKeys.map((key: any) => item[key] || 0))
    )
  );

  const { isPanelAreaGraphLoaded, isPanelAreaBarLoaded, isLocationCompLoaded } =
    useAppSelector((state) => state.globalcontent);
  const [isLoader, setLoader] = useState(true);
  const [isError, setError] = useState(false);

  useEffect(() => {
    if (
      isPanelAreaGraphLoaded &&
      isPanelAreaBarLoaded &&
      isLocationCompLoaded
    ) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [isPanelAreaGraphLoaded, isPanelAreaBarLoaded, isLocationCompLoaded]);

  /**
   * Handles errors that occur during the panel summary data update process.
   * If the error is "minTime" or "invalidDate", sets the `isError` state to true,
   * indicating that an error has occurred. Otherwise, sets `isError` to false.
   *
   * @param err - The error object or error message.
   */
  const handleError = (err: any) => {
    setError(err === "minTime" || err === "invalidDate" ? true : false);
  };

  /**
   * Updates the panel summary data based on the selected date range.
   * This function is called to fetch the latest panel summary data from the server
   * when the user changes the date range selection.
   */
  const updateSummaryData = () => {
    getPanelSummary(
      selectionRange.startDate.toISOString(),
      selectionRange.endDate.toISOString()
    );
  };

  const convertDecimalValues = (num: any) => {
    if(!num){
      return ""
    }
    let numStr = num.toString() || "";
    let decimalPos = numStr.indexOf('.');
    let decimalDigits = 0
    if (decimalPos === -1) {
      decimalDigits =  0;
    }
    decimalDigits = numStr.length - decimalPos - 1;
    if(decimalDigits > 2){
      return num.toFixed(2)
    }else {
      return num
    }
  }

  return (
    <>
      <Loader isOpen={isLoader} />
      <Box className="panel_detail_container">
        <div className="panel_detail_heading">{selectedPumpPanel.name}</div>
        <div className="pump_detail_subheading top_sub_head">
          Select the pump/valve from left panel to view details here
        </div>
        <Box className="panel_detail_graph1">
          <div className="pump_detail_subheading">
            Lifetime Cycles per Valve
          </div>
          <Bar data={filteredTotalValueCycles} />
        </Box>
        <Box className="panel_detail_graph2">
          <div className="grpInline_wrap">
            <div className="pump_detail_subheading">Total Cycles per Valve</div>
            <Box className="filterDt_wp">
              <Box className={"active cal"}>
                <i onClick={handleReset}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.5 3H16.5V16.5H1.5V3Z" strokeWidth="1.6" />
                    <path d="M1.5 6H16.5" strokeWidth="1.6" />
                    <path d="M4.5 0.75V3" strokeWidth="1.6" />
                    <path d="M13.5 0.75V3" strokeWidth="1.6" />
                    <path d="M3.75 9.75H6" strokeWidth="1.6" />
                    <path d="M3.75 12.75H6" strokeWidth="1.6" />
                    <path d="M7.875 9.75H10.125" strokeWidth="1.6" />
                    <path d="M12 9.75H14.25" strokeWidth="1.6" />
                    <path d="M7.875 12.75H10.125" strokeWidth="1.6" />
                  </svg>
                </i>
              </Box>
              <Box className="filtInp">
                <input
                  type="text"
                  value={`${moment(selectionRange.startDate).format(
                    "D MMM, YYYY hh:mm A"
                  )} - ${moment(selectionRange.endDate).format(
                    "D MMM, YYYY hh:mm A"
                  )}`}
                  onClick={handleEventDate}
                  id="date-range-picker"
                  placeholder="Select Date Range"
                  onChange={() => {}}
                />
                <i className="cls" onClick={handleReset}>
                  <img src={CloseImg} alt="" />
                </i>
              </Box>
              {showPicker && (
                <ClickAwayListener onClickAway={() => setShowPicker(false)}>
                  <Box className="date-range-container">
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleDates}
                      maxDate={initialDateValues?.endDate}
                      months={1}
                      className={"date-picker"}
                    />
                    <Box sx={{ display: "flex" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="Start Time"
                            value={dayjs(selectionRange.startDate)}
                            onChange={(value) =>
                              handleTimeChange(value, "startDate")
                            }
                            timeSteps={{ minutes: 1 }}
                            onError={handleError}
                            inputRef={(inputRef) => {
                              if (inputRef) {
                                inputRef.addEventListener("keydown", (event) => {
                                  if (event.keyCode === 8) {
                                    event.preventDefault();
                                  }
                                });
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="End Time"
                            value={dayjs(selectionRange.endDate)}
                            onChange={(value) =>
                              handleTimeChange(value, "endDate")
                            }
                            timeSteps={{ minutes: 1 }}
                            minTime={dayjs(selectionRange.startDate)}
                            onError={handleError}
                            inputRef={(inputRef) => {
                              if (inputRef) {
                                inputRef.addEventListener("keydown", (event) => {
                                  if (event.keyCode === 8) {
                                    event.preventDefault();
                                  }
                                });
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                    <Box className="dataPik_bottom">
                      <ContainedButton
                        title="Set Dates"
                        className="set_rst"
                        onClick={updateSummaryData}
                        disabled={isError}
                      />
                    </Box>
                  </Box>
                </ClickAwayListener>
              )}
            </Box>
          </div>
          {maxCyclesValue <= 0 ? (
            <Box className="Nodata">
              <figure>
                <img src={NoData} alt="" />
              </figure>
              No Data Available
            </Box>
          ) : (
            <CyclesData
              data={filteredData}
              startDate={selectionRange.startDate}
              endDate={selectionRange.endDate}
            />
          )}
        </Box>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Box className="delBxc">
              <div className="tp">
                <img src={BlueBackgoundDrop} alt="BlueBackgroundDrop" />
                <p>Current Temperature</p>
              </div>
              {latestDate.tempF !== 0 ? (
                <p className="dl">
                  {latestDate.tempF && (
                    <span>
                      {`${convertDecimalValues(latestDate?.tempF)}`} <sup>o</sup>F
                    </span>
                  )}
                </p>
              ) : (
                <p className="dl">
                  <span>{"N/A"}</span>
                </p>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="delBxc">
              <div className="tp">
                <img src={PurpleDrop} alt="PurpleDrop" />
                <p>Current Humidity</p>
              </div>
              {latestDate.humidity !== 0 ? (
                <p className="dl">
                  {latestDate.humidity && (
                    <span>{`${convertDecimalValues(latestDate?.humidity)}`}</span>
                  )}{" "}
                  %
                </p>
              ) : (
                <p className="dl">
                  <span>{"N/A"}</span>
                </p>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
