import { useState } from "react";
import { removeSession } from "src/utils";
import { useSelector } from "react-redux";
import { UserDetail, User } from "src/redux/types/userDetail";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  updateUserName,
  updateUserPhone,
  updateSystemAlert,
  updateProfilePic,
} from "src/redux/actions/userDetail.action";

const useSettingHelper = () => {
  const userInfo: UserDetail = useSelector((state: any) => state.userInfo);
  const userDetails: User = userInfo.userDetail;

  const dispatch = useAppDispatch();

  const [isLogout, setIsLogout] = useState(false);

  const handleLogout = () => {
    setIsLogout(true);
  };

  /**
   * Handles the closing of the logout popup. If the `flag` parameter is `true`, it removes the user's session and sets the `isLogout` state to `false`. Otherwise, it just sets the `isLogout` state to `false`.
   * @param {boolean} flag - A boolean flag indicating whether the logout popup should be closed.
   */
  const handleLogoutPopUpClose = (flag: boolean) => {
    if (flag === true) {
      removeSession();
      setIsLogout(false);
    } else {
      setIsLogout(false);
    }
  };

  /**
   * The `handleChangePhoneNumber` function dispatches an action to update the user's phone number with
   * the provided value.
   * @param {string} value - The `value` parameter in the `handleChangePhoneNumber` function is a
   * string representing the new phone number that the user wants to update in their user profile.
   * @returns The `handleChangePhoneNumber` function is returning the result of dispatching an action
   * to update the user's phone number. The action being dispatched is `updateUserPhone` with the
   * `user_id` extracted from `userDetails` and the `phone` value passed to the function.
   */
  const handleChangePhoneNumber = (value: string) => {
    return dispatch(
      updateUserPhone({
        user_id: userDetails?.user_id,
        phone: value,
      })
    );
  };

  // function to change profile picture
  const handleUpdateProfilePic = (value: any) => {
    return dispatch(updateProfilePic(value));
  };
// function to update username
  const handleUpdateUserName = (value: string) => {
    return dispatch(
      updateUserName({
        user_id: userDetails?.user_id,
        name: value,
      })
    );
  };

  /**
   * The function `emailAlertChange` updates a list of system alerts based on the input of email,
   * location, and valve alerts.
   * @param {boolean} emailAlert - The `emailAlert` parameter in the `emailAlertChange` function is a
   * boolean value that indicates whether email alerts should be enabled or disabled.
   * @param {boolean} loactionAlert - It looks like there is a typo in the function parameter name. It
   * should be `locationAlert` instead of `loactionAlert`.
   * @param {boolean} valveAlert - The `valveAlert` parameter in the `emailAlertChange` function is a
   * boolean value that indicates whether there is an alert related to a valve. Depending on the
   * combination of `emailAlert`, `locationAlert`, and `valveAlert`, the function updates the
   * `system_alerts_block_list
   * @returns In the provided code snippet, the `emailAlertChange` function is not explicitly returning
   * any value. Instead, it is dispatching an action to update the system alerts block list based on
   * the input parameters `emailAlert`, `locationAlert`, and `valveAlert`. The function is using
   * conditional logic to determine which alerts to include in the block list based on the combination
   * of these input parameters. If
   */
  const emailAlertChange = async (
    emailAlert: boolean,
    loactionAlert: boolean,
    valveAlert: boolean
  ) => {
    try {
      let system_alerts_block_list: string[] = [
        "GATEWAY_DISCONNECTED_FRESH",
        "VALVE_OVERVOLT",
        "ALL",
      ];
      if (emailAlert) {
        if (!loactionAlert && !valveAlert) {
          system_alerts_block_list = [
            "GATEWAY_DISCONNECTED_FRESH",
            "VALVE_OVERVOLT",
          ];
        } else if (loactionAlert && !valveAlert) {
          system_alerts_block_list = ["VALVE_OVERVOLT"];
        } else if (!loactionAlert && valveAlert) {
          system_alerts_block_list = ["GATEWAY_DISCONNECTED_FRESH"];
        } else if (loactionAlert && valveAlert) {
          system_alerts_block_list = [];
        }
      } else if (!emailAlert) {
        if (!loactionAlert && !valveAlert) {
          system_alerts_block_list = [
            "GATEWAY_DISCONNECTED_FRESH",
            "VALVE_OVERVOLT",
            "ALL",
          ];
        } else if (loactionAlert && !valveAlert) {
          system_alerts_block_list = ["VALVE_OVERVOLT", "ALL"];
        } else if (!loactionAlert && valveAlert) {
          system_alerts_block_list = ["GATEWAY_DISCONNECTED_FRESH", "ALL"];
        } else if (loactionAlert && valveAlert) {
          system_alerts_block_list = ["ALL"];
        }
      }

      dispatch(updateSystemAlert(system_alerts_block_list));
    } catch {
      return;
    }
  };

 /**
  * The function `locationAlertChange` updates a system alerts block list based on the values of
  * locationAlert and valveAlert.
  * @param {boolean} emailAlert - The `emailAlert` parameter in the `locationAlertChange` function is
  * used to determine whether email alerts should be sent. It is a boolean value that indicates whether
  * email alerts are enabled or disabled.
  * @param {boolean} loactionAlert - The `loactionAlert` parameter in the `locationAlertChange`
  * function is used to determine whether a location alert should be triggered. If `loactionAlert` is
  * `true`, it indicates that a location alert should be triggered; if it is `false`, no location alert
  * should be triggered.
  * @param {boolean} valveAlert - The `valveAlert` parameter in the `locationAlertChange` function is a
  * boolean value that indicates whether there is an alert related to a valve.
  * @returns In the provided code snippet, the `locationAlertChange` function is an asynchronous
  * function that takes in three boolean parameters: `emailAlert`, `locationAlert`, and `valveAlert`.
  * Within the function, a `system_alerts_block_list` array is populated based on the conditions of the
  * input parameters. Finally, the `updateSystemAlert` action is dispatched with the
  * `system_alerts_block
  */
  const locationAlertChange = async (
    emailAlert: boolean,
    loactionAlert: boolean,
    valveAlert: boolean
  ) => {
    try {
      let system_alerts_block_list: string[] = [];
      if (loactionAlert && !valveAlert) {
        system_alerts_block_list = ["VALVE_OVERVOLT"];
      } else if (loactionAlert && valveAlert) {
        system_alerts_block_list = [];
      } else if (!loactionAlert && valveAlert) {
        system_alerts_block_list = ["GATEWAY_DISCONNECTED_FRESH"];
      } else if (!loactionAlert && !valveAlert) {
        system_alerts_block_list = [
          "GATEWAY_DISCONNECTED_FRESH",
          "VALVE_OVERVOLT",
        ];
      }
      dispatch(updateSystemAlert(system_alerts_block_list));
    } catch {
      return;
    }
  };

  // function to change valve notification settings
  const valveAlertChange = async (
    emailAlert: boolean,
    loactionAlert: boolean,
    valveAlert: boolean
  ) => {
    try {
      let system_alerts_block_list: string[] = [];

      if (valveAlert && !loactionAlert) {
        system_alerts_block_list = ["GATEWAY_DISCONNECTED_FRESH"];
      } else if (valveAlert && loactionAlert) {
        system_alerts_block_list = [];
      } else if (loactionAlert && !valveAlert) {
        system_alerts_block_list = ["VALVE_OVERVOLT"];
      } else if (!valveAlert && !loactionAlert) {
        system_alerts_block_list = [
          "GATEWAY_DISCONNECTED_FRESH",
          "VALVE_OVERVOLT",
        ];
      }
      dispatch(updateSystemAlert(system_alerts_block_list));
    } catch {
      return;
    }
  };

  return {
    handleLogout,
    handleLogoutPopUpClose,
    handleChangePhoneNumber,
    handleUpdateUserName,
    emailAlertChange,
    locationAlertChange,
    valveAlertChange,
    handleUpdateProfilePic,
    isLogout,
  };
};
export default useSettingHelper;
