import { Modal, Box } from "@mui/material";
import React from "react";
import "./modal.scss";

type SimpleModalProps = {
  isOpen: boolean;
  handleClose: any;
  className?: any;
  children: React.ReactNode;
};

function SimpleModal({ isOpen, handleClose, children, className }: SimpleModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`modalPopo ${className}`}
    >
      <>
        {children}
      </>
    </Modal>
  );
}

export default SimpleModal;
