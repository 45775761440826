import { FC } from "react";

const DashboardSvg: FC<{}> = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.5 18.5V15H8V18.5" stroke="#6D839B" strokeWidth="1.5" />
      <path d="M8 18.5V8.5H14.5V18.5" stroke="#00ACE5" strokeWidth="1.5" />
      <path d="M14.5 18.5V1.5H20.5V18.5" stroke="#6D839B" strokeWidth="1.5" />
      <path d="M0 21H22" stroke="#6D839B" strokeWidth="1.5" />
    </svg>
  );
};

export default DashboardSvg;
