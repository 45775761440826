import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DashboardState, WidgetConfiguration } from "../types/dashboard.type";
import { dashboardAllWidgets, dashboardCalculatedWidget } from "../actions/dashboard.action";
import { act } from "react-dom/test-utils";

export const initialWidgetConfiguration: WidgetConfiguration = {
  column: 1,
  position: 1,
  type: "",
  timeFilter: "",
  customDate: {
    startDate: "",
    endDate: "",
  },
  widgetId: 1,
  widgetConfig: {
    washPackageId: "",
    chemicalId: "",
  },
  category: "",
  step: [],
  id: "",
  label: "",
};

const initialState: DashboardState = {
  allWidgets: [],
  widgets: [],
  calculatedWidgets: [],
  widgetConfiguration: initialWidgetConfiguration,
  isAddedWidget: false,
  widgetApiData: {}
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setWidgets: (state: DashboardState, action: PayloadAction<any>) => {
      state.widgets = action.payload;
    },
    /**
     * Sets the configuration for a widget on the dashboard.
     *
     * @param state - The current state of the dashboard.
     * @param action - The action that contains the new widget configuration.
     * @returns The updated state with the new widget configuration.
     */
    setWidgetConfiguration: (
      state: DashboardState,
      action: PayloadAction<WidgetConfiguration>
    ) => {
      state.widgetConfiguration = action.payload;
    },
    /**
     * Sets the calculated widgets in the dashboard state.
     *
     * @param state - The current state of the dashboard.
     * @param action - The action that contains the new calculated widgets.
     * @returns The updated state with the new calculated widgets.
     */
    setCalculatedWidgets: (
      state: DashboardState,
      action: PayloadAction<any>
    ) => {
      state.calculatedWidgets = action.payload;
    },
    /**
     * Sets the all widgets in the dashboard state.
     *
     * @param state - The current state of the dashboard.
     * @param action - The action that contains the new all widgets.
     * @returns The updated state with the new all widgets.
     */
    setAllWidgets: (state: DashboardState, action: PayloadAction<any>) => {
      state.allWidgets = action.payload;
    },
    setIsAddWidget: (state: DashboardState, action: PayloadAction<boolean>) => {
      state.isAddedWidget = action.payload;
    },
    /**
     * Clears the widget API data for a specific gateway ID in the dashboard state.
     *
     * @param state - The current state of the dashboard.
     * @param action - The action that contains the gateway ID to clear the widget API data for.
     * @returns The updated state with the widget API data cleared for the specified gateway ID.
     */
    clearWidgetApiData: (state: DashboardState, action: PayloadAction<any>) => {
      const updatedData = { ...state.widgetApiData }
      const gatewayId = action.payload?.gatewayId
      if (updatedData[gatewayId]) {
        updatedData[gatewayId] = {}
        state.widgetApiData = updatedData
      }
    },
    /**
     * Sets the widget API data for a specific gateway ID in the dashboard state.
     *
     * @param state - The current state of the dashboard.
     * @param action - The action that contains the gateway ID and the new widget API data.
     * @returns The updated state with the new widget API data.
     */
    setWidgetApiData: (state: DashboardState, action: PayloadAction<any>) => {

      const gatewayId = action.payload.gatewayId
      const data = action.payload.data
      const keyInitial = action.payload?.keyInitial
      const updatedData = { ...state.widgetApiData }
      if (keyInitial && updatedData[gatewayId]) {
        Object.keys(updatedData[gatewayId]).forEach((id) => {
          if (id.startsWith(keyInitial)) {
            delete updatedData[gatewayId][id]
          }
        })
      }
      updatedData[gatewayId] = { ...updatedData[gatewayId], ...data }
      state.widgetApiData = updatedData
    },
    /**
     * Deletes a specific widget API data entry for a given gateway ID in the dashboard state.
     *
     * @param state - The current state of the dashboard.
     * @param action - The action that contains the gateway ID and the ID of the widget API data to delete.
     * @returns The updated state with the specified widget API data entry deleted.
     */
    deleteKeyWidgetApiData: (state: DashboardState, action: PayloadAction<any>) => {
      const gatewayId = action.payload.gatewayId
      const id = action.payload.id
      const updatedData = { ...state.widgetApiData }
      if (updatedData[gatewayId] && updatedData[gatewayId][id]) {
        delete updatedData[gatewayId][id]
      }
      state.widgetApiData = updatedData
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      dashboardAllWidgets.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.allWidgets = action.payload;
      }
    );
    builder.addCase(
      dashboardCalculatedWidget.fulfilled,
      (state, action: PayloadAction<any>) => {
        let data = action.payload
        let filteredArray = state.calculatedWidgets.filter((item: any) => item.id !== action.payload.id);
        let updatedData = [...filteredArray, data];
        state.calculatedWidgets = updatedData;
      }
    );
  },
});

export const {
  setWidgets,
  setWidgetConfiguration,
  setCalculatedWidgets,
  setAllWidgets,
  setIsAddWidget,
  setWidgetApiData,
  deleteKeyWidgetApiData,
  clearWidgetApiData,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
