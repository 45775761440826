import { FC } from "react";

const WaterSenseSvg: FC<{}> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#00ACE5" />
      <g clipPath="url(#clip0_2564_9315)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.45 11.3414L20 11.9414L19.55 11.3414L20 11.0039L20.45 11.3414ZM19.55 11.3414C19.5501 11.3413 19.55 11.3414 20 11.9414C20.45 11.3414 20.4498 11.3413 20.45 11.3414L20.4523 11.3431L20.4569 11.3466L20.4735 11.3592C20.4877 11.3699 20.508 11.3855 20.5342 11.4058C20.5866 11.4462 20.6622 11.5052 20.7577 11.5814C20.9487 11.7337 21.2197 11.9551 21.5441 12.2344C22.1917 12.7921 23.0577 13.5856 23.9261 14.5265C24.7923 15.4649 25.6759 16.5657 26.3465 17.7392C27.0135 18.9065 27.5 20.1982 27.5 21.5039C27.5 25.6461 24.1421 29.0039 20 29.0039C15.8579 29.0039 12.5 25.6461 12.5 21.5039C12.5 20.1982 12.9865 18.9065 13.6535 17.7392C14.3241 16.5657 15.2077 15.4649 16.0739 14.5265C16.9423 13.5856 17.8082 12.7921 18.4559 12.2344C18.7803 11.9551 19.0513 11.7337 19.2423 11.5814C19.3378 11.5052 19.4134 11.4462 19.4658 11.4058C19.4919 11.3855 19.5124 11.3699 19.5265 11.3592L19.5431 11.3466L19.5477 11.3431L19.55 11.3414ZM20 24.5039C18.3431 24.5039 17 23.1607 17 21.5039H15.5C15.5 23.9892 17.5147 26.0039 20 26.0039V24.5039Z"
          fill="url(#paint0_linear_2564_9315)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2564_9315"
          x1="20"
          y1="11.0039"
          x2="20"
          y2="29.0039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.9" />
        </linearGradient>
        <clipPath id="clip0_2564_9315">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(11 11.0039)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WaterSenseSvg;
