import { createAsyncThunk } from "@reduxjs/toolkit";
import { showAlert } from "src/utils";
import { getDataApi } from "src/utils/common/constants";
import { setLoading, setLocationCompLoaded } from "../features/global.slice";

/**
 * Fetches the panel and pump details for a given location ID.
 *
 * @param {string} location_id - The ID of the location to fetch the panel and pump details for.
 * @returns {Promise<any>} - A promise that resolves with the fetched panel and pump details.
 */
export const panelAndPumpDetail = createAsyncThunk(
  "/pumps-and-panels",
  async (
    location_id: string,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    dispatch(setLocationCompLoaded(false));
    try {
      const res = await getDataApi({
        path: `alfred/location-components?lid=${location_id}`,
        data: {},
      });
      dispatch(setLocationCompLoaded(true));
      return fulfillWithValue(res);
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;
      dispatch(setLocationCompLoaded(true));
      showAlert(2, message);
      return rejectWithValue(message);
    }
  }
);
