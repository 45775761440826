import React from "react";

const TermConditions = () => {
  return (
    <div className="policyMainWrapper">
      <h1 className="PageHeading">Terms & Conditions</h1>

      <div className="InnerScrollWrapper">
        <h2 className="headingTxt">PLACING YOUR ORDER</h2>

        <ol className="txtListing">
          <li>
            All orders are processed for shipment immediately upon receipt of
            the order.
          </li>

          <li>
            Sonny's minimum order requirement is $50.00. Orders less than the
            minimum will be subject to additional handling charges.
          </li>

          <li>All prices are subject to change without notice.</li>

          <li>
            Orders will be invoiced at the prices prevailing on the date of
            shipment.
          </li>

          <li>
            Sonny's requires full payment for custom special order requests at
            the time of order. Custom special orders cannot be cancelled or
            returned
          </li>
        </ol>

        <h2 className="headingTxt">PAYMENT TERMS AND POLICY</h2>

        <p className="policyText">
          Payment can be made by Visa, MasterCard, Discover or American Express.
          Pre-payment may also be made by check. There will be a $35.00 service
          charge on all returned checks. The customer assumes all
          responsibilities for any sales or use tax not paid to the company and
          to pay the tax to the proper tax authority and hold Sonny's harmless
          for tax expenses connected herewith.
        </p>

        <h2 className="headingTxt">HOW WE CALCULATE TAX</h2>

        <p className="policyText">
          The amount of tax charged on your order depends on many factors
          including the following:
        </p>

        <ol className="txtListing">
          <li>The type of item purchased</li>

          <li>The shipment or delivery address of your order</li>

          <li>The time and location of fulfillment</li>

          <li>The shipping method used to fulfill delivery</li>
        </ol>

        <p className="policyText">
          We provide an "Estimated Tax" at the time of Check Out and on the
          initial order confirmation. In some situations the factors used to
          calculate taxes can change between the time you place an order and
          when your shipment is finalized.
        </p>

        <p className="policyText">
          As a result, the tax calculated on your order may change. Items sold
          by Sonny’s and its affiliated companies that are shipped to the
          following states or picked up at our locations may be subject to tax:
        </p>

        <ol className="txtListing">
          <li>California</li>

          <li>Florida</li>

          <li>Alabama</li>

          <li>Illinois</li>

          <li>Mississippi</li>

          <li>South Carolina</li>

          <li>Texas</li>

          <li>Georgia</li>

          <li>Kentucky</li>

          <li>North Carolina</li>

          <li>Tennessee</li>

          <li>Virginia</li>

          <li>Pennsylvania</li>

          <li>Ohio</li>

          <li>Michigan</li>

          <li>Arizona</li>

          <li>Colorado</li>
        </ol>

        <h2 className="headingTxt">RESALABLE/TAX EXEMPTION CERTIFICATE</h2>

        <p className="policyText">
          If you have a resalable/tax exemption certificate please email it to{" "}
          <a
            href="mailto: accountsreceivable@sonnysdirect.com"
            className="policyLink"
          >
            accountsreceivable@sonnysdirect.com
          </a>
        </p>

        <p className="policyText">
          Include the following information with the submission of your
          resalable/tax exemption certificate:
        </p>

        <ol className="txtListing">
          <li>Your account number</li>

          <li>Email address</li>

          <li>Telephone number</li>
        </ol>

        <p className="policyText">
          Credit terms are available upon approval. To apply, customers can
          contact our Customer Service department at 1-800-327-8723. Additional
          credit terms will apply. Select items are prepayment only and are not
          eligible for extended credit.
        </p>

        <h2 className="headingTxt">DAMAGED, LOST GOODS OR SHORTAGES</h2>

        <p className="policyText">
          Any visible damage or lost goods noticed at the time of shipment
          should be immediately communicated to the carrier, noted on all
          delivery paperwork, and a freight claim filed. If damages are
          uncovered after delivery, the original packaging must be held for
          claim evaluation by the shipping company. All shipments should be
          inspected within five (5) business days for any shortages or hidden
          damages.
        </p>

        <h2 className="headingTxt">WARRANTY</h2>

        <p className="policyText">
          All products sold by Sonny's carry the manufacturer's warranties;
          these warranties are the sole responsibility of the manufacturer.
          There are no other warranties or representations of any kind
          expressed, or implied, by Sonny's Enterprises, LLC. The customer will
          be responsible for transportation, labor costs, and any other
          incidental expenses in connection with the warranty repair or
          replacement.
        </p>

        <h2 className="headingTxt">RETURN POLICY</h2>

        <p className="policyText">
          To return products shipped from Sonny's warehouse, utilize the FedEx
          label included with the original shipment. All returns must include a
          copy of the packing slip or a completed return form from the original
          shipment. Customers may be charged a return fee of $15 for use of the
          FedEx label depending on the reason for the return. Items ordered in
          error are subject to a re-stocking charge of up to 25%.
        </p>

        <p className="policyText">
          To return products that do not have a return label, or for warranty
          return requests, customers must request a Return Material
          Authorization (RMA) Number through the Support section of Sonny's
          website at www.sonnysdirect.com. Once the request has been processed,
          customers will receive instructions on returning the item to Sonny's
          or directly to the vendor, in the case that items were drop shipped
          from a third party. Items must be returned within 30 days of receipt
          of the RMA#, be in new and saleable condition, complete with all
          manuals, parts and accessories, as sold originally to avoid additional
          charges. All returned orders must include the RMA# on the shipping
          label, unless otherwise instructed. Return items must be shipped
          prepaid and insured. All returns shipped freight-collect will be
          rejected. We recommend that item(s) be returned in their original
          packaging. Please be advised that Sonny's will not be responsible for
          loss or damage during return shipment to Sonny's or third party
          vendors. Returns without a valid RMA# or missing required paperwork
          will not be processed. The returns department will contact the
          customer if there is contact information available and hold the
          package for fourteen (14) days from the date of receipt. If the
          customer does not follow up after initial contact, the item(s) will be
          discarded.
        </p>

        <h2 className="headingTxt">COPYRIGHT NOTICE</h2>

        <p className="policyText">
          This website and all its content is copyright of Sonny’s Enterprises,
          LLC - © 2000-2023 Sonny’s Enterprises, LLC. All rights reserved. Any
          redistribution or reproduction of part or all of the contents in any
          form is prohibited other than the following:
        </p>

        <ol className="txtListing">
          <li>
            you may print or download to a local hard disk extracts for your
            personal and non-commercial use only.
          </li>

          <li>
            you may copy the content to individual third parties for their
            personal use, but only if you acknowledge the website as the source
            of the material
          </li>
        </ol>
        <p className="policyText">
          You may not, except with our express written permission, distribute or
          commercially exploit the content. Nor may you transmit it or store it
          in any other website or other form of electronic retrieval system.
        </p>

        <h2 className="headingTxt">PRIVACY POLICY</h2>

        <p className="policyText">
          Sonny's Enterprises, LLC and its subsidiaries, divisions, affiliates,
          and brands (collectively “We”) value our customers and are committed
          to protecting personal information about them and their companies.
          This notice (“Privacy Policy”) describes what information we collect
          about you, how we use it, with whom we may share it, how we collect
          it, and what choices you have regarding it. This Privacy Policy is
          incorporated into and forms part of the Terms of Use{" "}
          <a href="www.sonnysdirect.com" className="policyLink">
            www.sonnysdirect.com
          </a>{" "}
          of each Sonny's Enterprises, LLC website, or any replacement site, the
          “Site” or “Sites”). Please review and become familiar with the terms
          and conditions of both this Privacy Policy and the Terms of Use.
          <span className="TxtCap">
            BY ACCESSING OR USING ANY SONNY'S SITE YOU ACKNOWLEDGE THAT YOU HAVE
            READ, UNDERSTAND, AND CONSENT TO THE PRIVACY PRACTICES, AND TO THE
            USES AND DISCLOSURES OF INFORMATION ABOUT YOU AND YOUR COMPANY, THAT
            ARE DESCRIBED IN THIS PRIVACY POLICY AND YOU AGREE TO BE BOUND BY
            THEIR TERMS.
          </span>
          Please review this Privacy Policy periodically, as we may update it
          from time to time, as further described below.
        </p>

        <p className="policyText">
          <span className="BoldItelic"> Information We Collect:</span> Our web
          servers collect general data pertaining to each Site visitor,
          including the IP address, tracking codes, the domain name, the web
          page, the length of time spent and the pages accessed while visiting
          this Site. This information is collected to, among other things,
          aggregate information from online and offline sources, facilitate
          system administration, improve the content of the Site, customize and
          improve the user experience for Site visitors and customers, and
          improve customer service generally. We also collect personally
          identifiable and transactional information provided by companies and
          Site visitors that purchase products from that Site. This information
          includes contact information, shipping information, credit cardholder
          name, and card number/expiration date. We may also retain the content
          of the communications that are sent to our customer service
          representatives. This information helps us to respond to inquiries
          regarding this Site.
        </p>

        <p className="policyText">
          <span className="BoldItelic"> Use of Information Collected:</span> We
          use the information that is collected from Site visitors and customers
          to, among other things, better serve customers, improve the content of
          this Site, and customize the shopping experience for Site visitors and
          customers. We may also use the information collected to notify Site
          visitors of changes to this Site, to notify Site visitors of new
          products, and for any other legitimate and lawful business purpose. We
          may share your information as follows: Among our various subsidiaries,
          divisions, parent organizations, and brands.
        </p>

        <p className="policyText">
          <span className="BoldItelic"> Advertising and Remarketing:</span>{" "}
          Sonny’s uses third-party remarketing tracking cookies (see: “Cookies
          and Other Technology Information), including but not limited to the
          Google AdWords tracking cookie, to track your interaction(s) with our
          site and to continue to show ads to you across the internet on the
          Google Display Network. You can opt out of this third-party vendor’s
          use of cookies by visiting the Network Advertising Initiative Opt-Out
          Page:{" "}
          <a
            className="policyLink"
            href="http://www.networkadvertising.org/choices/"
          >
            http://www.networkadvertising.org/choices/
          </a>
        </p>

        <h2 className="headingTxt">SECURITY POLICY</h2>

        <p className="policyText">
          <span className="BoldItelic"> Website Security:</span> Site security
          is a priority. We periodically review and update the security methods
          intended to protect information transmitted via this Site, including
          measures intended to secure and protect all personally identifiable
          information from loss, misuse, and alteration. Our Site uses
          industry-standard encryption technology. However, we cannot guarantee
          that any electronic commerce is totally secure. We will make any
          legally required disclosures of any breach of the security,
          confidentiality, or integrity of electronically stored sensitive
          personal data via email or by conspicuous posting on the Site as soon
          as possible to the extent consistent with (a) the legitimate needs of
          law enforcement, and (b) any measures necessary to determine the scope
          of the breach and restore the reasonable integrity of the data system.
        </p>

        <p className="policyText">
          <span className="BoldItelic">Account Security: </span> We are
          dedicated to ensuring that your account remains secure. Here are
          several actions you can take to help protect your account:
        </p>

        <ol className="txtListing">
          <li>
            Keep Your User ID and Password Secure. We ask for your password when
            you log-in to our site. We will never ask for your password in any
            unsolicited communication.
          </li>

          <li>
            To enhance your online safety, follow these recommendations to keep
            your personal information private:
          </li>

          <ol className="txtListing">
            <li>Don’t reveal your password to anyone.</li>

            <li>Change your password periodically.</li>
          </ol>
        </ol>

        <h2 className="headingTxt BoldItelic">
          Protect Your Account from Online Fraud:
        </h2>

        <ol className="txtListing">
          <li>
            The Internet is not immune to fraud. Criminals will use spoof
            e-mails, phony web sites, and other deceptive practices to try to
            obtain your personal information; this is known as phishing.
          </li>

          <li>
            Spoof emails are phony emails that appear to have originated from a
            legitimate company. These phony emails ask you to go to a spoof web
            site and provide your personal account information. To bait you,
            often these deceptive emails may allude to an urgent matter
            concerning your account. In many cases, there is no easy way to
            determine that you are on a phony web site because the URL may
            contain the name of the company it is spoofing.
          </li>
        </ol>

        <h2 className="headingTxt BoldItelic">Security Tips:</h2>

        <ol className="txtListing">
          <li>
            Delete suspicious emails without opening them. If you do open a
            suspicious email, never click on a link or open an attachment
            contained in the suspicious email.
          </li>

          <li>Do not provide your sensitive information by email or phone.</li>

          <li>
            When entering private information, confirm your security by looking
            for a closed lock at the bottom of the Web page. Click on the lock
            icon to verify that the security certificate has been issued to our
            Site.
          </li>

          <li>
             If you believe that there is a problem with your account, please
            contact customer service. To report a suspicious email that uses our
            name, immediately forward it to{" "}
            <a className="policyLink" href="Service@SonnysDirect.com">
              Service@SonnysDirect.com
            </a>{" "}
            or call 1-800-327-8723.
          </li>
        </ol>

        <p className="policyText">
          <span className="BoldItelic">
            Cookies and Other Technology Information:
          </span>{" "}
          A cookie is a small file stored on your computer by a web site to give
          you a unique ID. We use Cookies and other technologies to track new
          visitors to this Site and recognize past customers so that we may
          present more personalized content. Cookies used by this Site do not
          contain any personally identifiable information. We cannot identify a
          customer unless that information is provided voluntarily. NOTE: If you
          configure your computer to block cookies, you may not be able to
          access certain Site functionality.
        </p>

        <p className="policyText">
          <span className="BoldItelic">Links: </span> This Site contains links
          to other sites that are not owned, maintained, operated, or endorsed
          by or for Sonny's and not subject to the same Privacy and other
          Policies. We are not responsible for the content of those sites or the
          use of any information collected from you by those sites. The
          inclusion of any link to such sites does not imply any recommendation
          or sponsorship of such sites and we disclaim any liability for links:
          (i) from another website to this Site, and (ii) to another website
          from this Site. You should read the other site’s privacy policies to
          understand how personal information collected about you is used and
          protected.
        </p>

        <p className="policyText">
          <span className="BoldItelic">Policy Updates: </span> From time to time
          we may change our privacy policies because of changes in the relevant
          and applicable legal or regulatory requirements, changes in our
          business or business practices, or in our attempts to better serve our
          customers. Notice of any material changes in the manner in which we
          handle personally identifiable information under this policy will be
          provided on the Site with an updated revision date. Please check the
          Site periodically for updates. Policy updates shall be effective the
          date the change is posted on the Site. By continuing to use the Site
          after we post any changes, you agree to the terms of the updated
          policy.
        </p>

        <p className="policyText">
          <span className="BoldItelic">Content Disclaimer: </span>The content of
          this website, including downloadable assets and other digital
          marketing materials, are for informational purposes only and does not
          constitute any legal, financial, or tax advice. Investments carry
          significant risk. You are expected to seek the advice of your own
          counsel and other professional advisors, especially with respect to
          any legal, regulatory, tax and accounting matters.
        </p>

        <p className="policyText">
          <span className="BoldItelic">Questions:</span>If users have any
          questions or suggestions regarding our privacy policy, please contact
          Us by sending an email to: Service@SonnysDirect.com Updated September
          1, 2023
        </p>
      </div>
    </div>
  );
};

export default TermConditions;
