import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ChemSenseOverviewState } from "../types/chemSense.type";
import { chemSenseOverview } from "../actions/chem-sense-overview.action";

const overviewInitialState: ChemSenseOverviewState = {
  chemSenseOverview: [],
  pending: false,
};

const chemSenseOverviewSlice = createSlice({
  name: "chemSenseOverview",
  initialState: overviewInitialState,
  reducers: {
    reset() {
      return {
        ...overviewInitialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(chemSenseOverview.pending, (state) => {
        state.pending = true;
      })
      .addCase(
        chemSenseOverview.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.chemSenseOverview = action.payload;
          state.pending = false;
          return state;
        }
      )
      .addCase(
        chemSenseOverview.rejected,
        (state, action: PayloadAction<any>) => {
          state.pending = false;
        }
      );
  },
});

export const {} = chemSenseOverviewSlice.actions;

export default chemSenseOverviewSlice.reducer;
