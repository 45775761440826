import { FC } from "react";

const PanelSvg: FC<{}> = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="36"
        height="36"
        rx="18"
        fill="url(#paint0_linear_2547_5846)"
      />
      <g clipPath="url(#clip0_2547_5846)">
        <path
          d="M18.667 24.6667H22.0004V26H14.0004V24.6667H17.3337V22H15.8897L16.0677 16.6667H19.9337L20.1117 22H18.6677V24.6667H18.667ZM19.711 10H16.2897L16.1117 15.3333H19.889L19.711 10ZM25.0924 15.3333L24.6164 11.7367C24.485 10.7467 23.6324 10 22.6337 10H21.0457L21.2237 15.3333H25.0924ZM14.9557 10H13.3677C12.3684 10 11.5164 10.7467 11.385 11.7373L10.909 15.3333H14.7784L14.9557 10ZM21.4457 22H25.975L25.269 16.6667H21.2677L21.4457 22ZM14.733 16.6667H10.7324L10.0264 22H14.5557L14.733 16.6667Z"
          fill="url(#paint1_linear_2547_5846)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2547_5846"
          x1="36"
          y1="36"
          x2="0"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F13741" />
          <stop offset="1" stopColor="#F4929E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2547_5846"
          x1="18.0007"
          y1="10"
          x2="18.0007"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
        <clipPath id="clip0_2547_5846">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PanelSvg;
