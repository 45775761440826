import { FC } from "react";

const PumpSvg: FC<{}> = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="36"
        height="36"
        rx="18"
        fill="url(#paint0_linear_2547_5866)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.7148C10 15.2966 13.5817 11.7148 18 11.7148C22.4183 11.7148 26 15.2966 26 19.7148C26 21.3 25.5384 22.7791 24.7421 24.023C24.637 24.1871 24.4555 24.2863 24.2608 24.2863H11.7392C11.5444 24.2863 11.363 24.1871 11.258 24.023C10.4616 22.7791 10 21.3 10 19.7148ZM15.8779 16.9881L18.8853 22.0006H16.8861L14.4079 17.8701C14.1643 17.4642 14.2959 16.9377 14.7019 16.6941C15.1078 16.4506 15.6343 16.5822 15.8779 16.9881Z"
        fill="url(#paint1_linear_2547_5866)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2547_5866"
          x1="36"
          y1="36"
          x2="0"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#277AF6" />
          <stop offset="1" stopColor="#27C6F6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2547_5866"
          x1="18"
          y1="11.7148"
          x2="18"
          y2="24.2863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PumpSvg;
