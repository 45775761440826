import React from 'react';
import "./about.scss";
import {
    Box, Typography,
} from "@mui/material";

const AboutUs = () => {
    return (

        <div className="policyMainWrapper">
            <h1 className="PageHeading">
                About Us
            </h1>


            <div className="InnerScrollWrapper">

                <h2 className="headingTxt">
                    PLACING YOUR ORDER
                </h2>

                <p className="policyText">
                Sonny's is the world's largest manufacturer of conveyorized car wash equipment, parts, and supplies. Car wash new investors and existing operators partner with Sonny's because of Sonny's total offering, market leadership, and industry expertise. 
                </p>

                <p className="policyText">
                Sonny's CarWash College and CarWash Controls software deliver cutting-edge business management and education to master the trade. Sonny's CarWash Chemistry, Signage, and Marketing create a competitive advantage to win every customer and increase average tickets. Innovation in CarWash Equipment, Vacuums, Water Treatment, and Backroom technology combine with the most extensive inventory of Car Wash Parts with onsite CarWash Services to help keep your business growing. For more information about how Sonny's can Make CarWashing Easy for you, visit SonnysDirect.com
                </p>

            
            </div>
        </div>


    );
}

export default AboutUs

