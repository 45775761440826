import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Button,
  FormHelperText,
} from "@mui/material";
import "./user-account.scss";
import { useSelector } from "react-redux";
import { UserDetail, User } from "src/redux/types/userDetail";
import moment from "moment";
import useSettingHelper from "../helper";
import { Formik } from "formik";
import { AccountSchema } from "./account-validator";
import { showAlert } from "src/utils";

const UserAccount = () => {
  const userInfo: UserDetail = useSelector((state: any) => state.userInfo);
  const userDetails: User = userInfo.userDetail;
  const [name, setName] = useState(userDetails?.name);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    userDetails?.user_metadata?.phone_number
  );
  const [showField, setShowField] = useState<boolean>(false);
  const [showPhoneField, setShowPhoneField] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(
    userDetails?.user_metadata?.profile_picture
  );
  const {
    handleChangePhoneNumber,
    handleUpdateUserName,
    handleUpdateProfilePic,
  } = useSettingHelper();

 /* The above code snippet is written in TypeScript React. It includes a `useEffect` hook that sets the
 `selectedImage` state to the value of `userDetails?.user_metadata?.profile_picture` whenever there
 is a change in that value. */
  useEffect(() => {
    setSelectedImage(userDetails?.user_metadata?.profile_picture);
  }, [userDetails?.user_metadata?.profile_picture]);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const initialValues = {
    fullName: name,
    phone: phoneNumber,
  };
  const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };
 /**
  * The above code snippet includes functions for handling date formatting, editing fields, editing
  * phone numbers, and handling file uploads with validation checks.
  * @param {string} dateString - The `dateString` parameter is a string that represents a date in a
  * specific format. The `handleDateFormat` function takes this string as input, converts it into a
  * `moment` object (assuming you are using the Moment.js library), and then formats it to "MMM D,
  * YYYY" format
  * @returns The code snippet provided contains several functions related to handling date formats,
  * editing fields, handling file changes, and submitting form values. The `handleDateFormat` function
  * takes a date string as input, converts it to a moment object, and returns the formatted date in the
  * "MMM D, YYYY" format.
  */
  const handleDateFormat = (dateString: string) => {
    const date = moment(dateString);
    return date.format("MMM D, YYYY");
  };
  const handleEdit = () => {
    setShowField(true);
  };
  const handlePhoneEdit = () => {
    setShowPhoneField(true);
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file?.type === "image/jpeg" || file?.type === "image/png") {
        if (file?.size <= 5 * 1024 * 1024) {
          const reader = new FileReader();

          reader.onload = () => {
            setSelectedImage(reader.result);
            const formData = new FormData();
            formData.append("image", file);
            handleUpdateProfilePic(file);
          };
          reader.readAsDataURL(file);
        } else {
          setSelectedImage(userDetails?.picture);
          showAlert(
            2,
            "Image size exceeds 5 MB. Please select a smaller image."
          );
        }
      } else {
        showAlert(2, "Invalid file type. Only JPEG or PNG files are allowed.");
      }
    }
  };
  const onSubmit = async (values: any) => {};

  return (
    <Box>
      <Typography className="pump_detail_heading">
        Account Information
      </Typography>
      <div className="pump_detail_subheading">
        Essential information about yourself will be displayed in this section
      </div>
      <Grid container className="bottomSection">
        <Grid>
          <figure className="img">
            {selectedImage ? (
              <img src={selectedImage} alt="" />
            ) : (
              <img src={userDetails.picture} alt="" />
            )}
          </figure>
        </Grid>
        <Grid>
          <Typography className="dataLabel">Profile picture</Typography>
          <div>
            <label htmlFor="files" className="btn changeImg">
              Change picture
            </label>
            <input
              id="files"
              className="change-pic"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={AccountSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          isSubmitting,
          dirty,
          isValid,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container justifyContent="flex-start" className="flexRow">
              <Grid>
                <p className="tableLabel">Full Name</p>
              </Grid>
              <Grid className="roleTxt">
                {!showField && <p>{userDetails?.name}</p>}
              </Grid>
              <Grid className="customInputWrapper">
                {showField ? (
                  <FormControl
                    fullWidth
                    error={Boolean(touched.fullName && errors.fullName)}
                    className={`inp_formCtrl ${
                      Boolean(touched.fullName && errors.fullName) &&
                      "inp_field_error"
                    }`}
                  >
                    <Box className="inp">
                      <input
                        type="text"
                        value={values.fullName}
                        name="fullName"
                        maxLength={25}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={() => {
                          let element = document.getElementById("user-icon");
                          element?.classList.add("inp_field_foucs");
                        }}
                        id="outlined-adornment-fullName"
                        placeholder="Full Name"
                      />
                      <Box className="pvw">
                        <Button
                          className="icon"
                          style={{ cursor: "pointer" }}
                          disabled={Boolean(
                            touched.fullName && errors.fullName
                          )}
                          onClick={() => {
                            handleUpdateUserName(values.fullName).then(
                              (response) => {
                                if (
                                  response?.meta?.requestStatus === "fulfilled"
                                ) {
                                  setShowField(false);
                                }
                              }
                            );
                          }}
                        >
                          {"Save"}
                        </Button>
                      </Box>
                    </Box>
                    {touched.fullName && errors.fullName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-fullName-login"
                      >
                        <>{errors.fullName}</>
                      </FormHelperText>
                    )}
                  </FormControl>
                ) : (
                  <Button onClick={handleEdit} className="editBtn">
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-start" className="flexRow">
              <Grid>
                <p className="tableLabel">Role</p>
              </Grid>
              <Grid>
                <p className="roleTxt">
                  {capitalizeFirstLetter(userDetails?.role_type)}
                </p>
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" className="flexRow">
              <Grid>
                <p className="tableLabel">Phone Number</p>
              </Grid>
              <Grid className="roleTxt">
                {!showPhoneField && (
                  <p>
                    {" "}
                    <span className="num">+1</span>
                    {userDetails?.user_metadata?.phone_number}
                  </p>
                )}
              </Grid>
              <Grid className="customInputWrapper">
                {showPhoneField ? (
                  <FormControl
                    fullWidth
                    error={Boolean(touched.phone && errors.phone)}
                    className={`inp_formCtrl ${
                      Boolean(touched.phone && errors.phone) &&
                      "inp_field_error"
                    }`}
                  >
                    <Box className="inp">
                      <span className="num">+1</span>
                      <input
                        className="no-spinner"
                        type="number"
                        value={values.phone}
                        name="phone"
                        onFocus={(e) => {
                          let element = document.getElementById("phone-icon");
                          element?.classList.add("inp_field_foucs");
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          if (e.code === "Space") e.preventDefault();
                        }}
                        placeholder="Phone Number"
                        id="outlined-adornment-phone"
                      />
                      <Box className="pvw">
                        <Button
                          className="icon"
                          style={{ cursor: "pointer" }}
                          disabled={Boolean(touched.phone && errors.phone)}
                          onClick={() => {
                            handleChangePhoneNumber(
                              !values.phone ? "" : values?.phone.toString()
                            ).then((response) => {
                              if (
                                response?.meta?.requestStatus === "fulfilled"
                              ) {
                                setShowPhoneField(false);
                              }
                            });
                          }}
                        >
                          {"Save"}
                        </Button>
                      </Box>
                    </Box>
                    {touched.phone && errors.phone && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-phone-login"
                      >
                        <>{errors.phone}</>
                      </FormHelperText>
                    )}
                  </FormControl>
                ) : (
                  <Button onClick={handlePhoneEdit} className="editBtn">
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" className="flexRow">
              <Grid>
                <p className="tableLabel">Email Address</p>
              </Grid>
              <Grid>
                <p className="roleTxt emailTxt">{userDetails?.email}</p>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-start" className="flexRow">
              <Grid>
                <p className="tableLabel">Last Login</p>
              </Grid>
              <Grid>
                <p className="roleTxt">
                  {handleDateFormat(userDetails?.last_login)}
                </p>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UserAccount;
