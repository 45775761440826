import { WebAuth } from "auth0-js";

/**
 * Initializes an instance of the Auth0 WebAuth client with the specified configuration.
 * This instance can be used to authenticate users and manage their authentication state.
 *
 * @param {string} domain - The Auth0 domain for the application.
 * @param {string} clientID - The client ID of the Auth0 application.
 * @param {string} redirectUri - The redirect URI for the Auth0 application.
 * @param {string} responseType - The response type for the authentication flow.
 * @returns {WebAuth} - An instance of the Auth0 WebAuth client.
 */
const authHelper = new WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
  clientID:
    process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
  redirectUri:
    process.env.REACT_APP_AUTH0_REDIRECT_URI ?? "",
  responseType: "id_token",
});

export default authHelper;
