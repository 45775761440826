import * as Yup from "yup";

export const LocationSchema = Yup.object().shape({
  id: Yup.string(),
  display_name: Yup.string()
    .trim()
    .required("Location Name is required")
    .max(50, "Location Name must be at most 50 characters"),
  address: Yup.string().trim(),
  city: Yup.string().trim(),
  region: Yup.string().trim(),
  postal_code: Yup.number(),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits"),
  description: Yup.string().notRequired(),
  coordinates: Yup.string(),
});

export type LocationData = Yup.InferType<typeof LocationSchema>;
