import React from "react"
import { Box, Backdrop } from "@mui/material";
import { LoaderImg, LoaderLogo } from "../../../utils/common/constants/images";

const Loader = ({isOpen}: any) => <Backdrop className="loaderContainer" open={isOpen}>
<Box
  display="flex"
  flexDirection="column"
  justifyContent="center"
  alignItems="center"
>
  <Box component="img" className="ldr_lg" src={LoaderLogo} alt="Loader" />
  <Box component="img" className="ldr" src={LoaderImg} alt="Loader" />
</Box>
</Backdrop>

export default Loader