import { ToastContainer } from "react-toastify";
import RoutesManager from "./routes";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/loader/loader";

const App = () => {
  return (
    <>
      <Loader />
      <RoutesManager />
      <ToastContainer
        closeButton={false}
        autoClose={3000}
        newestOnTop
        pauseOnHover
        position="top-center"
      />
    </>
  );
};

export default App;
