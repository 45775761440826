import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataApi, postDataApi } from "../../utils/common/constants";
import { setLoading } from "../features/global.slice";

/**
 * Dispatches an asynchronous action to add a new location address.
 *
 * @param {Object} payload - The payload object containing the location data to be added.
 * @returns {Promise<Object>} - A promise that resolves with the response data from the API call.
 */
export const addAddress = createAsyncThunk(
  "/location",
  async ({ payload }: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await postDataApi({
        path: "alfred/location",
        data: {
          ...payload,
        },
      });
      return fulfillWithValue(res);
    } catch (error: any) {
      const message =
        error.response && error.response.data && error.response.data.message;

      return rejectWithValue(message);
    }
  }
);

/**
 * Dispatches an asynchronous action to retrieve the gateway ID for a given location ID.
 *
 * @param {string} lid - The location ID to retrieve the gateway ID for.
 * @returns {Promise<Object>} - A promise that resolves with the response data from the API call.
 */
export const getGatewayId = createAsyncThunk(
  "/gatewayId",
  async (lid: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await getDataApi({
        path: "alfred/location-components",
        data: { lid },
      });
      return fulfillWithValue(res);
    } catch (error: any) {
      dispatch(setLoading(false))
      const message =
        error.response && error.response.data && error.response.data.error;
      return rejectWithValue(message);
    }
  }
);
