export const passwordError =
  "Your password must be at least 8 characters long and include a number and a special character";
export const phoneError = "Phone number must be 10 digits";

export const maxpasswordError = "Email should be maximum of 40 characters";

export const olPassRegex =
  /^(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z0-9!@#$%^&*(),.?":{}|<>]{8,}$/;

export const passRegex =
  /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;

export const emailRegex =
  /^([a-zA-Z0-9])([a-zA-Z0-9.]*[a-zA-Z0-9])?@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/;
  
export const emailError = "Please enter a valid email address";
export const mobileRegex = /^\d{10}$/;

export const requireEmail = "Enter your email address ";

export const requiredPass = "Enter your password";

export const validTLDs = [
  "com",
  "org",
  "net",
  "edu",
  "gov",
  "mil",
  "int",
  "info",
  "biz",
  "name",
  "pro",
  "co",
  "io",
  "tv",
  "me",
  "mobi",
  // Add more TLDs here...
];
