import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  panelSummary,
  totalValveCycles,
} from "src/redux/actions/pumpsAndPanels.action";
import { PanelSummary } from "src/redux/types/aquaLab.type";

/**
 * Calculates the number of days between two dates.
 * @param start - The start date.
 * @param end - The end date.
 * @returns The number of days between the start and end dates.
 */
const getDays = (start: any, end: any) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const startMilliseconds = startDate.getTime();
  const endMilliseconds = endDate.getTime();
  const differenceMilliseconds = endMilliseconds - startMilliseconds;
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const differenceInDays = differenceMilliseconds / millisecondsInADay;
  return differenceInDays;
};

const initialDateValues = {
  startDate: moment().subtract(15, "days").startOf("day").toDate(),
  endDate: moment().subtract(1, "days").endOf("day").toDate(),
  key: "selection",
};

/**
 * It manages state and data fetching related to pump
 * panel details for a monitoring application.
 * @returns The `usePanelDetailHelper` function returns an object with the properties and
 * methods:
 */
const usePanelDetailHelper = () => {
  const [panelSummaryData, setPanelSummaryData] = useState<any>([]);
  const [panelSummaryLatestData, setPanelSummaryLatestData] = useState<any>([]);

  const [selectionRange, setSelectionRange] = useState(initialDateValues);
  const [showPicker, setShowPicker] = useState(false);

  const [totalValueCycles, setTotalValueCycles] = useState<any>([]);

  const dispatch = useAppDispatch();

  const selectedPumpPanel = useAppSelector(
    (state) => state.aquaLab.selectedPumpPanel
  );

  const device_id = useSelector((state: any) => state.userInfo.gatewayId);

  const startUnixTimeStamp = moment(selectionRange.startDate)
    .startOf("day")
    .unix();
  const endUnixTimeStamp = moment(selectionRange.endDate)
    .add(1, "days")
    .startOf("day")
    .unix();

  const filteredData = panelSummaryData?.filter(
    (curr: PanelSummary) =>
      curr.modbusId === selectedPumpPanel.modbus_id &&
      startUnixTimeStamp <= curr.unixtime &&
      endUnixTimeStamp >= curr.unixtime
  );
  const filteredTotalValueCycles = totalValueCycles?.find(
    (curr: any) => curr.modbusId === selectedPumpPanel.modbus_id
  );

  var latestDate = { tempF: 0, humidity: 0 };
  if (panelSummaryLatestData?.length > 0) {
    var filterDataforParticularPanel = panelSummaryLatestData?.filter(
      (curr: PanelSummary) => curr.modbusId === selectedPumpPanel.modbus_id
    );

    if (filterDataforParticularPanel.length > 0)
      latestDate = filterDataforParticularPanel[0];
  }

  const getPanelSummary = (startDate: any, endDate: any) => {
    let bucketMinutes = 60;
    const days = getDays(startDate, endDate);
    if (days && days <= 1) {
      bucketMinutes = 60;
    } else if (days && days > 1 && days <= 4) {
      bucketMinutes = 240;
    } else if (days && days > 4 && days <= 7) {
      bucketMinutes = 420;
    } else if (days && days > 3 && days <= 31) {
      bucketMinutes = 1440;
    } else if (days && days > 31 && days <= 92) {
      bucketMinutes = 5040;
    } else if (days && days > 92) {
      bucketMinutes = 10080;
    }
    const payload = {
      deviceId: device_id,
      bucketMinutes: bucketMinutes,
      start: selectionRange.startDate.toISOString(),
      end: selectionRange.endDate.toISOString(),
    };
    if (device_id) {
      dispatch(panelSummary(payload)).then((res: any) => {
        setPanelSummaryData(res.payload);
      });
    }
    setShowPicker(false);
  };

  const handleReset = () => {};

  const handleEventDate = () => {
    setShowPicker(!showPicker);
  };
  const handleDates = (ranges: any) => {
    setSelectionRange({
      ...ranges.selection,
      endDate: moment(ranges.selection.endDate).endOf("day").toDate(),
    });
  };

  const handleTimeChange = (value: any, type: string) => {
    if (value) {
      const updatedRange = { ...selectionRange };
      type === "startDate"
        ? (updatedRange.startDate = value.$d)
        : (updatedRange.endDate = value.$d);
      setSelectionRange(updatedRange);
    }
  };

  const getPanelSummaryLatest = () => {
    const payload = {
      deviceId: device_id,
      bucketMinutes: 15,
      since: moment().subtract(1, "days").unix(),
    };
    if (device_id) {
      dispatch(panelSummary(payload)).then((res: any) => {
        setPanelSummaryLatestData(res.payload);
      });
    }
  };

  const getTotalValueCycles = () => {
    if (device_id) {
      dispatch(totalValveCycles(device_id)).then((res: any) => {
        setTotalValueCycles(res.payload);
      });
    }
  };

  useEffect(() => {
    getPanelSummary(selectionRange.startDate, selectionRange.endDate);
    getTotalValueCycles();
    getPanelSummaryLatest();
    // eslint-disable-next-line
  }, []);

  return {
    dispatch,
    selectedPumpPanel,
    latestDate,
    filteredData,
    filteredTotalValueCycles,
    getPanelSummary,
    selectionRange,
    setSelectionRange,
    panelSummaryLatestData,
    initialDateValues,
    showPicker,
    handleReset,
    handleEventDate,
    handleDates,
    handleTimeChange,
    setShowPicker,
  };
};

export default usePanelDetailHelper;
