import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataApi, postDataApi } from "src/utils/common/constants";
import { setLoading } from "../features/global.slice";
import { showAlert } from "src/utils";
import axios from 'axios';

/**
 * Fetches the user's locations from the backend API.
 *
 * @param user_id - The ID of the user whose locations should be fetched.
 * @returns A promise that resolves to the user's locations data.
 */
export const userDetail = createAsyncThunk(
    "/user-locations",
    async (user_id: string, { fulfillWithValue, rejectWithValue }) => {
        try {
            const res = await getDataApi({
                path: "alfred/user-locations",
                data: user_id
            })

            return fulfillWithValue(res)
        }
        catch (error: any) {
            const message = error.response && error.response.data && error.response.data.error;
            return rejectWithValue(message);
        }
    }
);

/**
 * Updates the user's phone number in the backend.
 *
 * @param data - An object containing the new phone number data.
 * @returns A promise that resolves to the response from the backend API.
 */
export const updateUserPhone = createAsyncThunk(
    "/update-user-phone",
    async (data: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {

            dispatch(setLoading(true));
            const res = await postDataApi({
                path: "alfred/user",
                data: data
            })
            dispatch(setLoading(false));
            showAlert(1, "Phone number updated successfully !");
            return fulfillWithValue(res)
        }
        catch (error: any) {
            const message =
                error.response && error.response.data && error.response.data.error;
            dispatch(setLoading(false));
            showAlert(2, message);
        }
    }
);

/**
 * Updates the system alert block list in the backend.
 *
 * @param system_alerts_block_list - An array of system alert IDs to be blocked.
 * @returns A promise that resolves to the response from the backend API.
 */
export const updateSystemAlert = createAsyncThunk(
    "/update-system-alert",
    async (system_alerts_block_list: string[], { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const res = await postDataApi({
                path: "alfred/system-alert-block-list",
                data: { system_alerts_block_list }
            })
            dispatch(setLoading(false));
            showAlert(1, "Your preferences have been updated.");
            return fulfillWithValue(res)
        }
        catch (error: any) {
            const message =
                error.response && error.response.data && error.response.data.error;
            dispatch(setLoading(false));
            showAlert(2, message);
        }
    }
);

/**
 * Updates the user's name in the backend.
 *
 * @param data - An object containing the updated user name data.
 * @returns A promise that resolves to the response from the backend API.
 */
export const updateUserName = createAsyncThunk(
    "/update-user-name",
    async (data: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const res = await postDataApi({
                path: "alfred/user",
                data: data
            })
            dispatch(setLoading(false));
            showAlert(1, "Name updated successfully !");
            return fulfillWithValue(res)
        }
        catch (error: any) {
            const message =
                error.response && error.response.data && error.response.data.error;
            dispatch(setLoading(false));
            showAlert(2, message);
        }
    }
);
/**
 * Updates the user's profile picture in the backend.
 *
 * @param data - An object containing the updated profile picture data.
 * @returns A promise that resolves to the URL of the updated profile picture.
 */
export const updateProfilePic = createAsyncThunk(
    "/update-profile-pic",
    async (data: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL
            dispatch(setLoading(true));
            const res = await axios.post(`${baseUrl}/alfred/profile-picture`, { data }, {
                timeout: 20000,
                headers: {
                    "content-type": "multipart/form-data"
                },
                withCredentials: true
            })
            dispatch(setLoading(false));
            showAlert(1, "Profile picture updated successfully !");
            return fulfillWithValue(res.data.profile_picture_url)
        }
        catch (error: any) {
            const message =
                error.response && error.response.data && error.response.data.error;
            dispatch(setLoading(false));
            showAlert(2, message);
        }
    }
);

/**
 * Dispatches an asynchronous action to invite a new user.
 *
 * @param data - An object containing the data for the new user to be invited.
 * @returns A promise that resolves to the response from the server.
 */
export const addUser = createAsyncThunk(
    "alfred/user-invite",
    async (data: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const res = await postDataApi({
                path: "alfred/user-invite",
                data: data
            })
            dispatch(setLoading(false));
            showAlert(1, "Invite sent");
            return fulfillWithValue(res)
        }
        catch (error: any) {
            const message =
                error.response && error.response.data && error.response.data.error;
            dispatch(setLoading(false));
            showAlert(2, message);
        }
    }
);

/**
 * Dispatches an asynchronous action to edit an existing user.
 *
 * @param data - An object containing the updated data for the user.
 * @returns A promise that resolves to the response from the server.
 */
export const editUser = createAsyncThunk(
    "alfred/user",
    async (data: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const res = await postDataApi({
                path: "alfred/user",
                data: data
            })
            dispatch(setLoading(false));
            showAlert(1, "User updated");
            return fulfillWithValue(res)
        }
        catch (error: any) {
            const message =
                error.response && error.response.data && error.response.data.error;
            dispatch(setLoading(false));
            showAlert(2, message);
        }
    }
);

/**
 * Dispatches an asynchronous action to delete an existing user.
 *
 * @param user_id - The ID of the user to be deleted.
 * @returns A promise that resolves to the response from the server.
 */
export const deleteUser = createAsyncThunk(
    "alfred/user-remove",
    async ({ user_id }: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const res = await postDataApi({
                path: "alfred/user-remove",
                data: { user_id }
            })
            dispatch(setLoading(false));
            showAlert(1, "User deleted");
            return fulfillWithValue(res)
        }
        catch (error: any) {
            const message =
                error.response && error.response.data && error.response.data.error;
            dispatch(setLoading(false));
            showAlert(2, message);
        }
    }
);

/**
 * Dispatches an asynchronous action to fetch the user's location details.
 *
 * @param user_id - The ID of the user whose location details are to be fetched.
 * @returns A promise that resolves to the response from the server containing the user's location details.
 */
export const getUserDetail = createAsyncThunk(
    "/user-locations",
    async (user_id: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const res = await getDataApi({
                path: "alfred/user-locations",
                data: { user_id }
            })
            dispatch(setLoading(false));
            return fulfillWithValue(res)
        }
        catch (error: any) {
            dispatch(setLoading(false));
            const message = error.response && error.response.data && error.response.data.error;
            return rejectWithValue(message);
        }
    }
);